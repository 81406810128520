<template>
	<div v-if="success" class="registration-confirm">
		<span class="sbl-h1">Производится подтверждение регистрации</span>
		<Spinner></Spinner>
	</div>
	<div v-else class="registration-confirm sbl-h1">
		<span>Произошла ошибка при подтверждении регистрации</span>
		<div class="exception-txt">
			Ссылка повреждена
		</div>
	</div>
</template>

<script>
import { SblValidateRegistrationEmailServiceExt } from "../services/api/SblValidateRegistrationEmailServiceExt.js";

/**
 * Страница подтверждения регистрации.
 */
export default {
	name: "RegistrationConfirm",
	data: function() {
		return {
			/**
			 * Успешность подтверждения регистрации.
			 */
			success: true,
		};
	},
	methods: {
		/**
		 * Регистрация по токену.
		 * @param {string} token Токен, по которому запрашиваются данные для регистрации.
		 */
		validateRegistration(token) {
			var scope = this;
			SblValidateRegistrationEmailServiceExt.validateRegistrationEmail(token)
			.then(function(response) {
				let regData = response && response.data;
				let result = regData.ValidateRegistrationEmailResult;
				if(result.Inn)
				{
					sessionStorage.setItem("Inn", result.Inn);
				}
				switch(result.ResultCode) {
					case 0:
						sessionStorage.setItem("UserName", result.Login);
						scope.$router.replace({ name: "RegistrationSuccess" });
						break;
					case 1:
						scope.$router.replace({ name: "ConfirmationEmailExpired", query: {token: token}});
						break;
					case 3:
						scope.$router.replace({ name: "SignIn" });
						break;
					default:
						scope.$router.replace({ name: "RegistrationFail" });
				}
			})
			.catch(function(error) {
				scope.success = false;
				console.log(error.message);
			});
		}
	},
	computed: {},
	mounted() {
		let router = this.$router.history.current;
		let query = router && router.query;
		let token = query && query.token;

		if (token) {
			this.validateRegistration(token);
			return;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.registration-confirm {
	display: flex;
	justify-content: center;
	flex: 1;
	align-items: center;
	flex-direction: column;
	margin-top: 100px;
}

.exception-txt {
	font-size: 24px;
	padding: 30px;
}
</style>
