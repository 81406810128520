<template>
  <BaseModal title="" @close="$emit('close')">
    <template v-slot:content>
      <div class="certificate-login sbl-card">
        <!-- Icon -->
        <div class="self-align-center">
          <img src="../assets/images/certificate-login-logo.svg" alt="Signature" />
        </div>

        <!-- Caption -->
        <div class="sbl-modal-row self-align-center sbl-large-text">
          <a>Подключите носитель электронной подписи</a>
        </div>

        <!-- Certificate select -->
        <div class="certificate-login-select-wrap sbl-modal-row self-align-center sbl-med-text">
          <v-select
            :options="certificateOptions" 
            label="text"
            placeholder="Выберите сертификат" 
            class="certificate-login-select"
            v-model="choosedCertificate"
            >
            <span slot="no-options">
            <a @click="goToCheckCryptoProPlugin">
              <router-link to="/" class="sbl-link sbl-link-spacing">Проверить работу КриптоПро ЭЦП плагина</router-link>
            </a>
            </span>
          </v-select>
        </div>

        <!-- NEXT -->
        <div class="certificate-login-next sbl-modal-row self-align-center sbl-modal-row-last">
          <RoundedButton
            caption="Далее"
            additionalClasses="sbl-green-button sbl-med-text"
            :disabled="!choosedCertificate || disabledSubmitButton"
            @click="readyButtonClick()"
          />
        </div>
      </div>
      <Spinner v-if="isLoading"></Spinner>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import { DefaultValues } from "../enums/DefaultValues.js"
import { SblCertificateAuthService } from "../services/api/SblCertificateAuthService.js";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { CertificateErrorTypes } from "../enums/CertificateErrorTypes.js";
import { EntranceTypes } from "../enums/EntranceTypes.js";
import ErrorModal from "../components/ErrorModal.vue";
import MessageBox from "@/services/MessageBox";
import AuthService from "../services/AuthenticationService";
import CertificatesLoader from "@/services/CertificatesLoader.js";
import cryptoPro from "crypto-pro";
import { CadesPlugin } from "@/services/api/CadesPluginApi.js";
import { Base64Helper } from "../helpers/base64Helper.js";
import { CadesErrorCodes } from "../enums/CadesErrorCodes.js";
import { Messages } from "../enums/Messages.js";
import { ModalService } from "../services/ModalService.js";
import RoundedButton from "@/components/RoundedButton";
import AuthenticationService from '../services/AuthenticationService';

export default {
  name: "LoginByCertificateModal",
  props: {
    /**
     * Тип авторизации.
     */
    authType: {
      type: String,
      required: true
    },
    /**
    * URL проверки работы КриптоПро ЭЦП плагина.
    */
    cryptoProPluginCheckUrl: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      /**
       * Выбранный сертификат.
       */
      choosedCertificate: undefined,

      /**
       * Доступность кнопки "Продолжить".
       */
      disabledSubmitButton: false,

      /**
       * Пункты выпадающего меню выборасертификатов.
       */
      certificateOptions: [],

      /**
       * Все загруженные сертификаты.
       */
      allCertificates: new Array(),

      /**
      * Видимость колеса загрузки.
      */
      isLoading: false
    };
  },
  components: {
    ErrorModal,
    BaseModal,
    RoundedButton
  },
  methods: {
    goToCheckCryptoProPlugin() {
      window.open(this.cryptoProPluginCheckUrl, '_blank').focus();
    },
    /**
     * Наполнить список сертификатов.
     */
    fillCertificateList() {
      this.choosedCertificate = null;
      CertificatesLoader.getCertificateList(
        this.allCertificates,
        this.certificateOptions
      );
    },

    /**
     * Обработчик события нажатия на кнопку "Готово".
     */
    readyButtonClick() {
      if (!this.choosedCertificate) {
        MessageBox.error(
          "Для продолжения работы необходимо выбрать сертификат"
        );
        return;
      }
      switch (this.authType) {
        case EntranceTypes.SIGN_IN:
          this.tryLoginByCertificate(this.choosedCertificate);
          break;
        case EntranceTypes.SIGN_UP:
          this.tryRegisterWithCertificate(this.choosedCertificate);
          break;
        default:
          console.error("Некорректный тип авторизации!");
      }
    },

    /**
     * Регистрация по сертификату.
     * @param {any} certificateOption Выбранный элемент выпалающего списка сертификатов.
     * @private
     */
    tryRegisterWithCertificate(certificateOption) {
      if (!certificateOption) {
        console.error("Argument exception: certificateOption");
        return;
      }

      let selectedCertID = certificateOption.value;

      // Переменные использующиеся в цепочке вызовов.
      var cert = this.allCertificates[selectedCertID];
      var finalCertificateInfo = {};
      var scope = this;

      scope.disabledSubmitButton = true;
      scope.isLoading = true;
      this.setCertificateBaseAttributes(cert)
        .then(function(certificateInfo) {
          return scope.setCertificateContentInBase64(cert, certificateInfo);
        })
        .then(scope.fillCertificateDataFromString)
        .then(function(certificateInfo) {
          return scope.generateCertificateSign(cert, certificateInfo);
        })
        .then(function(certificateInfo) {
          finalCertificateInfo = certificateInfo;
          return scope.encryptTokenByPrivateKey(
            cert,
            certificateInfo.SequenceForSign
          );
        })
        .then(function(encryptedToken) {
          finalCertificateInfo.CertificateSign = encryptedToken;
          return finalCertificateInfo;
        })

        .then(scope.verifyCertificateContent)
        .then(scope.getAdditionalInfoByDaData)
        .then(scope.notifyAboutNonCriticalCertificateErrorsIfExist)

        .then(scope.openRegistrationByCertificatePage)
        .catch(error => {
          scope.disabledSubmitButton = false;
          if (error instanceof scope.CertificateProcessingError) {
            MessageBox.error(error.message);
            console.log(error.message);
          } else if (typeof error === "string") {
            if (error.includes(CadesErrorCodes.CERTIFICATE_OR_PRIVATE_KEY_NOT_FOUND)) {
              MessageBox.error(Messages.SblPrivateKeyNotFound);
            } else if (error.includes(CadesErrorCodes.CERTIFICATE_CHAIN_COULD_NOT_BE_BUILT)) {
              MessageBox.error(Messages.SblCertificateChainCouldNotBeBuilt);
            } else if (error.includes(CadesErrorCodes.CERTIFICATE_NOT_IN_VALIDITY_PERIOD)) {
              MessageBox.error(Messages.SblCertificateNotInValidityPeriod);
            } else {
              console.log(error.message);
            }
          } else {
            console.log(error.message);
          }
        })
        .finally(()=>{
            if(scope.isLoading){
              scope.isLoading = false;
            }
        });
    },

    /**
     * Попытка регистрации по объединенной логике.
     * @param {any} certificateInfo Информация по сертификату.
     * @param {any} scope Область исполнения.
     */
    tryRegisterFromCombine(certificateInfo, scope) {
      scope.verifyCertificateContent(certificateInfo)
        .then(scope.getAdditionalInfoByDaData)
        .then(scope.notifyAboutNonCriticalCertificateErrorsIfExist)
        .then(scope.tryApplyCertToExistingUser)
        .then(scope.openRegistrationByCertificatePage)
        .catch(error => {
          scope.disabledSubmitButton = false;
          if (error instanceof scope.CertificateProcessingError) {
            MessageBox.error(error.message);
            console.log(error.message);
          } else if (typeof error === "string") {
            if (error.includes(CadesErrorCodes.CERTIFICATE_OR_PRIVATE_KEY_NOT_FOUND)) {
              MessageBox.error(Messages.SblPrivateKeyNotFound);
            } else if (error.includes(CadesErrorCodes.CERTIFICATE_CHAIN_COULD_NOT_BE_BUILT)) {
              MessageBox.error(Messages.SblCertificateChainCouldNotBeBuilt);
            } else if (error.includes(CadesErrorCodes.CERTIFICATE_NOT_IN_VALIDITY_PERIOD)) {
              MessageBox.error(Messages.SblCertificateNotInValidityPeriod);
            } else {
              console.log(error.message);
            }
          } else {
            console.log(error.message);
          }
        })
        .finally(()=>{
            if(scope.isLoading){
              scope.isLoading = false;
            }
        });
    },

    /**
     * Попробовать привязать сертификат к существющему пользователю.
     * @param {any} certificateInfo Информация по сертификату.
     */
    tryApplyCertToExistingUser(certificateInfo) {
      var dataToPass = {
        FirstName: certificateInfo.Data?.G,
        LastName: certificateInfo.Data?.SN,
        Inn: certificateInfo.Data?.INN,
        Kpp: certificateInfo.additionalInfoByDaDataResult?.Kpp,
        Thumbprint: certificateInfo.Thumbprint,
        Content: certificateInfo.base64Certificate
      };
      var scope = this;
      return new Promise((resolve, reject) => {
        SblUserManagementService.TryApplyCertToExistingUser(dataToPass)
          .then(function (response) {
              let paramName = "TryApplyCertToExistingUserResult";
              if (!response.data || !response.data[paramName]) {
                throw "Пустой ответ от сервиса предрегистрации.";
              }
              let body = response.data[paramName];
              if (body.Success)
              {
                if (body.AccountId) {
                  certificateInfo.AccountId = body.AccountId;
                }
                scope.loginWithSelectedCertificate(certificateInfo);
                return;
              }
              resolve(certificateInfo);
            })
            .catch(function (error) {
              reject(error);
            });
      });
    },

    notifyAboutNonCriticalCertificateErrorsIfExist(certificateInfo) {
      var scope = this;
      return new Promise((resolve, reject) => {
        let certificateData = certificateInfo.validationResult || {};

        // Получить ошибку, что пользователь не является ген.директором, если она есть
        let genDirError = (certificateInfo.additionalInfoByDaDataResult || {})
          .Error;

        if (certificateData.errors) {
          reject(
            new scope.CertificateProcessingError(
              CertificateErrorTypes.NOT_TRUSTED,
              errors
            )
          );
        } else if (genDirError) {
          reject(
            new scope.CertificateProcessingError(
              CertificateErrorTypes.NOT_GENERAL_DIRECTOR,
              genDirError
            )
          );
        } else {
          resolve(certificateInfo);
        }
      });
    },

    /**
     * Авторизация по сертификату.
     * @param {any} certificateOption Выбранный элемент выпалающего списка сертификатов.
     * @private
     */
    tryLoginByCertificate(certificateOption) {
      if (!certificateOption) {
        console.error("Argument exception: certificateOption");
        return;
      }
      let selectedCertID = certificateOption.value;

      // Переменные использующиеся в цепочке вызовов.
      var cert = this.allCertificates[selectedCertID];
      var finalCertificateInfo = {};
      var scope = this;
      scope.disabledSubmitButton = true;
      scope.isLoading = true;
      scope.setCertificateBaseAttributes(cert)
        .then(function(certificateInfo) {
          return scope.setCertificateContentInBase64(cert, certificateInfo);
        })
        .then(scope.fillCertificateDataFromString)
        .then(function(certificateInfo) {
          return scope.generateCertificateSign(cert, certificateInfo);
        })
        .then(function(certificateInfo) {
          finalCertificateInfo = certificateInfo;
          return scope.encryptTokenByPrivateKey(
            cert,
            certificateInfo.SequenceForSign
          );
        })
        .then(function(encryptedToken) {
          finalCertificateInfo.CertificateSign = encryptedToken;
          return finalCertificateInfo;
        })
        .then(function(certificateInfo) {
          return scope.validateCertificateOnLogin(certificateInfo, scope);
        })
        .then(scope.loginWithSelectedCertificate)
        .catch(error => {
          this.disabledSubmitButton = false;
          if (error instanceof scope.CertificateProcessingError) {
            MessageBox.error(error.message);
            console.log(error.message);
          } else {
            if (
              typeof error === "string" &&
              error.includes(
                CadesErrorCodes.CERTIFICATE_OR_PRIVATE_KEY_NOT_FOUND
              )
            ) {
              MessageBox.error(Messages.SblPrivateKeyNotFound);
            } else if (
              typeof error === "string" &&
              error.includes(
                CadesErrorCodes.CERTIFICATE_CHAIN_COULD_NOT_BE_BUILT
              )
            ) {
              MessageBox.error(Messages.SblCertificateChainCouldNotBeBuilt);
            } else {
              MessageBox.error(Messages.SblCertificateVerificationFailed);
              console.error(error);
            }
          }
        })
        .finally(()=>{
            if(scope.isLoading){
              scope.isLoading = false;
            }
        });
    },

    /**
     * Установка базовых параметров сертификата.
     * @param {any} cert Объект сертификата из плагина.
     */
    setCertificateBaseAttributes(cert) {
      let certificateInfo = {
        Data: {},
        base64Certificate: ""
      };

      certificateInfo.SubjectName = cert.subjectName;
      certificateInfo.Thumbprint = cert.thumbprint;
      return Promise.resolve(certificateInfo);
    },

    /**
     * Заполнение данных из строки сертификата.
     * @param {any} certificateInfo Информация по сертификату.
     * @param {any} certificateInfo.SubjectName Данные сертификата (место выдачи, назание и т.д.).
     * @private
     */
    fillCertificateDataFromString(certificateInfo) {
      return new Promise((resolve, reject) => {
        SblUserManagementService.parseCertificate(certificateInfo.base64Certificate)
          .then(function(response) {
            let paramName = SblUserManagementService.GetParseClientCertificateMethodName + "Result";
            if (!response.data || !response.data[paramName]) {
              throw "Пустой ответ от сервиса парсинга сертификата!";
            }
            certificateInfo.Data = response.data[paramName];
            
            resolve(certificateInfo);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Проверка сертификата при авторизации.
     * @param {any} certificateInfo Информация по сертификату.
     * @param {any} scope Область исполнения.
     */
    validateCertificateOnLogin(certificateInfo, scope) {
      return new Promise((resolve, reject) => {
        let dataToSend = {
          Thumbprint: certificateInfo.Thumbprint,
          CertificateSign: certificateInfo.CertificateSign,
          OGRN: certificateInfo.Data.OGRN,
          OGRNIP: certificateInfo.Data.OGRNIP
        }
        SblUserManagementService.validateCertificateOnLogin(dataToSend)
          .then(function (response) {
            let paramName = SblUserManagementService.GetValidateCertificateOnLoginMethodName + "Result";
            if (!response.data || !response.data[paramName]) {
              throw "Пустой ответ от сервиса првоерки сертификата.";
            }
            let responseBody = response.data[paramName];
            if (responseBody.NeedRedirect) {
              scope.tryRegisterFromCombine(certificateInfo, scope);
              return;
            }
            if (responseBody.Success) {
              if (responseBody.FoundedAccounts) {
                if (responseBody.FoundedAccounts.length > 1) {
                  // open account lookup
                  if(scope.isLoading){
                    scope.isLoading = false;
                  }
                  AuthenticationService.openAccountSelectLookup(responseBody.FoundedAccounts, (account) => {
                    certificateInfo.AccountId = account.AccountId;
                    resolve(certificateInfo);
                    return;
                  }, scope);
                  return;
                }
                if (responseBody.FoundedAccounts.length == 1) {
                  // use founded account
                  certificateInfo.AccountId = responseBody.FoundedAccounts[0].AccountId;
                }
              }
              // call login
              resolve(certificateInfo);
              return;
            }
            if (responseBody.ErrorMessage !== null
              && responseBody.ErrorMessage !== ""
              && responseBody.ErrorMessage !== "Error") {
              // show custom error
              reject(new scope.CertificateProcessingError(
                CertificateErrorTypes.CUSTOM_ERROR,
                responseBody.ErrorMessage
              ));
              return;
            }
            // show common error
            MessageBox.error(Messages.SblCertificateVerificationFailed);
            reject(new scope.CertificateProcessingError(
              CertificateErrorTypes.UNDEFINED_EXCEPTION,
              result.ResultMessage
            ));
            return;
          })
          .catch(function (error) {
            reject(error);
          });
      });
    },

    /**
     * Получение значения свойства из строки данных.
     * @param {String} dataString Строка с данными сертификата.
     * @param {String[]} possibleEntries Возможные названия атрибута в строке с данными.
     * @returns {String} Значение атрибута.
     * @private
     */
    getPropertyValue(dataString, possibleEntries) {
      let regexTemplate =
        '(?:^| )#entry#=(.*?),|(?:^| )#entry#="(.*?)"|(?:^| )#entry#=(.*?), |(?:^| )#entry#=(.*?)$';

      for (var i = 0; i < possibleEntries.length; i++) {
        var entryValue = null;
        var entry = possibleEntries[i];
        let filledTemplate = regexTemplate.replace(/#entry#/g, entry);
        let cnReg = new RegExp(filledTemplate);
        let cnMatch = cnReg.exec(dataString);
        if (!cnMatch) {
          continue;
        }
        if (cnMatch.length > 1) {
          if (cnMatch[1]) {
            entryValue = cnMatch[1];
            break;
          } else if (cnMatch[2]) {
            entryValue = cnMatch[2];
            break;
          } else if (cnMatch[3]) {
            entryValue = cnMatch[3];
            break;
          } else if (cnMatch[4]) {
            entryValue = cnMatch[4];
            break;
          }
        }
      }
      return entryValue;
    },

    /**
     * Преобразование содержимого сертификата к Base64.
     * @param {any} Сертификат.
     * @param {any} Дополнительная информация по сертификату, в которую запишется результат.
     */
    setCertificateContentInBase64(cert, certificateInfo) {
      return new Promise((resolve, reject) => {
        return cert._cert.Export(0).then(
          function(result) {
            certificateInfo.base64Certificate =
              result && result.replace(/\r\n/g, "");
            resolve(certificateInfo);
          },
          function(error) {
            reject(error);
          }
        );
      });
    },

    /**
     * Подписать сертификат по приватному ключу.
     * @param {any} cert Сертификат.
     * @param {string} stringToEncrypt Строка для полписи.
     */
    encryptTokenByPrivateKey(cert, stringToEncrypt) {
      var scope = this;
      return scope.GetSignedCadesBESMessage(cert, stringToEncrypt, scope);
    },

    GetSignedCadesBESMessage(cert, data) {
      var scope = this;
      if (isNativeMessageSupported) {
        return scope.SignCadesBES_Async(cert, data);
      } else {
        return scope.SignCadesBES_NPAPI(cert, data);
      }
    },

    SignCadesBES_Async(cert, data) {
      var scope = this;
      return new Promise(function(resolve, reject) {
        cadesplugin.async_spawn(function*(arg) {
          var certificate = cert;

          var dataToSign = data;
          if (typeof data != "undefined") {
            dataToSign = Base64Helper.encode(data);
          } else {
            dataToSign = Base64Helper.encode(dataToSign);
          }

          var Signature;
          try {
            //FillCertInfo_Async(certificate);
            let errormes = "";
            try {
              var oSigner = yield cadesplugin.CreateObjectAsync(
                "CAdESCOM.CPSigner"
              );
            } catch (err) {
              errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
              throw errormes;
            }
            let oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
            );

            yield oSigningTimeAttr.propset_Name(
              cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
            );
            let oTimeNow = new Date();
            yield oSigningTimeAttr.propset_Value(oTimeNow);
            let attr = yield oSigner.AuthenticatedAttributes2;
            yield attr.Add(oSigningTimeAttr);
            let oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
            );
            yield oDocumentNameAttr.propset_Name(
              cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
            );
            yield oDocumentNameAttr.propset_Value("Document Name");
            yield attr.Add(oDocumentNameAttr);

            if (oSigner) {
              yield oSigner.propset_Certificate(certificate);
            } else {
              errormes = "Failed to create CAdESCOM.CPSigner";
              throw errormes;
            }

            let oSignedData = yield cadesplugin.CreateObjectAsync(
              "CAdESCOM.CadesSignedData"
            );
            if (dataToSign) {
              // Данные на подпись ввели
              yield oSigner.propset_Options(
                cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
              );
              yield oSignedData.propset_ContentEncoding(
                cadesplugin.CADESCOM_BASE64_TO_BINARY
              ); //
              if (typeof setDisplayData != "undefined") {
                //Set display data flag flag for devices like Rutoken PinPad
                yield oSignedData.propset_DisplayData(1);
              }
              yield oSignedData.propset_Content(dataToSign);

              try {
                Signature = yield oSignedData.SignCades(
                  oSigner,
                  cadesplugin.CADESCOM_CADES_BES
                );
              } catch (err) {
                errormes =
                  "Не удалось создать подпись из-за ошибки: " +
                  cadesplugin.getLastError(err);
                throw errormes;
              }
            }
            return resolve(Signature);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        }); //cadesplugin.async_spawn
      });
    },

    SignCadesBES_NPAPI(cert, data) {
      return new Promise(function(resolve, reject) {
        var certificate = cert;
        try {
          var signature = MakeCadesBesSign_NPAPI(data, certificate);
          return resolve(signature);
        } catch (error) {
          console.log(error);
          return reject(error);
        }
      });
    },

    MakeCadesBesSign_NPAPI(dataToSign, certObject) {
      let errormes = "";
      try {
        var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      } catch (err) {
        errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
        throw errormes;
      }

      if (oSigner) {
        oSigner.Certificate = certObject;
      } else {
        errormes = "Failed to create CAdESCOM.CPSigner";
        console.log(errormes);
        throw errormes;
      }

      try {
        var oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      } catch (err) {
        console.log("Failed to create CAdESCOM.CadesSignedData: " + err.number);
        return;
      }

      let CADES_BES = 1;
      let Signature;

      if (dataToSign) {
        // Данные на подпись ввели
        oSignedData.ContentEncoding = 1; //CADESCOM_BASE64_TO_BINARY
        if (typeof setDisplayData != "undefined") {
          //Set display data flag flag for devices like Rutoken PinPad
          oSignedData.DisplayData = 1;
        }

        oSignedData.Content = Base64Helper.encode(dataToSign);
        oSigner.Options = 1; //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
        try {
          Signature = oSignedData.SignCades(oSigner, CADES_BES);
        } catch (err) {
          errormes =
            "Не удалось создать подпись из-за ошибки: " +
            cadesplugin.getLastError(err);
          console.log(cadesplugin.getLastError(err));
          throw errormes;
        }
      }
      return Signature;
    },

    /**
     * Генерация подписи сертификата.
     * @param {any} cert Сертификат.
     * @param {any} certificateInfo Информация о сертификате.
     * @returns {any} Информация о сертификате с подписью.
     * @private
     */
    generateCertificateSign(cert, certificateInfo) {
      return new Promise((resolve, reject) => {
        return SblUserManagementService.getSequenceForCertificateSign(
          certificateInfo.Thumbprint
        )
          .then(function(response) {
            var paramName =
              SblUserManagementService.GetSequenceForCertificateSignMethodName +
              "Result";
            if (response.data) {
              certificateInfo.SequenceForSign = response.data[paramName];
              resolve(certificateInfo);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Вход в систему по выбранному сертификату.
     * @param {any} certificateInfo Информация о сертификату.
     * @param {String} certificateInfo.Thumbprint "Отпечаток" сертификата.
     * @param {String} certificateInfo.Base64CertificateContent Содержимое сертификата в формате Base64.
     * @param {String} certificateInfo.CertificateSign Подпись сертификата.
     */
    loginWithSelectedCertificate(certificateInfo) {
      // Переменные, использующиеся в цепочке вызовов.
      var dataToPass = {
        ThumbPrint: certificateInfo.Thumbprint,
        CertificateSign: certificateInfo.CertificateSign,
        AccountId: certificateInfo.AccountId ?? DefaultValues.EMPTY_GUID
      };
      var scope = this;
      return new Promise((resolve, reject) => {
        return SblCertificateAuthService.loginWithCertificate(dataToPass)
          .then(function(response) {
            let result = response && response.data;
            if (result && result.ResultCode === "Success") {
              // Сохранить отпечаток в сессии, на случай необходимости смены пароля
              sessionStorage.setItem(
                "certificateThumbprint",
                dataToPass.ThumbPrint
              );
              sessionStorage.setItem("fillFromCertificate", true);
              AuthService.openHomePage();
            } else if (result.ResultCode === "UserFriendlyError") {
              reject(
                new scope.CertificateProcessingError(
                  CertificateErrorTypes.EMPTY_FIELDS,
                  result.ResultMessage
                )
              );
            } else {
              reject(
                new scope.CertificateProcessingError(
                  CertificateErrorTypes.EMPTY_FIELDS,
                  Messages.SblCertificateVerificationFailed
                )
              );
            }
          })
          .catch(function(error) {
            reject(
              new scope.CertificateProcessingError(
                CertificateErrorTypes.EMPTY_FIELDS,
                Messages.SblServiceIsTemporarilyUnavailable
              )
            );
          });
      });
    },

    /**
     * Валидация сертификата.
     * @param {any} certificateInfo Информация о сертификату.
     * @private
     */
    verifyCertificateContent(certificateInfo) {
      var scope = this;
      return new Promise((resolve, reject) => {
        SblUserManagementService.doCertificateValidationRequest(
          JSON.stringify(certificateInfo.base64Certificate)
        )
          .then(function(response) {
            var result = response && response.data;
            var validationResult = scope.processCertificateValidationResult(
              JSON.parse(result)
            );
            if (validationResult.isCriticalError) {
              reject(
                new scope.CertificateProcessingError(
                  CertificateErrorTypes.INVALID_CONTENT,
                  validationResult.errors
                )
              );
              return;
            }
            certificateInfo.validationResult = validationResult;
            resolve(certificateInfo);
          })
          .catch(function(error) {
            reject(scope.getDefaultCertificateError());
          });
      });
    },

    /**
     * Обработка результата проверки сертификата.
     * @package {any} validationResult Результат проверки сертификата.
     * @private
     */
    processCertificateValidationResult(validationResult) {
      let isCriticalError = false;

      let errors = "";
      if (!validationResult || !validationResult.meta) {
        errors = "Не удалось проверить выбранный сертификат.";
        isCriticalError = true;
      } else if (!validationResult.data) {
        errors = "Не удалось проверить выбранный сертификат.";
        isCriticalError = true;
      } else {
        if (!validationResult.data.trusted) {
          errors += "Сертификат не является доверенным; ";
          isCriticalError = true;
        }
        if (new Date(validationResult.data.dateTo) < new Date()) {
          errors +=
            "Сертификат не действителен. Вы можете обратиться в любой удостоверяющий центр для выпуска нового; ";
          isCriticalError = true;
        }

        if (validationResult.meta.status === "error") {
          var desc = validationResult.meta.desc;
          if (desc) {
            for (var i = 0; i < desc.length; i++) {
              errors += desc[i] + "; ";
            }
          }
        }
        return { isCriticalError, errors };
      }
    },

    /**
     * Получение дополнительной информации по сертификату из DaData.
     * @param {any} certificateInfo Информация о сертификату.
     * @private
     */
    getAdditionalInfoByDaData(certificateInfo) {
      var scope = this;

      let data = certificateInfo.Data;
      let inn = data.INN;
      let lastName = data.SN;
      let firstAndMiddleName = data.G;
      let organizationName = data.O;
      
      if ((!inn || !lastName || !firstAndMiddleName) && !organizationName) {
        reject(
          new scope.CertificateProcessingError(
            CertificateErrorTypes.EMPTY_FIELDS,
            "В сертификате отсутствуют данные по ИНН и имени владельца или по названию организации"
          )
        );
        return;
      }
      
      var dataToPass = {
        inn: inn,
        organizationName: organizationName,
        certificateSurName: lastName,
        certificateFirstAndMiddleName: firstAndMiddleName
      };
      return new Promise((resolve, reject) => {
        SblUserManagementService.getAdditionalInfoByDaData(dataToPass)
          .then(function(response) {
            let result = response && response.data;
            certificateInfo.additionalInfoByDaDataResult =
              result.GetAdditionalInfoByDaDataResult;
            resolve(certificateInfo);
          })
          .catch(function(error) {
            reject(scope.getDefaultCertificateError());
          })
          .finally(()=>{
            scope.isLoading = false;
          });
      });
    },

    /**
     * Закрывает окошко
     */
    close() {
      this.$emit('close');
    },

    /**
     * Открытие страницы регистрации по сертификату.
     * @param {any} certificateInfo Информация о сертификату.
     * @param {String} certificateInfo.Thumbprint "Отпечаток" сертификата.
     * @param {String} certificateInfo.base64Certificate Содержимое сертификата в формате Base64.
     * @param {String} certificateInfo.Data Данные сертификата в одной строке.
     * @private
     */
    openRegistrationByCertificatePage(certificateInfo) {
      if (certificateInfo.additionalInfoByDaDataResult) {
        sessionStorage.setItem(
          "additionalInfoByDaDataResult",
          JSON.stringify(certificateInfo.additionalInfoByDaDataResult)
        );
      }
      sessionStorage.setItem(
        "registrationData",
        JSON.stringify(certificateInfo.Data)
      );
      sessionStorage.setItem(
        "certificateSign",
        certificateInfo.CertificateSign
      );
      sessionStorage.setItem(
        "certificateContent",
        certificateInfo.base64Certificate
      );
      sessionStorage.setItem(
        "certificateThumbprint",
        certificateInfo.Thumbprint
      );
      sessionStorage.setItem("fillFromCertificate", true);
      this.$router.push({ name: "SignUpWithPersonalDataFromCertificate" });
      //closes window instead of closing it in SignUpWithPersonalData module
      this.close();
    },

    /**
     * Получение ошибки по умолчанию.
     * @private
     */
    getDefaultCertificateError() {
      return new this.CertificateProcessingError(
        CertificateErrorTypes.UNDEFINED_EXCEPTION,
        Messages.SblCertificateVerificationFailed
      );
    },

    /**
     * Ошибка обработки сертификата.
     */
    CertificateProcessingError: function(errorType, message) {
      this.errorType = errorType;
      this.message = message;
    },

    /**
     * Обработчик события нажатия на ссылку "Инструкция по работе с электронной подписью".
     */
    onUserManualClick() {
      SblUserManagementService.getUserManual()
        .then(function(response) {
          console.log(response);
          if (response) {
            var data = response.data;
            var pdfWindow = window.open("");
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(data) +
                "'></iframe>"
            );
          } else {
            MessageBox.error("Произошла ошибка при загрузке инструкции");
          }
        })
        .catch(function(error) {
          console.error(error);
          MessageBox.error("Произошла ошибка при загрузке инструкции");
        });
    }
  },
  mounted() {
    this.fillCertificateList();
  }
};
</script>

<style lang="scss" >
@import "@/styles/variables.scss";

//позволяет dropdown-листу выскакивать за границы окошка
.v--modal-box {
  overflow: visible !important;
}
.sbl-modal-row-last {
	margin-bottom: 15px;
}
.certificate-login {
  height: 85%;
  background-color: $popup-background-color;
  color: $popup-font-color;

  &-next {
    min-width: 25%;
  }

  &-select-wrap {
    width: 80%;

    .vs__search {
        color: $input;
    }
  }
}
</style>
