<template>
  <button :class="getClasses" :disabled="disabled" @click="handleClick">
    <div>
      <img v-if="imgSrc" :src="imgSrc"><slot name="content"></slot><span>{{caption}}</span>
    </div>
  </button>
</template>

<script>
/**
 * Кнопка с закругленными клаями с возможностю добавления классов для стилизации.
 */
export default {
  name: "RoundedButton",
  props: {
    /**
     * Изображения для кнопки.
     */
    imgSrc: String,

    /**
     * Название кнопки.
     */
    caption: {
      type: String,
      required: true
    },

    /**
     * Css-классы кнопки.
     */
    additionalClasses: String,

    /**
     * Доступность кнопки.
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Получение списка css-классов кнопки.
     * @returns {String} Классы кнопки.
     */
    getClasses() {
      return this.defaultClasses + " " + this.additionalClasses;
    }
  },
  data() {
    return {
      defaultClasses: "sbl-button sbl-medium-button"
    };
  },
  methods: {
    /**
     * Обработка события нажатия на кнопку.
     */
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>
