<template>
<div class="email-validation-fail">
    <div class="sbl-h1">При проверке адреса электронной почты произошла ошибка. Обратитесь к Вашему персональному менеджеру.</div>
</div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

</style>
