<template>
  <BaseModal title="" @close="$emit('close')">
    <template v-slot:content>
      <div class="error-modal sbl-card">

        <!-- Caption -->
        <div class="self-align-center sbl-h2-text">
          <a>Ошибка</a>
        </div>

        <!-- Error description -->
        <div class="sbl-items-group error-modal-message">
          <img src="../assets/images/warning.png" alt="Error" />
          <a class="error-modal-text sbl-med-text">
              Мы не смогли авторизовать Вас по одной из причин: неверно указаны данные для входа или Вы не прошли 
              <router-link
                class="sign-in-remember sbl-link"
                @click.native="onSignUpClick"
                :to="{name: 'SignUp'}">регистрацию</router-link>. 
              Пожалуйста, проверьте корректность введенных данных, в случае необходимости 
              <router-link
                class="sign-in-remember sbl-link"
                @click.native="onPasswordRecoveryClick"
                :to="{name: 'PasswordRecovery'}">смените пароль</router-link> 
              или обратитесь в Службу поддержки.
          </a>
        </div>

        <!-- Buttons -->
        <div class="sbl-items-group self-align-center sbl-items-group-last-row">
          <RoundedButton caption="ОК" @click="onClose" additionalClasses="sbl-green-button"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";

/**
 * Модальное окно с сообщением об ошибке.
 */
export default {
  props: {
      /**
       * Колбек по нажатию на ссылку регистрации.
       */
      signUpCallback: {
          type: Function,
          default: undefined
      },

      /**
       * Колбек по нажатию на ссылку восстановления пароля.
       */
      passwordRecoveryCallback: {
          type: Function,
          default: undefined
      },
  },
  components: {
    RoundedButton,
    BaseModal
  },
  methods: {
    /**
     * Обработчик нажатия ссылки на регистрацию.
     */
    onSignUpClick: function() {
        this.signUpCallback?.();
        this.onClose();
    },

    /**
     * Обработчик нажатия ссылки на восстановление пароля.
     */
    onPasswordRecoveryClick: function() {
        this.passwordRecoveryCallback?.();
        this.onClose();
    },

    /**
     * Закрытие окна с вызовом доп.обработчика
     */
    onClose: function() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.error-modal {

  background-color: $popup-background-color;
  color: $popup-font-color;

  .sbl-items-group-last-row{
    gap:10px;
  }
  .sbl-inner-iframe {
    width: 100%;
    border-style: ridge;
    border-radius: 0.5em;
  }

  button {
    max-width: 150px;
  }

  &-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    word-break: break-word;

    img {
      margin-right: 10px;
      width: 40px;
    }

    &-text {
      white-space: pre-line;
      overflow-y: auto;
      padding-right: 10px;
    }
  }

  &-text {
    text-align: justify;
  }
}
</style>
