import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для реализации авторизации по токену.
 */
export class SblValidateRegistrationEmailServiceExt {

    static get ApiService() { return "SblValidateRegistrationEmailServiceExt.svc" }

    static get BaseUrl() { return "../0/ServiceModel/" }

    /**
     * Метод, проверяющий токен.
     * @param {String} token Токен. 
     */
    static validateRegistrationEmail(token) {

        return ApiConnection.get(this.ApiService + '/ValidateRegistrationEmail?token='+ token,{ baseURL: this.BaseUrl} );
	  }
	
	  /**
	   * Метод, отправляющий email.
	   * @param {String} token Токен. 
	   */
	  static resendEmail(token) {
		  const headers = {
			  "Accept": "application/json",
			  "Content-Type": "application/json"
		  };

		  return ApiConnection.post(this.ApiService + '/InitValidate', { token: token }, { baseURL: this.BaseUrl, headers: headers });
	  }
}
