<template>
	<div class="sbl-signing sbl-base-container rounded-corners">
		<div class="sbl-warning-card">
			<div class="sbl-warning-image"></div>
			<div class="sbl-info">
			<span>Для включения в состав участников лизинговой сделки вам необходимо дать согласие на подписание всех
			запрошеных документов. В противном случае в рассмотрении лизинговой сделки может быть отказано.
			</span>
			</div>
		</div>

		<div class="sbl-card-caption">
			<span class="sbl-h2-text">Информация о компании</span>
		</div>

		<div class="sbl-card">
			<div class="sbl-items-group">
				<div class="sbl-form-control-container">
					<input 
						required
						v-model="accountName"
						autocomplete="off" 
						class="sbl-form-control" 
						type="text"
						disabled
					/>
					<span class="floating-placeholder">Наименование компании *</span>
				</div>
			</div>

			<!-- ИНН/КПП -->
			<div class="sbl-items-group-multiple" id="innFormGoup">
				<!-- ИНН -->
				<div class="sbl-items-group">
					<div class="sbl-form-control-container">
						<input
							required
							v-model="accountInn"
							class="sbl-form-control"
							type="text"
							id="inn"
							disabled
						/>
						<span class="floating-placeholder">ИНН *</span>
					</div>
				</div>

				<!-- Spacer -->
				<div class="sbl-items-group sbl-items-group-spacer"/>

				<!-- КПП -->
				<div class="sbl-items-group">
					<div class="sbl-form-control-container">
						<input 
							required
							v-model="accountKpp"
							class="sbl-form-control"
							type="text"
							id="kpp"
							disabled
						/>
						<span class="floating-placeholder">КПП *</span>
					</div>
				</div>
			</div>

			<div class="sbl-items-group">
				<div class="sbl-h2-text">Персональные данные</div>
			</div>

			<div class="sbl-items-group">
				<div class="sbl-form-control-container">
					<input 
						required
						v-model="contactName"
						class="sbl-form-control" 
						type="text"
						id="fio"
						disabled
					/>
					<span class="floating-placeholder">ФИО *</span>
				</div>
			</div>

			<!-- Телефон/Email -->
			<div class="sbl-items-group-multiple" id="telephoneFormGoup">
				<!-- Телефон -->
				<div class="sbl-items-group">
					<div class="sbl-form-control-container">
						<input
							required
							v-model="phoneNumber"
							class="sbl-form-control"
							type="text"
							id="telephone"
							disabled
						/>
						<span class="floating-placeholder">Мобильный телефон *</span>
					</div>
				</div>

				<!-- Spacer -->
				<div class="sbl-items-group sbl-items-group-spacer"/>

				<!-- Email -->
				<div class="sbl-items-group">
					<div class="sbl-form-control-container">
						<input 
							required
							v-model="email"
							class="sbl-form-control"
							type="text"
							id="email"
							disabled
						/>
						<span class="floating-placeholder">Email *</span>
					</div>
				</div>
			</div>

			<!-- Серия/Номер -->
			<div class="sbl-items-group-multiple" id="passFormGoup">
				<!-- Серия паспорта -->
				<div class="sbl-items-group">
					<div class="sbl-form-control-container">
						<input
							required
							v-model="passportSerial"
							class="sbl-form-control"
							type="text"
							id="serie"
							disabled
						/>
						<span class="floating-placeholder">Серия паспорта *</span>
					</div>
				</div>

				<!-- Spacer -->
				<div class="sbl-items-group sbl-items-group-spacer"/>

				<!-- Номер паспорта -->
				<div class="sbl-items-group">
					<div class="sbl-form-control-container">
						<input 
							required
							v-model="passportNumber"
							class="sbl-form-control"
							type="text"
							id="passNum"
							disabled
						/>
						<span class="floating-placeholder">Номер паспорта *</span>
					</div>
				</div>
			</div>

			<!-- Checkboxes -->
			<div class="sbl-items-group">
				<!-- Правила ПЭП -->
				<div v-show="isPepRulesVisible" class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isPepRules"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
					/>
					<span class="sbl-small-text">Соглашение об использовании простой электронной подписи</span>
					<button
						class="sbl-show-image"
						@click="onShowPreviewDocumentClick('PepRules')">
					</button>
				</div>
				<!-- Согласие ЭДО -->
				<div v-show="isConsentEdoVisible" class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isConsentEdo"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
					/>
					<span class="sbl-small-text">Заявление на присоединение к правилам электронного документооборота</span>
					<button
						class="sbl-show-image"
						@click="onShowPreviewDocumentClick('ConsentEdo')">
					</button>
				</div>
				<!-- БКИ ФЛ -->
				<div v-show="isBkiFlVisible" class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isBkiFl"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
					/>
					<span class="sbl-small-text">Согласие на предоставление данных в бюро кредитных историй на физическое лицо</span>
					<button
						class="sbl-show-image"
						@click="onShowPreviewDocumentClick('BkiFl')">
					</button>
				</div>
				<!-- БКИ ЮЛ -->
				<div v-show="isBkiUlVisible" class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isBkiUl"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
					/>
					<span class="sbl-small-text">Согласие на предоставление данных в бюро кредитных историй на юридическое лицо</span>
					<button
						class="sbl-show-image"
						@click="onShowPreviewDocumentClick('BkiUl')">
					</button>
				</div>
				<!-- Согласие на передачу БТ -->
				<div v-show="isConsentToTransferBtVisible" class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isConsentToTransferBt"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
					/>
					<span class="sbl-small-text">Согласие на передачу документов и информации</span>
					<button
						class="sbl-show-image"
						@click="onShowPreviewDocumentClick('ConsentToTransferBt')">
					</button>
				</div>
				<!-- СОПД -->
				<div v-show="isSopdVisible" class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isSopd"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
					/>
					<span class="sbl-small-text">Согласие на обработку персональных данных</span>
					<button
						class="sbl-show-image"
						@click="onShowPreviewDocumentClick('Sopd')">
					</button>
				</div>
				<!-- Выбрать всё -->
				<div class="sbl-form-control-container sbl-checkbox-spacer">
					<input 
						required
						v-model="isAllSelected"
						class="sbl-checkbox"
						type="checkbox"
						id="agreement"
						@change="onSelectedAllDocs"
					/>
					<span class="sbl-small-text">Выбрать все</span>
				</div>
			</div>

			<div class="sbl-items-group">
				<span class="sbl-small-text">
					<a
						v-bind:href="usingPEPRulesURL"
						target="_blank"
						class="sbl-link"
					>Правила осуществления электронного документооборота при использовании ПЭП</a>
				</span>
				<span class="sbl-small-text">
					<a
						v-bind:href="personalDataRulesURL"
						target="_blank"
						class="sbl-link"
					>Политика безопасности обработки персональных данных</a>
				</span>
				<span class="sbl-small-text">
				Переходя к подписанию, вы подтверждаете, что ознакомились с текстом согласий и общими положениями АО "СБЕРБАНК ЛИЗИНГ"
				</span>
			</div>


			<!-- Button -->
			<div class="sbl-items-group-multiple sbl-button-group">
				<RoundedButton
					caption="Подписать"
					additionalClasses="sbl-green-button"
					@click="onSignButtonClick()"
				/>
				<div class="sbl-items-group sbl-items-group-spacer"/>
				<RoundedButton
					caption="Отклонить"
					additionalClasses="sbl-white-button"
					@click="onCancelButtonClick()"
				/>
			</div>

		</div>
	<Spinner v-if="isLoading"></Spinner>
	</div>
</template>

<script>

import RoundedButton from "@/components/RoundedButton";
import ErrorModal from "../components/ErrorModal";
import InfoModal from "../components/InfoModal";
import SmsConfirmationModal from "../components/SmsConfirmationModal";
import ConfirmPhoneModal from "../components/ConfirmPhoneModal";

import { SblUserManagementService } from "../services/api/SblUserManagementService.js"
import SmsConfirmModalService from "@/services/SmsConfirmModalService.js"
import ModalService from "@/services/ModalService.js";

import { Messages } from "../enums/Messages.js";
import { CustomLinks } from "../enums/CustomLinks.js";

export default {
	name: "Signing",
	components: {
		RoundedButton,
		SmsConfirmationModal,
		ConfirmPhoneModal
	},
	data() {
		return {
			/**
			 * Наименование организации.
			 */
			accountName: "",

			/**
			 * ИНН.
			 */
			accountInn: "",
			
			/**
			 * КПП.
			 */
			accountKpp: "",
			
			/**
			 * ФИО.
			 */
			contactName: "",
			
			/**
			 * Мобильный телефон.
			 */
			phoneNumber: "",
			
			/**
			 * Email.
			 */
			email: "",
			
			/**
			 * Серия паспорта.
			 */
			passportSerial: "",
			
			/**
			 * Номер паспорта.
			 */
			passportNumber: "",

			/**
			 * Чекбокс "БКИ ФЛ".
			 */
			isBkiFl: false,

			/**
			 * Чекбокс "БКИ ЮЛ".
			 */
			isBkiUl: false,

			/**
			 * Чекбокс "Согласие ЭДО".
			 */
			isConsentEdo: false,

			/**
			 * Чекбокс "Согласие на передачу БТ".
			 */
			isConsentToTransferBt: false,

			/**
			 * Чекбокс "Правила ПЭП".
			 */
			isPepRules: false,

			/**
			 * Чекбокс "СОПД".
			 */
			isSopd: false,

			/**
			 * Чекбокс "Выбрать все".
			 */
			isAllSelected: false,

			/**
			 * Видимость чекбокса "БКИ ФЛ".
			 */
			isBkiFlVisible: false,

			/**
			 * Видимость чекбокса "БКИ ЮЛ".
			 */
			isBkiUlVisible: false,

			/**
			 * Видимость чекбокса "Согласие ЭДО".
			 */
			isConsentEdoVisible: false,

			/**
			 * Видимость чекбокса "Согласие на передачу БТ".
			 */
			isConsentToTransferBtVisible: false,

			/**
			 * Видимость чекбокса "Правила ПЭП".
			 */
			isPepRulesVisible: false,

			/**
			 * Видимость чекбокса "СОПД".
			 */
			isSopdVisible: false,

			/**
			 * Токен.
			 */
			tokenId: "",

			/**
			 * Идет ли какая-либо загрузка.
			 */
			isLoading: false,

			/**
			 * Значение системной настройки "URL правил использования ПЭП".
			 */
			usingPEPRulesURL: "",

			/**
			 * Значение системной настройки "URL правил на обработку персональных данных".
			 */
			personalDataRulesURL: ""
		};
	},
  	methods: {
		/**
		 * Открыть печатную форму на просмотр.
		 * @param {String} typeCode Код выбранного документа.
		 */
		onShowPreviewDocumentClick(typeCode) {
			if (!this.tokenId || !typeCode) {
				return;
			}

			this.isLoading = true;

			SblUserManagementService.getDocumentPrintFormDraft(this.tokenId, typeCode)
			.then((response) => {
				let data = response?.data?.GetDocumentPrintFormDraftResult;

				if (data) {
					let pdfWindow = window.open("");
					pdfWindow.document.write(
						"<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
						encodeURI(data) +
						"'></iframe>"
					);
				} else {
					this.showErrorModal.call(this);
				}
			})
			.catch((error) => {
				this.showErrorModal.call(this);
			})
			.finally(() => {
				this.isLoading = false;
			});
		},

		/**
		 * Обработчик события checked у чекбокса "Выбрать всё".
		 */
		onSelectedAllDocs() {
			if (this.isBkiFlVisible) {
				this.isBkiFl = this.isAllSelected;
			}
			if (this.isBkiUlVisible) {
				this.isBkiUl = this.isAllSelected;
			}
			if (this.isConsentEdoVisible) {
				this.isConsentEdo = this.isAllSelected;
			}
			if (this.isConsentToTransferBtVisible) {
				this.isConsentToTransferBt = this.isAllSelected;
			}
			if (this.isPepRulesVisible) {
				this.isPepRules = this.isAllSelected;
			}
			if (this.isSopdVisible) {
				this.isSopd = this.isAllSelected;
			}
		},

		onSignButtonClick() {
			let isAnySelected = this.checkIsAnyDocumentSelected();

			if (!isAnySelected) {
				this.showErrorModal(Messages.SblIsNeedToSelectDocumentsForSigningMessage);
				return;
			}

			SblUserManagementService.createPepCode(this.tokenId)
			.then((response) => {
				let data = response?.data?.CreatePepCodeResult;

				if (data) {
					if (data.redirectToErrorPage) {
						this.openSigningFailPage();
					} else if (data.success) {
						this.showSmsModal.call(this, data.codeId);
					} else {
						this.showErrorModal.call(this);
					}
				} else {
					this.showErrorModal.call(this);
				}
			})
			.catch((error) => {
				this.showErrorModal.call(this);
			});
		},

		/**
		 * Отобразить окно ввода СМС.
		 * @param {any} codeId Id кода подтверждения.
		 */
		showSmsModal(codeId) {
			ModalService.show({
				component: SmsConfirmationModal,
				props: {
					modalData: {
						tokenId: this.tokenId,
						codeId: codeId
					},
					confirmButtonFunc: SmsConfirmModalService.confirmSigningSmsCode,
					resendCodeFunc: SmsConfirmModalService.resendSigningSmsCode,
					isSeparateMode: false
				},
				settings: {
					width:"560px"
				}
			})
			.then(() => {
				this.callStartSigning.call(this);
			})
			.catch(() => {
				this.showErrorModal.call(this);
			});
		},

		/**
		 * Отобразить окно подтверждения доступа к номеру телефона.
		 * @param {string} phoneNumber Номер телефона для валидации.
		 */
		showConfirmSmsPhoneValidationModal(phoneNumber) {
			ModalService.show({
				component: SmsConfirmationModal,
				props: {
					modalData: {
						tokenId: this.tokenId,
						phoneNumber: phoneNumber
					},
					confirmButtonFunc: SmsConfirmModalService.confirmPhoneSmsCode,
					resendCodeFunc: SmsConfirmModalService.resendPhoneSmsCode,
					isSeparateMode: false
				},
				settings: {
					width:"560px"
				}
			})
			.then((response) => {
				this.initializePageDataModel.call(this, response);
			})
			.catch(() => {
				this.showErrorModal.call(this);
			});
		},

		/**
		 * Отобразить окно подтверждения телефона.
		 */
		showConfirmPhoneModal() {
			ModalService.show({
				component: ConfirmPhoneModal,
				props: {
					tokenId: this.tokenId
				},
				settings: {
					width:"560px"
				}
			})
			.then((response) => {
				if (response?.success) {
					this.showConfirmSmsPhoneValidationModal.call(this, response.phoneNumber);
				} else if (response?.resultCode == 0) {
					this.showErrorModal(Messages.SblWrongEnteredDataError, this.showConfirmPhoneModal);
				} else {
					this.showErrorModal.call(this, null, this.openSberleasingSite.bind(this));
				}
			})
			.catch(() => {
				this.showErrorModal.call(this, null, this.openSberleasingSite.bind(this));
			});
		},

		/**
		 * Запустить подписание.
		 */
		callStartSigning() {
			let data = {
				tokenId : this.tokenId,
				isBkiFl : this.isBkiFl,
				isBkiUl : this.isBkiUl,
				isConsentEdo : this.isConsentEdo,
				isConsentToTransferBt : this.isConsentToTransferBt,
				isSopd : this.isSopd,
				isPepRules : this.isPepRules
			};

			this.isLoading = true;

			SblUserManagementService.startSigning(data)
			.then((response) => {
				this.isLoading = false;

				let success = response?.data?.StartSigningResult;

				if (success) {
					this.showSuccessInfoModal(Messages.SblSuccessSigningMessage);
				} else {
					this.showErrorModal.call(this);
				}
			})
			.catch((error) => {
				this.isLoading = false;
				this.showErrorModal.call(this);
			});
		},

		/**
		 * Обработчик нажатия на кнопку Отклонить.
		 */
		onCancelButtonClick() {
			this.isLoading = true;

			SblUserManagementService.cancelSigning(this.tokenId)
			.then((response) => {
				this.isLoading = false;

				if (response.status != 200) {
					this.showErrorModal.call(this);
				} else {
					this.showSuccessInfoModal.call(this, Messages.SblSuccessCancelSigningMessage);
				}
			})
			.catch((error) => {
				this.isLoading = false;
				this.showErrorModal.call(this);
			});
		},

		/**
		 * Открыть главную страницу сайта Сберлизинг.
		 */
		openSberleasingSite() {
			window.location.replace(CustomLinks.SberleasingSite);
		},

		/**
		 * Открыть информационную страницу с истекшим сроком токена.
		 */
		openSigningFailPage() {
			this.$router.replace({ name: "SigningFail", query: null });
		},

		/**
		 * Открыть информационную страницу использованного токена.
		 */
		openSigningSuccessPage() {
			this.$router.replace({ name: "SigningSuccess", query: null });
		},

		/**
		 * Отобразить успешное сообщение.
		 * @param {string} message Сообщение.
		 * @param {function} callback Функция обратного вызова.
		 */
		showErrorModal(message, callback) {
			message = message || Messages.SblCommonError;

			ModalService.show({
				component: ErrorModal,
				props: {
					title: Messages.SblCommonErrorTitle,
					showRegisterButton: false,
					message: message,
					okClickHandler: callback ?? null,
					showCloseButton: false
				},
				settings: {
					height: "auto",
					width: "545px"
				}
			});
		},

		/**
		 * Отобразить успешное сообщение.
		 */
		showSuccessInfoModal(message) {
			ModalService.show({
				component: InfoModal,
				props: {
					message: message,
					okClickHandler: this.openSberleasingSite.bind(this)
				},
				settings: {
					height: "auto",
					width: "545px"
				}
			});
		},

		/**
		 * Проверка на хотя бы 1 выбранный докумендля подписания.
		 * @returns Boolean True, если хотя бы один документ выбран.
		 */
		checkIsAnyDocumentSelected() {
			return this.isBkiFl || this.isBkiUl || this.isConsentEdo ||
				this.isConsentToTransferBt || this.isPepRules || this.isSopd;
		},

		/**
		 * Инициализировать модель данных страницы.
		 */
		checkIsTokenCorrect() {
			SblUserManagementService.checkIsTokenCorrect(this.tokenId)
			.then((response) => {
				let data = response?.data?.CheckIsTokenCorrectResult;

				if (data) {
					this.showConfirmPhoneModal.call(this);
				} else {
					this.openSigningFailPage();
				}
			})
			.catch((error) => {
				this.showErrorModal.call(this);
			});
		},

		/**
		 * Инициализировать модель данных страницы.
		 * @param {object} data Данные из токена.
		 */
		initializePageDataModel(data) {
			window.console.log(data);

			if (data) {
				if (data.redirectToErrorPage) {
					this.openSigningFailPage();
				} else if (data.isTokenUsed) {
					this.openSigningSuccessPage();
				} else if (data.success) {
					this.accountName = data.accountName;
					this.accountInn = data.accountInn;
					this.accountKpp = data.accountKpp;
					this.contactName = data.contactName;
					this.phoneNumber = data.phoneNumber;
					this.email = data.email;
					this.passportSerial = data.passportSerial;
					this.passportNumber = data.passportNumber;
					this.isBkiFlVisible = data.isBkiFl;
					this.isBkiUlVisible = data.isBkiUl;
					this.isConsentEdoVisible = data.isConsentEdo;
					this.isConsentToTransferBtVisible = data.isConsentToTransferBt;
					this.isPepRulesVisible = data.isPepRules;
					this.isSopdVisible = data.isSopd;
				} else {
					this.showErrorModal.call(this, null, this.openSberleasingSite);
				}
			} else {
				this.showErrorModal.call(this, null, this.openSberleasingSite);
			}
		},

		/**
		 * Инициализировать данные сис. настроек.
		 */
		initializeSettingsValue() {
			SblUserManagementService.getSblUsingPEPRulesURL()
			.then((response) => {
				if (response?.data) {
					this.usingPEPRulesURL = response?.data;
				}
			});
			

			SblUserManagementService.getSblPersonalDataRulesURL()
			.then((response) => {
				if (response?.data) {
					this.personalDataRulesURL = response?.data;
				}
			});
		}
	},
	mounted() {
		let router = this.$router.history.current;
		let query = router && router.query;
		let token = query && query.token;

		if (!token) {
			this.openSigningFailPage();
		}

		this.tokenId = token;

		this.checkIsTokenCorrect.call(this);
		this.initializeSettingsValue.call(this);
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/elements.scss";

@media (min-width: 864px) {

	.sbl-base-container {
		margin: 15vh auto;
		width: 40%;
		min-width: 864px;
		border-radius: $border-rad;
		box-shadow: none;
	}

	.sbl-medium-button {
		width: 150px;
	}
	.sbl-checkbox-spacer {
		margin-bottom: 20px;
	}
	.sbl-checkbox-spacer:last-child {
		margin-bottom: 0px;
	}

	.sbl-checkbox-spacer > .sbl-small-text {
		margin-left: 5px;
	}
}

@media (max-width: 864px) {
	.sbl-base-container {
		margin: 15vh auto;
		width: 80%;
		min-width: 320px;
		border-radius: $border-rad;
		box-shadow: none;
	}

	.sbl-items-group-multiple > .sbl-items-group {
		width: 100%;
	}
	.sbl-items-group-multiple {
		flex-direction: column;
	}

	.sbl-medium-button {
		width: 100%;
	}
}
.sbl-signing {
	
	font-family: "SB Sans Display";

	.sbl-med-text {
		font-size: 14px;
		font-weight: 400;
		color: #293C4D;
	}
	.sbl-h2-text {
		font-size: 24px;
		font-weight: 400;
		color: #293C4D;
	}

	.sbl-card-caption {
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
	}

	.sbl-card {
		padding-top: 15px;
		padding-bottom: 15px;
		border-radius: 20px;
		border: 1px solid #D8E1E8;
		background-color: #FFFFFF;
		margin-bottom: 30px;
	}

	.sbl-warning-card {
		display: flex;
		flex-direction: row;
		background: white;
		height: auto;
		width: 100%;
		border: 1px solid #D8E1E8;
		border-radius: 16px;
		margin-bottom: 30px;
	}

	.sbl-warning-image {
		min-width: 36px;
		min-height: 36px;
		margin-left: 20px;
		background: no-repeat center;
		background-image: url(~@/assets/images/tip.svg);
	}

	.sbl-show-image {
		min-width: 50px;
		min-height: 30px;
		width: 50px;
		height: 30px;
		background: no-repeat center;
		border: 1px solid #D8E1E8;
		border-radius: 100px;
		margin-left: 20px;
		cursor: pointer;
		background-image: url(~@/assets/images/follow.svg);
	}

	.sbl-checkbox {
		content: "";
		display: inline-block;
		width: 100%;
		height: 20px;
		background: url(~@/assets/images/check-box-off.svg) no-repeat;
		max-width: 21px;
	}

	.sbl-checkbox:checked {
		background: url(~@/assets/images/check-box-on.svg) no-repeat;
	}

	.sbl-small-text {
		font-size: 16px;
		line-height: 28px;
	}

	.sbl-items-group-multiple {
		margin-top: 0;
	}

	.sbl-info {
		padding: 20px;
	}

	.sbl-button-group {
		margin-top: 30px;
		margin-bottom: 15px;

		button:first-child {
			margin-left: auto;
		}

		button:last-child {
			margin-right: auto;
		}
	}

	#passFormGoup {
		margin-bottom: 20px;
	}
}
</style>
