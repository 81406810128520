/**
 * Типы ошибок сертификата.
 */
export const CertificateErrorTypes = {
  /**
   * Незаполненные поля.
   */
  EMPTY_FIELDS: 0,

  /**
   * Ошибка в данных.
   */
  INVALID_CONTENT: 1,

  /**
   * Недоверенный.
   */
  NOT_TRUSTED: 2,

  /**
   * Сертификат не принадлежит руководителю.
   */
  NOT_GENERAL_DIRECTOR: 3,

  /**
   * Неизвестная ошибка.
   */
  UNDEFINED_EXCEPTION: 4,

  /**
   * Пользовательская ошибка.
   */
  CUSTOM_ERROR: 5,
}