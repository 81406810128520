import Vue from 'vue';
import Router from 'vue-router';
import SignIn from '@/views/SignIn';
import SignUp from '@/views/SignUp';
import PasswordRecovery from '@/views/PasswordRecovery';
import PasswordRecoveryConfirm from '@/views/PasswordRecoveryConfirm';
import SignUpWithPersonalData from '@/views/SignUpWithPersonalData';
import ChangePassword from '@/views/ChangePassword';
import EmailValidationSuccess from '@/views/EmailValidationSuccess';
import EmailValidationFail from '@/views/EmailValidationFail';
import LoginByToken from '@/views/LoginByToken';
import { AuthenticationErrorCodes } from '@/enums/AuthenticationErrorCodes';
import RegistrationConfirm from '@/views/RegistrationConfirm';
import RegistrationFail from '@/views/RegistrationFail';
import RegistrationSuccess from '@/views/RegistrationSuccess';
import ConfirmationEmailExpired from '@/views/ConfirmationEmailExpired';
import Signing from '@/views/Signing';
import SigningFail from '@/views/SigningFail';
import SigningSuccess from '@/views/SigningSuccess';

Vue.use(Router);

const router = new Router({
	mode: 'history',
	routes: [
		{
			path: "/",
			component: {
				render(c) {
					return c('router-view')
				}
			},
			redirect: {
				name: "SignIn"
			}
		},
		{
			path: "/index.html",
			component: {
				render(c) {
					return c('router-view')
				}
			},
			redirect: {
				name: "SignIn"
			}
		},
		{
			path: '/Authorization',
			component: {
				render(c) {
					return c('router-view')
				}
			},
			children: [
				{
					path: '/',
					component: {
						render(c) {
							return c('router-view')
						}
					},
					redirect: {
						name: "SignIn"
					}
				},
				{
					path: 'index.html',
					component: {
						render(c) {
							return c('router-view')
						}
					},
					redirect: {
						name: "SignIn"
					}
				},
				{
					path: "EmailValidationSuccess",
					component: {
						render(c) {
							return c('router-view')
						}
					},
					redirect: {
						name: "EmailValidationSuccess"
					}
				},
				{
					path: "EmailValidationFail",
					component: {
						render(c) {
							return c('router-view')
						}
					},
					redirect: {
						name: "EmailValidationFail"
					}
				},
				{
					path: 'sign-in',
					name: 'SignIn',
					component: SignIn
				},
				{
					path: 'sign-up',
					name: 'SignUp',
					component: SignIn,
					props: {
						IsSignUp: true
					}
				},
				{
					path: 'sign-up-old',
					name: 'SignUpOld',
					component: SignUp,
				},
				{
					path: 'password-recovery',
					name: 'PasswordRecovery',
					component: PasswordRecovery,
				},
				{
					path: 'password-recovery-confirm',
					name: 'PasswordRecoveryConfirm',
					component: PasswordRecoveryConfirm,
					props: true
				},
				{
					path: 'sign-up-personal',
					name: 'SignUpWithPersonalData',
					component: SignUpWithPersonalData,
				},
				{
					path: 'sign-up-personal-certificate',
					name: 'SignUpWithPersonalDataFromCertificate',
					component: SignUpWithPersonalData,
				},
				{
					path: 'sign-up-personal-token',
					name: 'SignUpWithPersonalDataFromToken',
					component: SignUpWithPersonalData,
				},
				{
					path: 'change-password',
					name: 'ChangePassword',
					component: ChangePassword,
				},
				{
					path: 'email-validation-success',
					name: 'EmailValidationSuccess',
					component: EmailValidationSuccess,
				},
				{
					path: 'email-validation-fail',
					name: 'EmailValidationFail',
					component: EmailValidationFail,
				},
				{
					path: 'sign-in-token',
					name: 'SignInWithToken',
					component: LoginByToken,
				},
				{
					path: 'registration-confirm',
					name: 'RegistrationConfirm',
					component: RegistrationConfirm,
				},
				{
					path: 'registration-fail',
					name: 'RegistrationFail',
					component: RegistrationFail,
				},
				{
					path: 'registration-success',
					name: 'RegistrationSuccess',
					component: RegistrationSuccess,
				},
				{
					path: 'confirmation-email-expired',
					name: 'ConfirmationEmailExpired',
					component: ConfirmationEmailExpired,
				},
				{
					path: 'signing',
					name: 'Signing',
					component: Signing,
				},
				{
					path: 'signing/fail',
					name: 'SigningFail',
					component: SigningFail,
				},
				{
					path: 'signing/success',
					name: 'SigningSuccess',
					component: SigningSuccess,
				}
			]
		},
	],
});

router.beforeEach((to, from, next) => {
	// параметр, который добавляется при нажатии кнопки "выйти", не нужен на кастомной странице и создает лишний редирект
	if (to && to.query && to.query.hasOwnProperty("simpleLogin") && to.query.ReturnUrl === "/?simpleLogin") {
		next({
			name: "SignIn"
		});
	}

	// При возникновении в роуте кода ошибки 8 - редирект на страницу восстановления пароля
	let matches = window.location.href.match(/ErrorCode=(\d)/);
	var errorCode = matches && parseInt(matches[1], 10);
	if (errorCode === AuthenticationErrorCodes.TempPassword ||
		errorCode === AuthenticationErrorCodes.ChangePassword) {
		window.location.href = window.location.origin + "/Authorization/change-password";
	} else {
		next();
	}
});

export default router;
