<template>
  <BaseModal :show-close-button="false" title="" @close="$emit('close')">
    <template v-slot:content>
      <div class="error-modal sbl-card">

        <!-- Caption -->
        <div class="self-align-center sbl-error-caption sbl-h2-text">
          {{SblUserBlockedMessage}}
        </div>

        <!-- Error description -->
        <div class="sbl-error-modal-message-main">
          {{SblWrongPasswordNumberMessage}}
        </div>

        <div class="sbl-error-modal-message">
          {{SblWrongPasswordTryAgainMessage}}
        </div>
        <!-- Buttons -->
        <div class="sbl-items-group self-align-center sbl-items-group-last-row">
          <RoundedButton caption="ОК" @click="onClose" additionalClasses="sbl-green-button"/>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";
import { Messages } from "../enums/Messages.js";

/**
 * Модальное окно с сообщением об ошибке.
 */
export default {
  props: {
    /**
     * Время блокировки пользователя.
     */
    blockDuration: 0
  },
  components: {
    RoundedButton,
    BaseModal
  },
  data: function() {
    let loginAgainTime = this.blockDuration > 60
      ? Math.floor(this.blockDuration / 60) + " ч. " + (this.blockDuration % 60) + " мин."
      : (this.blockDuration) + " мин.";
    return {

      SblUserBlockedMessage: Messages.SblUserBlockedMessage,

      SblWrongPasswordNumberMessage: Messages.SblWrongPasswordNumberMessage,

      SblWrongPasswordTryAgainMessage: Messages.SblWrongPasswordTryAgainMessage + loginAgainTime
    }
  },
  methods: {
    /**
     * Закрытие окна с вызовом доп.обработчика
     */
    onClose: function() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.error-modal {

  background-color: $popup-background-color;
  color: $popup-font-color;

  .sbl-error-modal-message-main{
    margin-top: 15px;
    text-align: center;
  }

  .sbl-error-modal-message{
    text-align: center;
  }

  .sbl-error-caption {
    margin-top: 20px;
  }

  .sbl-items-group-last-row{
    gap:10px;
  }

  .sbl-inner-iframe {
    width: 100%;
    border-style: ridge;
    border-radius: 0.5em;
  }

  button {
    max-width: 150px;
  }

  &-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    word-break: break-word;

    img {
      margin-right: 10px;
      width: 40px;
    }

    &-text {
      white-space: pre-line;
      overflow-y: auto;
      padding-right: 10px;
    }
  }

  &-text {
    text-align: justify;
  }
}
</style>
