<template>
	<div>
		<div v-if="!isLoading">
			<BaseModal title="Введите код подтверждения" @close="close()">
			<template v-slot:content>
				<label class="sbl-common-text confirm-sms__common-text">Вам отправлено SMS на телефон</label>
				<div class="confirm-sms">
					<div class="sbl-form-group">
						<div class="sbl-form-control-container">
							<PincodeInput
								:length="inputLength"
								additionalInputClasses='sbl-form-control'
								v-model="confirmationCode"
								v-on:enter-pressed="pinCodeEnterPressed"
								v-if="isSeparateMode"
							/>
						</div>
						<input
							v-if="!isSeparateMode"
							v-model="confirmationCode"
							class="single-input"
							type="text"
							id="confirmationCodeId"
							:maxlength="inputLength"
							@keypress="onInputKeypress"
							@keydown ="onInputKeydown"
							@keyup="onInputKeyup"
						/>
						<span v-if="errorMessage" class="sbl-form-control-error">{{errorMessage}}</span>
					</div>
					<div class="confirm-sms__buttons-container">
						<RoundedButton
							:caption="confirmButtonCaption"
							additionalClasses="sbl-green-button" 
							:disabled="!confirmationCode || confirmationCode?.length < 6"
							@click="onConfirmButtonClicked()"
						/>
						<!-- Капча -->
						<div id="yandexSmartCaptchaModal" class="sbl-items-group smart-captcha-model"></div>
						<p id="resendTextInformation" class="sbl-common-text">Не получили код подтверждения?<br> 
							<span v-if="isCounting">Попробуйте через {{counter}} сек.</span>
							<a class="sbl-link" v-if="!isCounting" href="#" v-on:click="onResendSMSClicked()">Отправить повторно</a>
						</p>
					</div>
				</div>
			</template>
			</BaseModal>
		</div>
		<Spinner v-if="isLoading"></Spinner>
	</div>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";
import PincodeInput from '../components/PinCodeInput';
import { Messages } from "../enums/Messages.js";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";

/**
 * Модальное окно ввода кода подтверждения смс.
 */
export default {
	name: "SmsConfirmationModal",
	props: {
		/**
		 * Внещний параметры для модалки.
		 */
		modalData: {
			type: Object,
			required: false
		},

		/**
		 * Ответ от модалки.
		 */
		promise: {
			type: Object,
			required: true
		},

		/**
		 * Заголовок подтверждающей код кнопки.
		 */
		confirmButtonCaption: {
			type: String,
			default: "Подтвердить"
		},

		/**
		 * Обработчик подтверждения кода.
		 */
		confirmButtonFunc: {
			type: Function,
			required: true
		},

		/**
		 * Обработчик перезапроса кода.
		 */
		resendCodeFunc: {
			type: Function,
			required: true
		},

		/**
		 * Режим отображения инпутов формы.
		 * true - разделенное отображение.
		 * false - один инпут без разделения.
		 */
		isSeparateMode: {
			type: Boolean,
			default: false
		},

		/**
		 * Длина кода подтверждения.
		 */
		inputLength: {
			type: Number,
			default: 6
		}
	},
	data() {
		return {
			/**
			 * Данные для запроса.
			 */
			data: null,

			/**
			 * Код подтверждения.
			 */
			confirmationCode: null,

			/**
			 * Сообщение об ошибке.
			 */
			errorMessage: null,
			/**
			 * Счетчик.
			 */
			counter: 0,

			/**
			 * Идет ли какая-либо загрузка.
			 */
			isLoading: false,
			
			/**
			 * Работает ли счетчик.
			 */
			isCounting: true
		};
	},
	components: {
		RoundedButton,
		BaseModal,
		PincodeInput
	},
	methods: {
		/**
		 * Обработчик события keypress у кода подтверждения.
		 */
		onInputKeypress(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			
			if (charCode < 48 || charCode > 57) {
				evt.preventDefault();
			}
		},
		
		/**
		 * Обработчик события keydown у кода подтверждения.
		 */
		onInputKeydown(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			
			if (charCode < 48 || charCode > 57) {
				return;
			}
		},

		/**
		 * Обработчик события keyup у кода подтверждения.
		 */
		onInputKeyup(evt) {
			evt = (evt) ? evt : window.event;

			if (evt.target.value.length == this.inputLength) {
				this.pinCodeEnterPressed();
			}
		},

		/**
		 * Закрытие модального окна.
		 */
		close() {
			this.$emit("close");
		},

		/**
		 * Старт таймера.
		 */
		startTimer() {
			this.counter = 60;
			this.countDown();
		},

		/**
		 * Обработчик ввода кода подтвреждения.
		 */
		pinCodeEnterPressed: function() {
			if(!this.confirmationCode || !this.confirmationCode.length){
				return;
			}
			this.onConfirmButtonClicked.call(this);
		},

		/**
		 * Обработчик события нажатия на кнопку "Подтвердить".
		 */
		onConfirmButtonClicked() {
			this.isLoading = true;
			
			if (this.data) {
				this.data.code = this.confirmationCode;
			}

			this.confirmButtonFunc.call(this)
			.then(response => {
				this.promise.resolve(response);
				return this.close();
			})
			.catch(error => {
				this.confirmationCode = "";
				this.errorMessage = error.message;
			})
			.finally(() => (this.isLoading = false));
		},

		/**
		 * Отсчет таймера
		 */
		countDown() {
			if (this.counter) {
				this.isCounting = true;
				return setTimeout(() => {
					--this.counter;
					this.countDown();
				}, 1000);
			} else {
				this.isCounting = false; 
			}  
		},

		/**
		 * Обработчик события нажатия на ссылку "Отправить повторно".
		 */
		onResendSMSClicked() {
			this.errorMessage = "";
			this.confirmationCode = "";
			this.data.codeId = "";
			this.data.code = null;
			
			this.resendCodeFunc.call(this)
			.then(response => {
				this.data.codeId = response.codeId || "";
				this.startTimer();
			})
			.catch(error => {
				this.errorMessage = error.message;
			});
		},

		/**
		 * Восстановление пароля.
		 */
		recoverPassword() {
			const scope = this;			
			if (this.data.inn) {
				SblUserManagementService.recoverPasswordWithInn(this.data)
				.then(response => scope.recoverPasswordCallback(response, scope))
				.catch(error => {
					console.error(error);
					scope.errorMessage = Messages.SblSmsModalCommonError;
					window.smartCaptcha.reset(this.captchaWidgetId);
				});
			} else {
				SblUserManagementService.recoverPassword(this.data)
				.then(response => scope.recoverPasswordCallback(response, scope))
				.catch(error => {
					console.error(error);
					scope.errorMessage = Messages.SblSmsModalCommonError;
					window.smartCaptcha.reset(this.captchaWidgetId);
				});
			}	
		},

		/**
		 * Коллбэк-функция восстановления пароля.
		 */
		recoverPasswordCallback(response, scope) {			
			if (response) {
				let data = response && response.data;
				scope.recoveryButtonDisabled = false;
				if (!data.IsSuccess) {
					if (data.ErrorCode) {
						if (data.ErrorCode === "InternalServerError") {
							scope.errorMessage = data.Error;
						} else {
							console.error(data.Error, data);
							scope.errorMessage = data.Error;
							window.smartCaptcha.reset(scope.captchaWidgetId);
							return;
						}
					} else {
						console.error(data.Error, data);
						scope.errorMessage = "В процессе восстановления пароля возникла ошибка. Повторите позднее или обратитесь в Службу поддержки";
						window.smartCaptcha.reset(scope.captchaWidgetId);
						return;
					}
				}
				else {
					window.smartCaptcha.destroy(scope.captchaWidgetId);
					const container = document.getElementById('yandexSmartCaptchaModal');
					container.style.display = "none";
					scope.startTimer();
				}
			}

			const resendTextInformation = document.getElementById('resendTextInformation');
			resendTextInformation.style.display = "block";
		},

		/**
		 * Смена пароля с помощью SMS.
		 */
		changePasswordWithSMS() {
			const scope = this;
			SblUserManagementService.ChangePasswordWithSMS({
				Login: this.data.login,
				Password: this.data.oldPassword,
				NewPassword: this.data.password,
				YSmartCaptchaResponse: this.data.captcha,
				Inn: this.data.inn
			}).then(response => {
				if (!response || !response.data) {
					scope.errorMessage = Messages.SblSmsModalCommonError;
					window.smartCaptcha.reset(scope.captchaWidgetId);
					return;
				}

				if (!response.data.success){
					scope.errorMessage = Messages.SblSmsModalCommonError;
					window.smartCaptcha.reset(scope.captchaWidgetId);
					return;
				}

				window.smartCaptcha.destroy(scope.captchaWidgetId);
				const container = document.getElementById('yandexSmartCaptchaModal');
				container.style.display = "none";
				scope.startTimer();
				const resendTextInformation = document.getElementById('resendTextInformation');
				resendTextInformation.style.display = "block";
			})
			.catch(error => {
				window.console.error(error);
				scope.errorMessage = Messages.SblSmsModalCommonError;
			});
		},

		/**
		 * Событийный метод при успешном решения капчи.
		 * @param {String} token токен капчи.
		 */
		onSmartCaptchaSuccessCallback(token, resendCodeFunc) {
			this.data.captcha = token;
			resendCodeFunc.call(this);
		},

		/**
		 * Создать капчу в модальном окне.
		 * @param {any} resolve Результат вызова.
		 * @param {any} reject Ошибка вызова.
		 * @param {Function} resendCodeFunc Функция переотправки СМС.
		 */
		initModalCapcha(resolve, reject, resendCodeFunc) {
			let cachedSmartCaptchaKey = localStorage.getItem("SblSmartCaptchaSiteKey");
			const container = document.getElementById('yandexSmartCaptchaModal');
			container.style.display = "block";

			const resendTextInformation = document.getElementById('resendTextInformation');
			resendTextInformation.style.display = "none";

			if (window.smartCaptcha && cachedSmartCaptchaKey) {
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: cachedSmartCaptchaKey,
					hl: 'ru',
				});

				const unsubscribe = window.smartCaptcha.subscribe(
					widgetId, 
					'success', 
					(token) => {
						this.onSmartCaptchaSuccessCallback(token, resendCodeFunc);
					}
				);

				this.captchaWidgetId = widgetId;
				return;
			}

			SblUserManagementService.getSmartCaptchaSiteKey()
				.then( (response) => {
				var smartCaptchaSiteKey = response && response.data;

				if (!smartCaptchaSiteKey) {
					window.console.error(Messages.SblYandexCaptchaNoSiteKey);
					reject(new Error(Messages.SblYandexCaptchaNoSiteKey));
					return;
				}
				
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: smartCaptchaSiteKey,
					hl: 'ru'
				});

				const unsubscribe = window.smartCaptcha.subscribe(
					widgetId, 
					'success', 
					(token) => {
						this.onSmartCaptchaSuccessCallback(token, resendCodeFunc);
					}
				);

				this.captchaWidgetId = widgetId;
				localStorage.setItem("SblSmartCaptchaSiteKey", smartCaptchaSiteKey);

				resolve(response);
			})
			.catch(function(error) {
				reject(new Error(Messages.SblYandexCaptchaNoSiteKey));
				console.log(error);
			});
		}
	},
	mounted() {
		this.startTimer();
		this.$nextTick(() => {
			this.data = this.modalData;

			if (!this.isSeparateMode) {
				document.getElementById('confirmationCodeId')?.focus();
			}
		});
	},
}
</script>

<style lang="scss" scoped>
@import "@/styles/elements.scss";

@media only screen and (max-width: 478px) {
	.sbl-modal {
		min-width: 293px;
	}

	:deep(.sbl-modal-header-h1) {
		width: 50%;
		font-size: 19px;
	}

	.confirm-sms {
		&__common-text {
			width: 50%;
			font-size: 14px;
		}

		&__buttons-container {
			font-size: 14px;
		}
	}
}

@media only screen and (min-width: 478px) {
	.sbl-modal {
		min-width: 478px;
	}

	:deep(.sbl-modal-header-h1) {
		font-size: 24px;
	}

	.confirm-sms {
		&__common-text {
			font-size: 16px;
		}

		& button,
		& span,
		& p,
		& a {
			font-size: 16px;
		}
	}
}

.sbl-modal {
	max-width: 600px;
	padding: 40px;
	background-color: #F5F8FB;
	border: 1px solid #D8E1E8;
	border-radius: 20px;
}

:deep(.modal-close-button) {
	width: 20px;
	height: 20px;
	right: 20px;
	top: 20px;
}

:deep(.sbl-modal-header-h1) {
	color: #104752;
    text-align: center;
    font-family: "SBSansDisplay-Regular";
    font-weight: 400;
}

.sbl-form-control-error {
	margin: auto;
}

.sbl-form-control-container {
	width: 90%;
	align-self: center;
}

.sbl-common-text {
	margin: auto;
	text-align: center;
}

.sbl-control-label {
	text-align: center;
}

.sbl-form-group {
	width: 100%;
	margin-top: 10px;
}

.single-input {
	height: 61px;
	padding: 15px 20px 10px 20px;
	margin-top: 5px;
	margin-bottom: 10px;
	border: 1px solid $input;
	border-radius: 8px;
	min-width: 10px;
	width: 75%;
	letter-spacing: 19.2px;
	text-align: center;
	color: #212529;
	text-align: center;
	font-family: "SBSansUI-Regular";
	font-size: 32px;
	font-weight: 400;
	align-self: center;
}

.confirm-sms {
	display: flex;
	align-items: center;
	flex-direction: column;

	&__common-text {
		color: #104752;
		text-align: center;
		font-family: "SBSansDisplay-Regular";
		font-weight: 400;
		display: block;
		margin-top: 10px;
	}
	&__confirm-code {
		font-size: 25px !important;
		font-family: Arial regular;
	}

	&__buttons-container {
		flex-direction: column;
		width: 84% !important;
		color: #104752;

		& button,
		& span,
		& p,
		& a {
			width: 100% !important;
			margin-top: 20px;
			text-align: center;
			font-family: "SBSansDisplay-Regular";
			font-weight: 400;
			line-height: 120%;
		}

		& button:disabled {
			color: #212529;
		}
	}
}

.sbl-form-control-error {
	font-size: 14px !important;
	text-align: center;
}

.smart-captcha-model {
	width: 100%;
}

</style>