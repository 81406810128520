import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VModal from 'vue-js-modal'
import Spinner from '@/components/Spinner.vue'
import vSelect from "vue-select";
import "vue-select/src/scss/vue-select.scss";

// Отключение предупреждения о работе в режиме разработки при запуске Vue.
Vue.config.productionTip = false

// Модалки
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true
});

// Глобально регаем компонент спиннера - он повсюду используется
Vue.component('Spinner', Spinner);
Vue.component('v-select', vSelect);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
