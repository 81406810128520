<template>
	<div class="sign-in-token">
		<Spinner v-if="isLoading"></Spinner>
	</div>
</template>

<script>
import ConfirmResultModal from "../components/ConfirmRequestResultModal.vue";
import { SblTokenAuthService } from "../services/api/SblTokenAuthService.js";
import { SblSberBusinessIdService } from "../services/api/SblSberBusinessIdService.js";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { Messages } from "../enums/Messages.js";
import { DefaultCaptions } from "../enums/DefaultCaptions";
import AuthService from "../services/AuthenticationService";
import ModalService from "@/services/ModalService.js";
import ErrorModal from "../components/ErrorModal";
import InfoModal from "../components/InfoModal";

/**
 * Страница авторизации по токену.
 */
export default {
	components: {
		ConfirmResultModal
	},
	data() {
		return {
			/**
			 * Идет ли какая-либо загрузка.
			 */
			isLoading: true
		};
	},
	methods: {
		/**
		 * Вход в систему по токену.
		 * @param {any} userInfo Информация по пользователю.
		 */
		loginWithToken(userInfo) {
			let scope = this;

			return new Promise((resolve, reject) => {
				return SblTokenAuthService.loginWithToken(userInfo.token)
				.then(function(response) {
					let result = response && response.data;

					if (result && result.Success === true) {
						if (userInfo.isUnknownApplicationPageRedirect) {
							AuthService.openUnknownApplicationPage();
						} else if (userInfo.isApplicationSectionRedirect) {
							AuthService.openApplicationPage();
						} else if (userInfo.contractId) {
							let regexp = new RegExp("^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$", "i");

							if (regexp.test(userInfo.contractId)) {
								AuthService.openContractPage(userInfo.contractId);
							} else {
								AuthService.openHomePage();
							}
						} else if (userInfo.isContractSectionRedirect) {
							AuthService.openContractSection();
						} else if (userInfo.isSignDocumentPageRedirect) {
							AuthService.openSignDocumentsPage();
						} else {
							AuthService.openHomePage();
						}
					} else {
						scope.showErrorModal();
						reject(
							new scope.TokenProcessingError(Messages.SblSberBusinessAuthorizationFailed)
						);
					}
				})
				.catch(function(error) {
					scope.showErrorModal();
					reject(
						new scope.TokenProcessingError(Messages.SblSberBusinessAuthorizationFailed)
					);
				});
			});
		},

		/**
		 * Обработка запроса.
		 * @param {string} requestParameters Параметры запроса.
		 */
		processRequest(requestParameters) {
			let scope = this;

			SblSberBusinessIdService.catchRequest(requestParameters)
			.then(function(response) {
				let responseURL = response && response.request && response.request.responseURL;

				if (responseURL) {
					window.location.replace(responseURL);
				}
			})
			.catch(function(error) {
				scope.showErrorModal();
			});
		},

		/**
		 * Получение информации о клиенте в СББОЛ.
		 * @param {string} authCode Авторизационный код.
		 */
		sberBusinessAuth(authCode) {
			let scope = this;

			SblUserManagementService.sberBusinessAuthProcess(authCode)
			.then(function(response) {
				let result = response?.data?.SberBusinessAuthProcessResult;

				if (!result || (result.isError && !result.customErrorText)) {
					scope.showErrorModal();
				} else if (result.isError && result.customErrorText) {
					scope.showErrorModal(result.customErrorText, DefaultCaptions.NextButtonCaption, scope.redirectToSberBusinessPortal);
				} else if (result.isUsedCode) {
					scope.$router.replace({ name: "SignIn", query: null });
				} else if (result.nonClient) {
					scope.showErrorModal(Messages.SblSberBusinessIdNonClientMessage);
				} else if (result.isRegistration) {
					scope.showSuccessInfoModal(Messages.SblIsRegistrationStartedMessage);
				} else {
					scope.loginWithToken(result);
				}
			})
			.catch(function(error) {
				scope.showErrorModal();
			});
		},

		/**
		 * Ошибка обработки токена.
		 */
		TokenProcessingError: function(message) {
			this.message = message;
		},

		/**
		 * Отобразить сообщение об ошибке.
		 */
		showErrorModal(message, buttonCaption, clickHandler) {
			let errorMessage = message || Messages.SblCommonError;
			let successButtonCaption = buttonCaption || DefaultCaptions.OkButtonCaption;
			clickHandler = clickHandler || this.openSignInPage;
			
			ModalService.show({
				component: ErrorModal,
				props: {
					title: Messages.SblCommonErrorTitle,
					message: errorMessage,
					showRegisterButton: false,
					okClickHandler: clickHandler.bind(this),
					successButtonCaption: successButtonCaption
				},
				settings: {
					height: "auto",
					width: "545px"
				}
			});
		},

		/**
		 * Переадресация на страницу портала сервиса СберБизнес.
		 */
		redirectToSberBusinessPortal() {
			let scope = this;

			SblSberBusinessIdService.getSblSberBusinessLinkOnPortal()
			.then(function(response) {
				window.console.log(response);
				let result = response?.data;

				if (result) {
					window.location.replace(decodeURI(result));
				} else {
					scope.openSignInPage();
				}
			})
			.catch(function(error) {
				scope.showErrorModal();
			});
		},

		/**
		 * Открыть главную страницу авторизации.
		 */
		openSignInPage() {
			this.$router.replace({ name: "SignIn", query: null });
		},

		/**
		 * Отобразить успешное сообщение.
		 */
		showSuccessInfoModal(message) {
			ModalService.show({
				component: InfoModal,
				props: {
					message: message,
					okClickHandler: this.openSignInPage.bind(this)
				},
				settings: {
					height: "auto",
					width: "545px"
				}
			});
		}
  	},
  	computed: {},
	mounted() {
		let router = this.$router.history.current,
			query = router && router.query,
			token = query && query.token,
			error = query && query.error;

		if (error) {
			this.showErrorModal();
			return;
		}
		if (token) {
			this.sberBusinessAuth(token);
			return;
		}

		let allParams = window.location.search;
		if (allParams) {
			this.processRequest(allParams);
		}
	}
};
</script>

<style lang="scss" scoped></style>