import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { ConfirmPepResultCodes } from '../enums/ConfirmPepResultCodes';
import { Messages } from "../enums/Messages.js";

/**
 * Обработчики действий модального окна с подтверждением СМС кода.
 */
export default {
	/**
	 * Метод подтверждения телефона (подписание).
	 */
	confirmPhoneSmsCode() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			SblUserManagementService.getSigningTokenInfo(this.data.tokenId, this.confirmationCode)
			.then((response) => {
				let data = response?.data?.GetTokenInfoResult;

				if (data.confirmCodeResult == ConfirmPepResultCodes.Incorrect) {
					reject(new Error(Messages.SblSmsModalWrongCodeError));
				} else if (data.resultCode == ConfirmPepResultCodes.TryCountOver) {
					reject(new Error(Messages.SblSmsModalLimitCodeError));
				} else {
					resolve(data);
				}
			})
			.catch(error => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			});
		});
	},

	/**
	 * Метод переотправки смс кода для подтверждения телефона (подписание).
	 */
	resendPhoneSmsCode() {
		return new Promise((resolve, reject) => {
			SblUserManagementService.validatePhoneNumber(this.data.tokenId, this.data.phoneNumber)
			.then((response) => {
				let data = response?.data?.ValidatePhoneNumberResult;

				if (data?.success) {
					resolve(data);
				} else {
					reject(new Error(Messages.SblSmsModalCommonError));
				}
				
			})
			.catch((error) => {
				reject(new Error(Messages.SblSmsModalCommonError));
			})
		});
	},

	/**
	 * Метод подтверждения смс кода (подписание).
	 */
	confirmSigningSmsCode() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			SblUserManagementService.confirmPepCode(this.data.codeId, this.confirmationCode)
			.then(response => {
				let data = response?.data?.ConfirmPepCodeResult;
				
				if (data.success) {
					resolve(data);
				} else if (data.resultCode == ConfirmPepResultCodes.Incorrect) {
					reject(new Error(Messages.SblSmsModalWrongCodeError));
				} else if (data.resultCode == ConfirmPepResultCodes.ValidityPeriodOver) {
					reject(new Error(Messages.SblSmsModalExpireCodeError));
				} else if (data.resultCode == ConfirmPepResultCodes.TryCountOver) {
					reject(new Error(Messages.SblSmsModalLimitCodeError));
				} else {
					reject(new Error(Messages.SblSmsModalCommonError));
				}
			})
			.catch(error => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			});
		});
	},

	/**
	 * Метод переотправки смс кода (подписание).
	 */
	resendSigningSmsCode() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			SblUserManagementService.createPepCode(this.data.tokenId)
			.then((response) => {
				let data = response?.data?.CreatePepCodeResult;

				if (data) {
					if (data.redirectToErrorPage) {
						this.$router.replace({ name: "SigningFail", query: null });
					} else if (data.success) {
						resolve(data);
					} else {
						reject(new Error(Messages.SblSmsModalCommonError));
					}
				} else {
					reject(new Error(Messages.SblSmsModalCommonError));
				}
			})
			.catch((error) => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			});
		});
	},

	/**
	 * Метод подтверждения смс кода (авторизация).
	 */
	confirmLoginSmsCode() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			SblUserManagementService.loginRequest(this.data)
			.then(response => {
				console.log(response);
				if (!response || !response.data) {
					reject(new Error(Messages.SblSmsModalCommonError));
				}

				let result = response.data;
				// Если все успешно, закрываем модалку и отдаем ответ наружу
				if (result.Success === true && !result.NeedSmsAuth) {
					resolve(result.ResponseText);
				}
				if (result.ErrorMessage) {
					reject(new Error(result.ErrorMessage));
				} else {
					reject(new Error(Messages.SblSmsModalWrongCodeError));
				}
			})
			.catch(error => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			})
		});
	},

	/**
	 * Метод переотправки смс кода (подписание).
	 */
	resendLoginSmsCode() {
		return new Promise((resolve, reject) => {
			this.initModalCapcha(resolve, reject, this.recoverPassword);
		}); 
	},

	/**
	 * Метод переотправки смс кода (подтверждение при смене пароля).
	 */
	resendPasswordConfirmSmsCode() {
		return new Promise((resolve, reject) => {
			this.initModalCapcha(resolve, reject, this.changePasswordWithSMS);
		});
	},

	/**
	 * Метод подтверждения смс кода (восстановление пароля, ИНН режим).
	 */
	confirmRecoveryPasswordSmsCodeWithInn() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			let data = {
				login: this.data.login,
				confirmationCode: this.confirmationCode,
				inn: this.data.inn
			};

			SblUserManagementService.confirmPasswordRecoveryWithInn(data)
			.then(response => {
				if (!response || !response.data) {
					reject(new Error(Messages.SblSmsModalCommonError));
				}

				// Если все успешно, закрываем модалку и отдаем ответ наружу
				if (response.data.IsSuccess) {
					resolve(response.data);
				}

				if (response.data.ErrorCode == "wrongConfirmationCode") {
					reject(new Error(Messages.SblSmsModalWrongCodeError));
				} else if (response.data.ErrorCode == "confirmationCodeTimeout") {
					reject(new Error(Messages.SblSmsModalExpireCodeError));
				} else if (response.data.ErrorCode == "confirmationCodeAttemptsError") {
					reject(new Error(Messages.SblSmsModalLimitCodeError));
				} else {
					reject(new Error(Messages.SblSmsModalCommonError));
				}
			})
			.catch(error => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			})
		});
	},

	/**
	 * Метод подтверждения смс кода (восстановление пароля).
	 */
	confirmRecoveryPasswordSmsCode() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			let data = {
				login: this.data.login,
				confirmationCode: this.confirmationCode
			};

			SblUserManagementService.confirmPasswordRecovery(data)
			.then(response => {
				if (!response || !response.data) {
					reject(new Error(Messages.SblSmsModalCommonError));
				}

				// Если все успешно, закрываем модалку и отдаем ответ наружу
				if (response.data.IsSuccess) {
					resolve(response.data);
				}

				if (response.data.ErrorCode == "wrongConfirmationCode") {
					reject(new Error(Messages.SblSmsModalWrongCodeError));
				} else if (response.data.ErrorCode == "confirmationCodeTimeout") {
					reject(new Error(Messages.SblSmsModalExpireCodeError));
				} else if (response.data.ErrorCode == "confirmationCodeAttemptsError") {
					reject(new Error(Messages.SblSmsModalLimitCodeError));
				} else {
					reject(new Error(Messages.SblSmsModalCommonError));
				}
			})
			.catch(error => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			})
		});
	},

	/**
	 * Метод подтверждения смс кода (ввод нового пароля).
	 */
	confirmChangePasswordSmsCode() {
		return new Promise((resolve, reject) => {
			//this определяется внутри модального окна с СМС кодом.
			let data = {
				login: this.data.login,
				confirmationCode: this.confirmationCode,
				password: this.data.password,
				inn: this.data.inn
			};

			SblUserManagementService.confirmPasswordChange(data)
			.then(response => {
				if (!response || !response.data) {
					reject(new Error(Messages.SblSmsModalCommonError));
				}

				// Если все успешно, закрываем модалку и отдаем ответ наружу
				if (response.data.success) {
					sessionStorage.setItem("RealLogin", response.data.login);
					resolve(response.data);
				}

				// У пользака есть 3 поптыки на ввод кода, если ввел неправильно - предлагаем ввести еще раз
				if (response.data.errorMessage) {
					reject(new Error(response.data.errorMessage));
				} else {
					reject(new Error(Messages.SblSmsModalCommonError));
				}
			})
			.catch(error => {
				window.console.error(error);
				reject(new Error(Messages.SblSmsModalCommonError));
			})
		});
	}
}
