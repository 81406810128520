/**
 * Тип входа в систему.
 */
export const EntranceTypes = {
  /**
   * Вход.
   */
  SIGN_IN: "SignIn",
  /**
   * Регистрация.
   */
  SIGN_UP: "SignUp"
}