export default {
	// фабрика модалок
	_modalFactory: null,

	/**
	 * Инициализировать фабрику модалок компонентом библиотеки.
	 * @param {*} modalFactory Компонент библиотеки.
	 */
	initialize(modalFactory) {
		this._modalFactory = modalFactory;
	},

	/**
	 * Показать модалку.
	 * @param {*} param0
	 */
	show({ component, props, settings }) {

		var defaultSetting = {
			adaptive: true,
			draggable: false,
			scrollable: false,
			height: "auto",
			clickToClose: false,
			name: '',
			position: {
				element: null,
				orientation: null
			},
			classes: ["rounded-corners"]
		};
		var showSettings = Object.assign(defaultSetting, settings);

		showSettings.name = showSettings.name || component.name || `Modal_${Math.random().toString(36).substring(7)}`;

		// промис с результатом работы модалки
		let promise = new Promise((resolve, reject) => {
			this._modalFactory.show(
				component,
				{
					...props,
					promise: {
						resolve,
						reject
					}
				},
				showSettings,
				{}
			);
		});
		return promise;
	}
}
