/**
 * Типы ошибок сертификата.
 */
export const CadesErrorCodes = {
    /**
     * Не найден сертификат или приватный ключ
     */
    CERTIFICATE_OR_PRIVATE_KEY_NOT_FOUND: '0x8009200B',
    /**
     * Цепочка сертификатов не может быть построена
     */
    CERTIFICATE_CHAIN_COULD_NOT_BE_BUILT: '0x800B010A',

    /**
    * Истек/не наступил срок действия требуемого сертификата
    */
    CERTIFICATE_NOT_IN_VALIDITY_PERIOD: '0x800B0101'
  }