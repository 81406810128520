/**
 * Коды ошибок аутентификации.
 */
export const AuthenticationErrorCodes = {
  /**
   * Временный пароль. Требуется восстановление пароля.
   */
  TempPassword: 8,

  /**
   * Необходимость изменения пароля.
   */
  ChangePassword: 5,

  /**
   * Неверные логин или пароль.
   */
  InvalidCredentials: 1,

  /**
   * Неверные логин или пароль при двухфакторной авторизации.
   */
   SmsAuthenticationInvalidCredentials: 9,

  /**
   * Пользователь заблокирован после неудачных попыток входа.
   */
     UserBlockedForTime: 101,
}