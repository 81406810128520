<template>
  <div class="certificate-login">
    <div
      align-self="left"
      class="certificate-login__signature-text"
    >При необходимости подключите носитель электронной подписи и выберите сертификат</div>
    <div class="certificate-login__signature">
      <img src="../assets/images/signature.png" alt="Signature" />
    </div>
    <div class="certificate-login__buttons-container">
      <select
        class="sbl-form-control sbl-select"
        id="certificateSelection"
        v-model="choosedCertificate"
      >
        <option
          v-for="(cert, index) in certificateOptions"
          :value="cert"
          :key="index"
        >{{ cert.text }}</option>
      </select>
    </div>
    <div class="certificate-login__remember-password">
      <label>
        <span>
          Время ожидания загрузки сертификата может составлять до 30 секунд
          <a
            href="#"
            id="userManual"
            class="sbl-link"
            @click="onUserManualClick"
          >
            <br />Инструкция по работе с электронной подписью
          </a>
        </span>
      </label>
    </div>
    <div class="certificate-login__buttons-container">
      <button
        class="sbl-button sbl-green-button sbl-medium-button certificate-login__button"
        :disabled="!choosedCertificate || disabledSubmitButton"
        @click="readyButtonClick()"
      >Готово</button>
    </div>
  </div>
</template>

<script>
import { SblCertificateAuthService } from "../services/api/SblCertificateAuthService.js";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { CertificateErrorTypes } from "../enums/CertificateErrorTypes.js";
import { EntranceTypes } from "../enums/EntranceTypes.js";
import ErrorModal from "../components/ErrorModal.vue";
import MessageBox from "@/services/MessageBox";
import AuthService from "../services/AuthenticationService";
import CertificatesLoader from "@/services/CertificatesLoader.js";
import cryptoPro from "crypto-pro";
import { CadesPlugin } from "@/services/api/CadesPluginApi.js";
import { Base64Helper } from "../helpers/base64Helper.js";
import { CadesErrorCodes } from "../enums/CadesErrorCodes.js";
import { Messages } from "../enums/Messages.js";
import { ModalService } from "../services/ModalService.js";

export default {
  name: "LoginByCertificate",
  props: {
    /**
     * Тип авторизации.
     */
    authType: {
      type: String,
      required: true
    }
  },
  data: function() {
    return {
      /**
       * Выбранный сертификат.
       */
      choosedCertificate: undefined,

      /**
       * Доступность кнопки "Продолжить".
       */
      disabledSubmitButton: false,

      /**
       * Пункты выпадающего меню выборасертификатов.
       */
      certificateOptions: [],

      /**
       * Все загруженные сертификаты.
       */
      allCertificates: new Array()
    };
  },
  components: {
    ErrorModal
  },
  methods: {
    /**
     * Наполнить список сертификатов.
     */
    fillCertificateList() {
      this.choosedCertificate = null;
      var scope = this;
      CertificatesLoader.getCertificateList(
        this.allCertificates,
        this.certificateOptions
      );
    },

    /**
     * Обработчик события нажатия на кнопку "Готово".
     */
    readyButtonClick() {
      if (!this.choosedCertificate) {
        MessageBox.error(
          "Для продолжения работы необходимо выбрать сертификат"
        );
        return;
      }
      switch (this.authType) {
        case EntranceTypes.SIGN_IN:
          this.tryLoginByCertificate(this.choosedCertificate);
          break;
        case EntranceTypes.SIGN_UP:
          this.tryRegisterWithCertificate(this.choosedCertificate);
          break;
        default:
          console.error("Некорректный тип авторизации!");
      }
    },

    /**
     * Регистрация по сертификату.
     * @param {any} certificateOption Выбранный элемент выпалающего списка сертификатов.
     * @private
     */
    tryRegisterWithCertificate(certificateOption) {
      if (!certificateOption) {
        console.error("Argument exception: certificateOption");
        return;
      }
      var selectedCertID = certificateOption.value;
      var cert = this.allCertificates[selectedCertID];
      var finalCertificateInfo = {};
      this.disabledSubmitButton = true;
      var scope = this;
      this.setCertificateBaseAttributes(cert)
        .then(function(certificateInfo) {
          return scope.setCertificateContentInBase64(cert, certificateInfo);
        })
        .then(scope.fillCertificateDataFromString)
        .then(function(certificateInfo) {
          return scope.generateCertificateSign(cert, certificateInfo);
        })
        .then(function(certificateInfo) {
          finalCertificateInfo = certificateInfo;
          return scope.encryptTokenByPrivateKey(
            cert,
            certificateInfo.SequenceForSign
          );
        })
        .then(function(encryptedToken) {
          finalCertificateInfo.CertificateSign = encryptedToken;
          return finalCertificateInfo;
        })

        .then(scope.verifyCertificateContent)
        .then(scope.getAdditionalInfoByDaData)
        .then(scope.notifyAboutNonCriticalCertificateErrorsIfExist)

        .then(scope.openRegistrationByCertificatePage)
        .catch(error => {
          this.disabledSubmitButton = false;
          if (error instanceof scope.CertificateProcessingError) {
            MessageBox.error(error.message);
            console.log(error.message);
          } else if (typeof error === "string") {
            if (error.includes(CadesErrorCodes.CERTIFICATE_OR_PRIVATE_KEY_NOT_FOUND)) {
              MessageBox.error(Messages.SblPrivateKeyNotFound);
            } else if (error.includes(CadesErrorCodes.CERTIFICATE_CHAIN_COULD_NOT_BE_BUILT)) {
              MessageBox.error(Messages.SblCertificateChainCouldNotBeBuilt);
            } else if (error.includes(CadesErrorCodes.CERTIFICATE_NOT_IN_VALIDITY_PERIOD)) {
              MessageBox.error(Messages.SblCertificateNotInValidityPeriod);
            } else {
              console.log(error.message);
            }
          } else {
            console.log(error.message);
          }
        });
    },

    notifyAboutNonCriticalCertificateErrorsIfExist(certificateInfo) {
      var scope = this;
      return new Promise((resolve, reject) => {
        var certificateData = certificateInfo.validationResult || {};
        var resolveFunction = () => {
          resolve(certificateInfo);
        };
        var rejectFunction = () => {
          reject(certificateInfo);
        };

        // Получить ошибку, что пользователь не является ген.директором, если она есть
        var genDirError = (certificateInfo.additionalInfoByDaDataResult || {})
          .Error;

        if (certificateData.errors) {
          reject(
            new scope.CertificateProcessingError(
              CertificateErrorTypes.NOT_TRUSTED,
              errors
            )
          );
        } else if (genDirError) {
          reject(
            new scope.CertificateProcessingError(
              CertificateErrorTypes.NOT_GENERAL_DIRECTOR,
              genDirError
            )
          );
        } else {
          resolve(certificateInfo);
        }
      });
    },

    /**
     * Авторизация по сертификату.
     * @param {any} certificateOption Выбранный элемент выпалающего списка сертификатов.
     * @private
     */
    tryLoginByCertificate(certificateOption) {
      if (!certificateOption) {
        console.error("Argument exception: certificateOption");
        return;
      }
      var selectedCertID = certificateOption.value;
      var cert = this.allCertificates[selectedCertID];
      var finalCertificateInfo = {};
      var scope = this;
      this.disabledSubmitButton = true;
      this.setCertificateBaseAttributes(cert)
        .then(function(certificateInfo) {
          return scope.setCertificateContentInBase64(cert, certificateInfo);
        })
        .then(scope.fillCertificateDataFromString)
        .then(function(certificateInfo) {
          return scope.generateCertificateSign(cert, certificateInfo);
        })
        .then(function(certificateInfo) {
          finalCertificateInfo = certificateInfo;
          return scope.encryptTokenByPrivateKey(
            cert,
            certificateInfo.SequenceForSign
          );
        })
        .then(function(encryptedToken) {
          finalCertificateInfo.CertificateSign = encryptedToken;
          return finalCertificateInfo;
        })
        .then(scope.loginWithSelectedCertificate)
        .catch(error => {
          this.disabledSubmitButton = false;
          if (error instanceof scope.CertificateProcessingError) {
            MessageBox.error(error.message);
            console.log(error.message);
          } else {
            if (
              typeof error === "string" &&
              error.includes(
                CadesErrorCodes.CERTIFICATE_OR_PRIVATE_KEY_NOT_FOUND
              )
            ) {
              MessageBox.error(Messages.SblPrivateKeyNotFound);
            }
            if (
              typeof error === "string" &&
              error.includes(
                CadesErrorCodes.CERTIFICATE_CHAIN_COULD_NOT_BE_BUILT
              )
            ) {
              MessageBox.error(Messages.SblCertificateChainCouldNotBeBuilt);
            }
            console.error(error);
          }
        });
    },

    /**
     * Установка базовых параметров сертификата.
     */
    setCertificateBaseAttributes(cert) {
      var certificateInfo = {
        Data: {},
        base64Certificate: ""
      };

      certificateInfo.SubjectName = cert.subjectName;
      certificateInfo.Thumbprint = cert.thumbprint;
      return Promise.resolve(certificateInfo);
    },

    /**
     * Заполнение данных из строки сертификата.
     * @param {any} certificateInfo Информация по сертификату.
     * @param {any} certificateInfo.SubjectName Данные сертификата (место выдачи, назание и т.д.).
     * @private
     */
    fillCertificateDataFromString(certificateInfo) {
      return new Promise((resolve, reject) => {
        SblUserManagementService.parseCertificate(certificateInfo.base64Certificate)
          .then(function(response) {
            console.log(response);

            var paramName = SblUserManagementService.GetParseClientCertificateMethodName + "Result";
            if (!response.data || !response.data[paramName]) {
              throw "Пустой ответ от сервиса парсинга сертификата!";
            }
            certificateInfo.Data = response.data[paramName];
            
            resolve(certificateInfo);
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Получение значения свойства из строки данных.
     * @param {String} dataString Строка с данными сертификата.
     * @param {String[]} possibleEntries Возможные названия атрибута в строке с данными.
     * @returns {String} Значение атрибута.
     * @private
     */
    getPropertyValue(dataString, possibleEntries) {
      var regexTemplate =
        '(?:^| )#entry#=(.*?),|(?:^| )#entry#="(.*?)"|(?:^| )#entry#=(.*?), |(?:^| )#entry#=(.*?)$';

      for (var i = 0; i < possibleEntries.length; i++) {
        var entryValue = null;
        var entry = possibleEntries[i];
        var filledTemplate = regexTemplate.replace(/#entry#/g, entry);
        var cnReg = new RegExp(filledTemplate);
        var cnMatch = cnReg.exec(dataString);
        if (!cnMatch) {
          continue;
        }
        if (cnMatch.length > 1) {
          if (cnMatch[1]) {
            entryValue = cnMatch[1];
            break;
          } else if (cnMatch[2]) {
            entryValue = cnMatch[2];
            break;
          } else if (cnMatch[3]) {
            entryValue = cnMatch[3];
            break;
          } else if (cnMatch[4]) {
            entryValue = cnMatch[4];
            break;
          }
        }
      }
      return entryValue;
    },

    /**
     * Преобразование содержимого сертификата к Base64.
     * @param {any} Сертификат.
     * @param {any} Дополнительная информация по сертификату, в которую запишется результат.
     */
    setCertificateContentInBase64(cert, certificateInfo) {
      var scope = this;
      return new Promise((resolve, reject) => {
        return cert._cert.Export(0).then(
          function(result) {
            certificateInfo.base64Certificate =
              result && result.replace(/\r\n/g, "");
            resolve(certificateInfo);
          },
          function(error) {
            reject(error);
          }
        );
      });
    },

    /**
     * Подписать сертификат по приватному ключу.
     * @param {any} cert Сертификат.
     * @param {string} stringToEncrypt Строка для полписи.
     */
    encryptTokenByPrivateKey(cert, stringToEncrypt) {
      var scope = this;
      return scope.GetSignedCadesBESMessage(cert, stringToEncrypt, scope);
    },

    GetSignedCadesBESMessage(cert, data) {
      var scope = this;
      if (isNativeMessageSupported) {
        return scope.SignCadesBES_Async(cert, data);
      } else {
        return scope.SignCadesBES_NPAPI(cert, data);
      }
    },

    SignCadesBES_Async(cert, data) {
      var scope = this;
      return new Promise(function(resolve, reject) {
        cadesplugin.async_spawn(function*(arg) {
          var certificate = cert;

          var dataToSign = data;
          if (typeof data != "undefined") {
            dataToSign = Base64Helper.encode(data);
          } else {
            dataToSign = Base64Helper.encode(dataToSign);
          }

          var Signature;
          try {
            //FillCertInfo_Async(certificate);
            var errormes = "";
            try {
              var oSigner = yield cadesplugin.CreateObjectAsync(
                "CAdESCOM.CPSigner"
              );
            } catch (err) {
              errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
              throw errormes;
            }
            var oSigningTimeAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
            );

            yield oSigningTimeAttr.propset_Name(
              cadesplugin.CAPICOM_AUTHENTICATED_ATTRIBUTE_SIGNING_TIME
            );
            var oTimeNow = new Date();
            yield oSigningTimeAttr.propset_Value(oTimeNow);
            var attr = yield oSigner.AuthenticatedAttributes2;
            yield attr.Add(oSigningTimeAttr);
            var oDocumentNameAttr = yield cadesplugin.CreateObjectAsync(
              "CADESCOM.CPAttribute"
            );
            yield oDocumentNameAttr.propset_Name(
              cadesplugin.CADESCOM_AUTHENTICATED_ATTRIBUTE_DOCUMENT_NAME
            );
            yield oDocumentNameAttr.propset_Value("Document Name");
            yield attr.Add(oDocumentNameAttr);

            if (oSigner) {
              yield oSigner.propset_Certificate(certificate);
            } else {
              errormes = "Failed to create CAdESCOM.CPSigner";
              throw errormes;
            }

            var oSignedData = yield cadesplugin.CreateObjectAsync(
              "CAdESCOM.CadesSignedData"
            );
            if (dataToSign) {
              // Данные на подпись ввели
              yield oSigner.propset_Options(
                cadesplugin.CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
              );
              yield oSignedData.propset_ContentEncoding(
                cadesplugin.CADESCOM_BASE64_TO_BINARY
              ); //
              if (typeof setDisplayData != "undefined") {
                //Set display data flag flag for devices like Rutoken PinPad
                yield oSignedData.propset_DisplayData(1);
              }
              yield oSignedData.propset_Content(dataToSign);

              try {
                Signature = yield oSignedData.SignCades(
                  oSigner,
                  cadesplugin.CADESCOM_CADES_BES
                );
              } catch (err) {
                errormes =
                  "Не удалось создать подпись из-за ошибки: " +
                  cadesplugin.getLastError(err);
                throw errormes;
              }
            }
            return resolve(Signature);
          } catch (error) {
            console.log(error);
            reject(error);
          }
        }); //cadesplugin.async_spawn
      });
    },

    SignCadesBES_NPAPI(cert, data) {
      var scope = this;
      return new Promise(function(resolve, reject) {
        var certificate = cert;
        try {
          var signature = MakeCadesBesSign_NPAPI(data, certificate);
          return resolve(signature);
        } catch (error) {
          console.log(error);
          return reject(error);
        }
      });
    },

    MakeCadesBesSign_NPAPI(dataToSign, certObject) {
      var errormes = "";
      try {
        var oSigner = cadesplugin.CreateObject("CAdESCOM.CPSigner");
      } catch (err) {
        errormes = "Failed to create CAdESCOM.CPSigner: " + err.number;
        throw errormes;
      }

      if (oSigner) {
        oSigner.Certificate = certObject;
      } else {
        errormes = "Failed to create CAdESCOM.CPSigner";
        console.log(errormes);
        throw errormes;
      }

      try {
        var oSignedData = cadesplugin.CreateObject("CAdESCOM.CadesSignedData");
      } catch (err) {
        console.log("Failed to create CAdESCOM.CadesSignedData: " + err.number);
        return;
      }

      var CADES_BES = 1;
      var Signature;

      if (dataToSign) {
        // Данные на подпись ввели
        oSignedData.ContentEncoding = 1; //CADESCOM_BASE64_TO_BINARY
        if (typeof setDisplayData != "undefined") {
          //Set display data flag flag for devices like Rutoken PinPad
          oSignedData.DisplayData = 1;
        }

        oSignedData.Content = Base64Helper.encode(dataToSign);
        oSigner.Options = 1; //CAPICOM_CERTIFICATE_INCLUDE_WHOLE_CHAIN
        try {
          Signature = oSignedData.SignCades(oSigner, CADES_BES);
        } catch (err) {
          errormes =
            "Не удалось создать подпись из-за ошибки: " +
            cadesplugin.getLastError(err);
          console.log(cadesplugin.getLastError(err));
          throw errormes;
        }
      }
      return Signature;
    },

    /**
     * Генерация подписи сертификата.
     * @param {any} cert Сертификат.
     * @param {any} certificateInfo Информация о сертификате.
     * @returns {any} Информация о сертификате с подписью.
     * @private
     */
    generateCertificateSign(cert, certificateInfo) {
      return new Promise((resolve, reject) => {
        return SblUserManagementService.getSequenceForCertificateSign(
          certificateInfo.Thumbprint
        )
          .then(function(response) {
            var paramName =
              SblUserManagementService.GetSequenceForCertificateSignMethodName +
              "Result";
            if (response.data) {
              certificateInfo.SequenceForSign = response.data[paramName];
              resolve(certificateInfo);
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    },

    /**
     * Вход в систему по выбранному сертификату.
     * @param {any} certificateInfo Информация о сертификату.
     * @param {String} certificateInfo.Thumbprint "Отпечаток" сертификата.
     * @param {String} certificateInfo.Base64CertificateContent Содержимое сертификата в формате Base64.
     * @param {String} certificateInfo.CertificateSign Подпись сертификата.
     */
    loginWithSelectedCertificate(certificateInfo) {
      var dataToPass = {
        ThumbPrint: certificateInfo.Thumbprint,
        CertificateSign: certificateInfo.CertificateSign
      };
      var scope = this;
      return new Promise((resolve, reject) => {
        return SblCertificateAuthService.loginWithCertificate(dataToPass)
          .then(function(response) {
            var result = response && response.data;
            if (result && result.ResultCode === "Success") {
              // Сохранить отпечаток в сессии, на случай необходимости смены пароля
              sessionStorage.setItem(
                "certificateThumbprint",
                dataToPass.ThumbPrint
              );
              sessionStorage.setItem("fillFromCertificate", true);
              AuthService.openHomePage();
            } else if (result.ResultCode === "UserFriendlyError") {
              reject(
                new scope.CertificateProcessingError(
                  CertificateErrorTypes.EMPTY_FIELDS,
                  result.ResultMessage
                )
              );
            } else {
              reject(
                new scope.CertificateProcessingError(
                  CertificateErrorTypes.EMPTY_FIELDS,
                  Messages.SblCertificateVerificationFailed
                )
              );
            }
          })
          .catch(function(error) {
            reject(
              new scope.CertificateProcessingError(
                CertificateErrorTypes.EMPTY_FIELDS,
                Messages.SblServiceIsTemporarilyUnavailable
              )
            );
          });
      });
    },

    /**
     * Валидация сертификата.
     * @param {any} certificateInfo Информация о сертификату.
     * @private
     */
    verifyCertificateContent(certificateInfo) {
      var scope = this;
      return new Promise((resolve, reject) => {
        SblUserManagementService.doCertificateValidationRequest(
          JSON.stringify(certificateInfo.base64Certificate)
        )
          .then(function(response) {
            var result = response && response.data;
            var validationResult = scope.processCertificateValidationResult(
              JSON.parse(result)
            );
            if (validationResult.isCriticalError) {
              reject(
                new scope.CertificateProcessingError(
                  CertificateErrorTypes.INVALID_CONTENT,
                  validationResult.errors
                )
              );
              return;
            }
            certificateInfo.validationResult = validationResult;
            resolve(certificateInfo);
          })
          .catch(function(error) {
            reject(scope.getDefaultCertificateError());
          });
      });
    },

    /**
     * Обработка результата проверки сертификата.
     * @package {any} validationResult Результат проверки сертификата.
     * @private
     */
    processCertificateValidationResult(validationResult) {
      var isCriticalError = false;

      var errors = "";
      if (!validationResult || !validationResult.meta) {
        errors = "Не удалось проверить выбранный сертификат.";
        isCriticalError = true;
      } else if (!validationResult.data) {
        errors = "Не удалось проверить выбранный сертификат.";
        isCriticalError = true;
      } else {
        if (!validationResult.data.trusted) {
          errors += "Сертификат не является доверенным; ";
          isCriticalError = true;
        }
        if (new Date(validationResult.data.dateTo) < new Date()) {
          errors +=
            "Сертификат не действителен. Вы можете обратиться в любой удостоверяющий центр для выпуска нового; ";
          isCriticalError = true;
        }

        if (validationResult.meta.status === "error") {
          var desc = validationResult.meta.desc;
          if (desc) {
            for (var i = 0; i < desc.length; i++) {
              errors += desc[i] + "; ";
            }
          }
        }
        return { isCriticalError, errors };
      }
    },

    /**
     * Получение дополнительной информации по сертификату из DaData.
     * @param {any} certificateInfo Информация о сертификату.
     * @private
     */
    getAdditionalInfoByDaData(certificateInfo) {
      var data = certificateInfo.Data;
      var scope = this;

      var inn = data.INN;
      var lastName = data.SN;
      var firstAndMiddleName = data.G;
      var organizationName = data.O;
      if ((!inn || !lastName || !firstAndMiddleName) && !organizationName) {
        reject(
          new scope.CertificateProcessingError(
            CertificateErrorTypes.EMPTY_FIELDS,
            "В сертификате отсутствуют данные по ИНН и имени владельца или по названию организации"
          )
        );
        return;
      }
      var dataToPass = {
        inn: inn,
        organizationName: organizationName,
        certificateSurName: lastName,
        certificateFirstAndMiddleName: firstAndMiddleName
      };
      return new Promise((resolve, reject) => {
        SblUserManagementService.getAdditionalInfoByDaData(dataToPass)
          .then(function(response) {
            var result = response && response.data;
            certificateInfo.additionalInfoByDaDataResult =
              result.GetAdditionalInfoByDaDataResult;
            resolve(certificateInfo);
          })
          .catch(function(error) {
            reject(scope.getDefaultCertificateError());
          });
      });
    },

    /**
     * Открытие страницы регистрации по сертификату.
     * @param {any} certificateInfo Информация о сертификату.
     * @param {String} certificateInfo.Thumbprint "Отпечаток" сертификата.
     * @param {String} certificateInfo.base64Certificate Содержимое сертификата в формате Base64.
     * @param {String} certificateInfo.Data Данные сертификата в одной строке.
     * @private
     */
    openRegistrationByCertificatePage(certificateInfo) {
      if (certificateInfo.additionalInfoByDaDataResult) {
        sessionStorage.setItem(
          "additionalInfoByDaDataResult",
          JSON.stringify(certificateInfo.additionalInfoByDaDataResult)
        );
      }
      sessionStorage.setItem(
        "registrationData",
        JSON.stringify(certificateInfo.Data)
      );
      sessionStorage.setItem(
        "certificateSign",
        certificateInfo.CertificateSign
      );
      sessionStorage.setItem(
        "certificateContent",
        certificateInfo.base64Certificate
      );
      sessionStorage.setItem(
        "certificateThumbprint",
        certificateInfo.Thumbprint
      );
      sessionStorage.setItem("fillFromCertificate", true);
      this.$router.push({ name: "SignUpWithPersonalDataFromCertificate" });
    },

    /**
     * Получение ошибки по умолчанию.
     * @private
     */
    getDefaultCertificateError() {
      return new this.CertificateProcessingError(
        CertificateErrorTypes.UNDEFINED_EXCEPTION,
        "При проверке сертификата произошла ошибка, обратитесь к администратору системы"
      );
    },

    /**
     * Ошибка обработки сертификата.
     */
    CertificateProcessingError: function(errorType, message) {
      this.errorType = errorType;
      this.message = message;
    },

    /**
     * Обработчик события нажатия на ссылку "Инструкция по работе с электронной подписью".
     */
    onUserManualClick() {
      SblUserManagementService.getUserManual()
        .then(function(response) {
          console.log(response);
          if (response) {
            var data = response.data;
            var pdfWindow = window.open("");
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(data) +
                "'></iframe>"
            );
          } else {
            MessageBox.error("Произошла ошибка при загрузке инструкции");
          }
        })
        .catch(function(error) {
          console.error(error);
          MessageBox.error("Произошла ошибка при загрузке инструкции");
        });
    }
  },
  mounted() {
    this.fillCertificateList();
  }
};
</script>

<style lang="scss" scoped>

.certificate-login {
  &__signature {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0 0;

    &-text {
      align-self: center;
    }
  }

  &__remember-password {
    align-self: flex-end;
    margin-top: 10px;
    font-size: 14px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    margin-top: 20px;

    &-button {
      width: 100% !important;
      margin-top: 20px;
    }
  }

  &__button {
    width: 100% !important;
    margin-top: 20px;
  }

  &__signature-text {
    text-align: center;
  }

  &__buttons-container {
    margin-top: 20px;
  }
}
</style>
