/**
* Набор констант для заголовков.
*/
export const DefaultCaptions = {
	/**
	 * Заголовок кнопки "Подтвердить".
	 */
	OkButtonCaption: "ОК",

	/**
	 * Заголовок кнопки "Далее/Продолжить".
	 */
	NextButtonCaption: "Продолжить",

	/**
	 * Заголовок кнопки "Войти".
	 */
	EnterButtonCaption: "Войти"
};