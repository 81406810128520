import { CadesPlugin } from "@/services/api/CadesPluginApi.js";

export default {

  /**
   * Загрузка сертификатов.
   * @param {Object[]} allCertificates Массив всех сертификатов (куда загружать).
   * @param {Object[]} certificateOptions Массив всех элементов выпадающего списка сертификатов (куда загружать).
   */
  getCertificateList(allCertificates, certificateOptions) {

    if (isNativeMessageSupported) {
      this.getCertificatesAsync(allCertificates, certificateOptions);
    } else {
      this.getCertificates(allCertificates, certificateOptions);
    }
  },

  /**
   * Загрузка сертификатов.
   * @param {Object[]} allCertificates Массив всех сертификатов.
   * @param {Object[]} certificateOptions Массив всех элементов выпадающего списка сертификатов.
   */
  getCertificates(allCertificates, certificateOptions) {
    var MyStoreExists = true;
    var certificateCounter = 0;
    try {
      var oStore = cadesplugin.CreateObject("CAdESCOM.Store");
      oStore.Open();
    } catch (ex) {
      MyStoreExists = false;
    }

    var certCnt;
    if (MyStoreExists) {
      certCnt = oStore.Certificates.Count;

      for (var i = 1; i <= certCnt; i++) {
        var cert;
        try {
          cert = oStore.Certificates.Item(i);
        } catch (ex) {
          console.log("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
          return;
        }

        var certOption = {};
        certOption.text = this.getCertificateDisplayValue(
          cert.SubjectName,
          cert.ValidFromDate
        );
        certOption.value = certificateCounter;
        allCertificates.push(this.getCertificateObject(cert));
        certificateCounter++;
        certificateOptions.push(certOption);
      }

      oStore.Close();
    }

    //В версии плагина 2.0.13292+ есть возможность получить сертификаты из 
    //закрытых ключей и не установленных в хранилище
    try {
      oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
      certCnt = oStore.Certificates.Count;
      for (var i = 1; i <= certCnt; i++) {
        var cert = oStore.Certificates.Item(i);
        //Проверяем не добавляли ли мы такой сертификат уже?
        var found = false;
        for (var j = 0; j < allCertificates.length; j++) {
          if (allCertificates[j].Thumbprint === cert.Thumbprint) {
            found = true;
            break;
          }
        }
        if (found) {
          continue;
        }

        var certOption = {};
        certOption.text = this.getCertificateDisplayValue(
          cert.SubjectName,
          cert.ValidFromDate
        );
        certOption.value = certificateCounter;
        allCertificates.push(this.getCertificateObject(cert));
        certificateCounter++;
        certificateOptions.push(certOption);
      }
      oStore.Close();
    } catch (ex) { }
  },

  /**
   * Загрузка сертификатов (асинхронная).
   * @param {Object[]} allCertificates Массив всех сертификатов.
   * @param {Object[]} certificateOptions Массив всех элементов выпадающего списка сертификатов.
   */
  getCertificatesAsync(allCertificates, certificateOptions) {
    var scope = this;
    var certificateCounter = 0;
    cadesplugin.async_spawn(function* () {
      var MyStoreExists = true;
      try {
        var oStore = yield cadesplugin.CreateObjectAsync("CAdESCOM.Store");
        if (!oStore) {
          console.log("Create store failed");
          return;
        }

        yield oStore.Open();
      } catch (ex) {
        MyStoreExists = false;
      }

      var certCnt;
      var certs;
      if (MyStoreExists) {
        try {
          certs = yield oStore.Certificates;
          certCnt = yield certs.Count;
        } catch (ex) {
          console.log("Ошибка при получении Certificates или Count: " + cadesplugin.getLastError(ex));
          return;
        }
        for (var i = 1; i <= certCnt; i++) {
          var cert;
          try {
            cert = yield certs.Item(i);
          } catch (ex) {
            console.log("Ошибка при перечислении сертификатов: " + cadesplugin.getLastError(ex));
            return;
          }

          var certOption = {};
          certOption.text = scope.getCertificateDisplayValue(
            yield cert.SubjectName,
            yield cert.ValidFromDate
          );
          certOption.value = certificateCounter;
          allCertificates.push({
            objid: yield cert.objid,
            issuerName: yield cert.IssuerName,
            subjectName: yield cert.SubjectName,
            thumbprint: yield cert.Thumbprint,
            validFrom: yield cert.ValidFromDate,
            validTo: yield cert.ValidToDate,
            _cert: yield cert
          });
          certificateCounter++;
          certificateOptions.push(certOption);

        }

        yield oStore.Close();
      }

      //В версии плагина 2.0.13292+ есть возможность получить сертификаты из 
      //закрытых ключей и не установленных в хранилище
      try {
        yield oStore.Open(cadesplugin.CADESCOM_CONTAINER_STORE);
        certs = yield oStore.Certificates;
        certCnt = yield certs.Count;
        for (var i = 1; i <= certCnt; i++) {
          var cert = yield certs.Item(i);
          //Проверяем не добавляли ли мы такой сертификат уже?
          var found = false;
          for (var j = 0; j < allCertificates.length; j++) {
            if ((yield allCertificates[j].thumbprint) === (yield cert.Thumbprint)) {
              found = true;
              break;
            }
          }
          if (found) {
            continue;
          }

          var certOption = {};
          certOption.text = scope.getCertificateDisplayValue(
            yield cert.SubjectName,
            yield cert.ValidFromDate
          );
          certOption.value = certificateCounter;
          allCertificates.push({
            objid: yield cert.objid,
            issuerName: yield cert.IssuerName,
            subjectName: yield cert.SubjectName,
            thumbprint: yield cert.Thumbprint,
            validFrom: yield cert.ValidFromDate,
            validTo: yield cert.ValidToDate,
            _cert: yield cert
          });
          certificateCounter++;
          certificateOptions.push(certOption);
        }
        yield oStore.Close();

      } catch (ex) { }
    });
  },

  /**
   * Получить отображаемое значение для строки сертификата.
   * @param {String} certInfo Информация о сертификате.
   * @param {Date} certFromDate Дата выдачи сертификата.
   * @returns {String} Отображаемое значение сертификата (Название + Выдан).
   */
  getCertificateDisplayValue(certInfo, certFromDate) {
    return (
      this.extractCertificateName(certInfo, "CN=") +
      "; Выдан: " +
      this.getFormattedDateTime(new Date(certFromDate))
    );
  },

  /**
   * Получить имя сертификата из набора данных.
   * @returns {String} Отображаемое значение сертификата (Название + Выдан).
   * @private
   */
  extractCertificateName(from, what) {
    var certName = "";
    var begin = from.indexOf(what);
    if (begin >= 0) {
      var end = from.indexOf(", ", begin);
      certName =
        end < 0 ? from.substr(begin) : from.substr(begin, end - begin);
    }
    return certName;
  },

  /**
   * Добавление ведущего нуля к числу.
   * @param {int} digit Редактируемое число.
   */
  addZeroToNumber(digit) {
    return (digit < 10) ? "0" + digit : digit;
  },

  /**
   * Форматирование даты сертификата.
   * @param {date} date Форматируемая дата.
   */
  getFormattedDateTime(date) {
    return this.addZeroToNumber(date.getUTCDate()) + "." +
      this.addZeroToNumber(date.getUTCMonth() + 1) + "." +
      date.getFullYear() + " " +
      this.addZeroToNumber(date.getUTCHours()) + ":" +
      this.addZeroToNumber(date.getUTCMinutes()) + ":" +
      this.addZeroToNumber(date.getUTCSeconds());
  },

  /**
   * Получение преобразованного сертификата.
   * @param {Object} certificate Сертификат.
   */
  getCertificateObject(certificate) {
    return {
      issuerName: certificate.IssuerName,
      subjectName: certificate.SubjectName,
      thumbprint: certificate.Thumbprint,
      validFrom: certificate.ValidFromDate,
      validTo: certificate.ValidToDate,
      _cert: certificate
    }
  }

}