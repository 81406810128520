<template>
  <div class="registration-fail">
    <span class="sbl-h1 registration-fail__head-text">При подтверждении регистрации произошла ошибка.</span>
    <div class="sbl-common-text registration-fail__subhead-text">
        Обратитесь в Cлужбу поддержки для решения проблемы.
    </div>
    <RoundedButton
            caption="Перейти на главную"
            additionalClasses="sbl-green-button"
            @click="replaceToMainPage()"
    />
  </div>
</template>

<script>
import RoundedButton from "../components/RoundedButton";

export default {
    name: "registration-fail",
    components: {
    RoundedButton
    },
    methods: {

    /**
     * Переход на главную.
     */
    replaceToMainPage() {
        this.$router.replace({ name: "SignIn"});
    },
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.registration-fail {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;

    &__head-text {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 40px;
    }

    &__subhead-text {
    margin-top: 20px;
    margin-bottom: 20px;
    }
    
    & button,
    & a {
      width: 400px !important;
      margin-top: 20px;
      text-align: center;
    }
}
</style>