<template>
  <div class="registration-success">
    <span class="sbl-h1 registration-success__head-text">Регистрация подтверждена.</span>
    <div class="sbl-common-text registration-success__subhead-text" v-show="IsSmsFeatureEnabled">
      Для первого входа в систему вам нужно установить новый пароль.
    </div>
    <div class="sbl-common-text registration-success__subhead-text" v-show="IsSmsFeatureDisabled">
      Вам отправлено SMS с логином и паролем для первого входа в систему.
    </div>
    <div v-show="IsSmsFeatureEnabled">
      <RoundedButton
              caption="Установить пароль"
              additionalClasses="sbl-green-button"
              @click="replaceToPasswordPage()"
      />
    </div>
    <div v-show="IsSmsFeatureDisabled">
      <RoundedButton
              caption="Перейти на главную"
              additionalClasses="sbl-green-button"
              @click="replaceToMainPage()"
      />
    </div>
  </div>
</template>
<script>
import RoundedButton from "../components/RoundedButton";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";

export default {
    name: "registration-success",
    components: {
    RoundedButton
    },
    data: function() {
	    return {
        IsSmsFeatureEnabled: true,
        IsSmsFeatureDisabled: false
      };
    },
    mounted() {
      this.checkIsSmsFeatureEnabled();
    },
    methods: {

      /**
      * Переход на главную.
      */
      replaceToMainPage() {
          this.$router.replace({ name: "SignIn"});
      },

      /**
      * Переход на страницу смены пароля.
      */
      replaceToPasswordPage() {
          this.$router.replace({ name: "ChangePassword"});
      },

      /**
      * Получает с бэка значение системной настройки и устанавливает соответствующий флаг.
      */
      checkIsSmsFeatureEnabled(){
        SblUserManagementService.checkIsSmsFeatureEnabled()
          .then(function(response) {
            if (response) {
              this.IsSmsFeatureEnabled = response.data;
              this.IsSmsFeatureDisabled = !response.data;
            }
          }.bind(this))
      }
    },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.registration-success {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;

    &__head-text {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 40px;
    }

    &__subhead-text {
    margin-top: 20px;
    margin-bottom: 20px;
    }
    
    & button,
    & a {
      width: 400px !important;
      margin-top: 20px;
      text-align: center;
    }
}
</style>