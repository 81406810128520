<template>
  <BaseModal title="Выбор сертификата" @close="$emit('close')">
    <template v-slot:content>
      <LoginByCertificate :authType="signUpType" />
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import LoginByCertificate from "../components/LoginByCertificate";
import { EntranceTypes } from "../enums/EntranceTypes.js";

/**
 * Модальное окно для регистрации по сертификату.
 */
export default {
  name: "RegisterByCertificateModal",
  components: {
    BaseModal,
    LoginByCertificate
  },
  data() {
    return {
      /**
       * Тип входа в систему: Регистрация.
       */
      signUpType: EntranceTypes.SIGN_UP
    };
  }
};
</script>
