<template>
  <div class="confirmation-email-expired">
    <span class="sbl-h1 confirmation-email-expired__head-text">
      Регистрация не подтверждена, т.к. истек срок действия проверочного письма
    </span>
    <div class="confirmation-email-expired__buttons-container">
      <RoundedButton caption="Отправить повторное письмо"
                     additionalClasses="sbl-green-button"
                     @click="sendEmail()" />
      <RoundedButton caption="Перейти на главную"
                     additionalClasses="sbl-green-button"
                     @click="replaceToMainPage()" />
    </div>
  </div>
</template>

<script>
  import RoundedButton from "../components/RoundedButton";
  import { SblValidateRegistrationEmailServiceExt } from "../services/api/SblValidateRegistrationEmailServiceExt.js";
  import ModalService from "@/services/ModalService.js";
  import ErrorModal from "../components/ErrorModal";

  export default {
    name: "confirmation-email-expired",
    components: {
      RoundedButton
    },
    methods: {

      /**
       * Переход на главную.
       */
      replaceToMainPage() {
        this.$router.replace({ name: "SignIn" });
      },

      /**
       * Отправка повторного письма.
       */
      sendEmail() {
        var scope = this;
        var router = this.$router.history.current;
        var query = router && router.query;
        var token = query && query.token;
        if (token) {
          SblValidateRegistrationEmailServiceExt.resendEmail(token)
            .then(function (response) {
              var regData = response && response.data;
              var result = regData.InitValidateResult;
              if (result.ResultCode === 0) {
                scope.$router.replace({ name: "SignIn" });
                return;
              }
              scope.showErrorModal(result.Message);
            })
            .catch(function (error) {
              console.log(error.message);
            });
        }
      },

      /**
       * Отобразить сообщение об ошибке.
       */
      showErrorModal(message) {
        var clickHandler = () => {
          this.$router.replace({ name: "SignIn", query: null });
        };
        ModalService.show({
          component: ErrorModal,
          props: {
            message: message,
            showRegisterButton: false,
			okClickHandler: clickHandler.bind(this)
          },
          settings: {
            height: "auto",
            width: "545px"
          }
        });
      }
    },

  };
</script>

<style lang="scss" scoped>
  @import "@/styles/variables.scss";

  .confirmation-email-expired {
    display: flex;
    justify-content: center;
    flex: 1;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;

    &__head-text {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 40px;
    }

    &__subhead-text {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__buttons-container {
      display: flex;
      justify-content: space-between;
    }

    & button,
    & a {
      width: 400px !important;
      margin: 20px;
      text-align: center;
    }
  }
</style>
