<template>
	<BaseModal :showCloseButton="false">
		<template v-slot:content>
			<div class="sbl-phone-header">Подтвердите номер телефона</div>
			<div class="confirm-sms" v-if="!isLoading">
				<div class="sbl-phone-control" id="passwordRecoveryConfirmationForm">
					<div class="sbl-form-control-container">
						<input
							required
							autocomplete="off"
							class="sbl-form-control sbl-phone__phone-code"
							name="phone"
							type="text"
							v-mask="'+7(9##)###-##-##'"
							v-model="phone"
						/>
						<span class="floating-placeholder">Номер телефона *</span>
					</div>
					<span v-if="errorMessage" class="sbl-form-control-error">{{errorMessage}}</span>
				</div>
				<div class="sbl-phone__buttons-container">
					<RoundedButton
						caption="Подтвердить"
						additionalClasses="sbl-green-button sbl-medium-button"
						:disabled="!phone || phone.length != 16"
						@click="onConfirmButtonClicked()"
					/>
				</div>
			</div>
			<Spinner v-if="isLoading"></Spinner>
		</template>
	</BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { mask } from "vue-the-mask";

/**
 * Модальное окно ввода кода подтверждения.
 */
export default {
	name: "ConfirmPhoneModal",
	directives: {
		mask
	},
	props: {
		/**
		 * Токен подписания.
		 */
		tokenId: {
			type: String,
			required: true
		},
		/**
		 * Ответ от модалки.
		 */
		promise: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			/**
			 * Номер телефона.
			 */
			phone: null,
			/**
			 * Сообщение об ошибке.
			 */
			errorMessage: null,
			/**
			 * Идет ли какая-либо загрузка.
			 */
			isLoading: false
		};
	},
	components: {
		RoundedButton,
		BaseModal
	},
	methods: {
		/**
		 * Обработчик события закрытия модального окна.
		 */
		close() {
			this.$emit("close");
		},

		/**
		 * Обработчик события нажатия на кнопку "Подтвердить".
		 */
		onConfirmButtonClicked() {
			this.isLoading = true;

			SblUserManagementService.validatePhoneNumber(this.tokenId, this.phone)
			.then((response) => {
				let data = response?.data?.ValidatePhoneNumberResult;

				if (data) {
					data.phoneNumber = this.phone;
					this.promise.resolve(data);
				} else {
					this.promise.resolve({ success: false });
				}
				
			})
			.catch((error) => {
				this.promise.resolve({ success: false });
			})
			.finally(() => {
				this.isLoading = false
				return this.close();
			});
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/elements.scss";
.sbl-phone-control {
	margin: 0 auto;
	padding-left: 40px;
	padding-right: 40px;
}
.sbl-phone-header {
	color: #104752;
	font-size: 24px;
	text-align: center;
	padding: 40px 40px 20px 40px;
}
.sbl-phone {
	&__phone-code {
		color: #104752;
		font-size: 25px !important;
		font-family: Arial regular;
	}

	&__buttons-container {
		margin-top: 20px;
		margin-bottom: 40px;
		display: flex;
		justify-content: center;

		.sbl-button {
			width: 200px;
		}
	}
}
</style>