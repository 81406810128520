/**
 * Коды результата обработки кода подтверждения при ПЭП подписании.
 */
export const  ConfirmPepResultCodes = {
	/**
	 * Успех.
	 */
	Success: 0,

	/**
	 * Ошибка во время обработки запроса.
	 */
	Failure: 1,

	/**
	 * Не корректный код.
	 */
	Incorrect: 2,

	/**
	 * Время действия кода истекло.
	 */
	ValidityPeriodOver: 3,

	/**
	 * Превышено количество попыток ввода кода.
	 */
	TryCountOver: 4
}