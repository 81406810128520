import { AuthService } from "../services/api/AuthService.js";
import { SblRegistrationHelperService } from "../services/api/SblRegistrationHelperService.js";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import ModalService from "@/services/ModalService.js";
import LookupModal from "@/components/LookupModal";

/**
 * Вспомогательные функции для реализации авторизации в приложении bpm.
 */
export default {

	/**
	 * Попытка авторизации в приложении bpm.
	 * @param {any} authData Авторизационные данные, необходимые для входа в приложение.
	 * @param {Function} errorHandler Функция-обработчик ошибки.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	tryLogin(authData, errorHandler, storeValuesBeforeRedirect) {
		var storeValuesFunc = storeValuesBeforeRedirect
			|| this.setParametersToStorage(authData.UserName, authData.UserPassword);
		var scope = this;
		AuthService.login(authData)
		.then(function (response) {
			console.log(response);
			if (response && response.data && response.status === 200) {
				if (response.data.ResponseText) {
					let parsedData = JSON.parse(response.data.ResponseText);
					if (parsedData && parsedData.Code != null) {
						switch (parsedData.Code) {
							case 0:
								scope.openHomePage(storeValuesFunc);
								return;
							case 1:
								scope.handleUserDeactivationReason(authData, errorHandler, response.data.NeedCaptcha);
								return;
							default:
								errorHandler(response.data.ErrorMessage, false, parsedData);
						}
					}
				} else if (!response.data.Success && response.data.ErrorMessage, response.data.NeedCaptcha) {
					errorHandler(response.data.ErrorMessage, response.data.NeedCaptcha);
				} else if (response.data.Success) {
					scope.openChangePasswordPage(storeValuesFunc);
				}
			} else {
				errorHandler("Сервис временно недоступен. Обратитесь к вашему клиентскому менеджеру или по телефону 8-800-555-55-56", response.data.NeedCaptcha);
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	},
	
    /**
	 * Обработать ответ сервиса авторизации.
	 * @param {any} authData Авторизационные данные, необходимые для входа.
	 * @param {any} responseData Ответ сервиса авторизации.
	 * @param {Function} errorHandler Функция-обработчик ошибки.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
     * необходимых для работы при входе.
	 */
	handleAuthResponse: function(authData, responseData, errorHandler, storeValuesBeforeRedirect) { 
		var storeValuesFunc = storeValuesBeforeRedirect
			|| this.setParametersToStorage(authData.UserName, authData.UserPassword);
		if (responseData && responseData.Code != null) {
			switch (responseData.Code) {
				case 0:
					this.openHomePage(storeValuesFunc);
					return;
				case 1:
					this.handleUserDeactivationReason(authData, errorHandler);
					return;
				default:
					errorHandler(responseData.Message);
			}
		}
	},

    /**
	 * Обработать ответ сервиса авторизации при скрытом логине после смены пароля.
	 * @param {any} authData Авторизационные данные, необходимые для входа.
	 * @param {any} responseData Ответ сервиса авторизации.
	 * @param {any} isEdoEnabled Флаг включения новой страницы ЭДО.
	 * @param {Function} errorHandler Функция-обработчик ошибки.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
     * необходимых для работы при входе.
	 */
    handleAutoAuthResponse: function(authData, responseData, isEdoEnabled, errorHandler, storeValuesBeforeRedirect) { 
		let storeValuesFunc = storeValuesBeforeRedirect
			|| this.setParametersToStorage(authData.UserName, authData.UserPassword);
		if (responseData && responseData.Code != null) {
			switch (responseData.Code) {
				case 0:
					this.openProfilePage(isEdoEnabled, storeValuesFunc);
					return;
				case 1:
					this.handleUserDeactivationReason(authData, errorHandler);
					return;
				default:
					errorHandler(responseData.Message);
			}
		}
    },

	/**
	 * Очистка данных сессии.
	 */
	clearSessionStorage: function () {
		sessionStorage.removeItem("UserName");
		sessionStorage.removeItem("UserPassword");
	},

	/**
	 * В зависимости от причины деактивации выводит сообщение деактивированному пользователю.
	 */
	handleUserDeactivationReason(authData, errorHandler,  needCaptcha) {

		var login = JSON.stringify(authData.UserName);

		SblUserManagementService.getDeactivationReason(login)
		.then(function (response) {
			var result = JSON.parse(response.data);

			switch (result.ResultCode) {
				case 0:
					errorHandler("Неверный логин или пароль", needCaptcha);
					break;
				case 1:
					errorHandler(result.ResultMessage, needCaptcha)
					break;
				default:
					errorHandler("Неверный логин или пароль", needCaptcha)
					break;
			}
		})
		.catch(function (error) {
			console.log(error);
		});
	},

	/**
	 * Очистка данных кук.
	 */
	resetCookies: function () {
		SblRegistrationHelperService.resetCookies()
		.then(function (response) {
			console.log(response);
		})
		.catch(function (error) {
			console.log(error);
		});
	},

	/**
	 * Поместить в хранилище сессии данные по параметрам.
	 * @param {String} login Логин пользователя.
	 * @param {String} password Пароль пользователя.
	 */
	setParametersToStorage(login, password) {
		sessionStorage.setItem("UserName", login);
		sessionStorage.setItem("UserPassword", password);
	},

	/**
	 * Сохранение идентификатора сессии.
	 */
	sblSiteSessionSave() {
		var returnUrl = this.getReturnUrl();
		var sessionUid = this.getUrlParameter('SblSiteSessionUID', returnUrl);
		if (sessionUid) {
			sessionUid = sessionUid.toLowerCase();
			var tmpStr = localStorage.getItem('SblSiteSessionUID');
			var tmpArray = tmpStr ? (JSON.parse(tmpStr) || []) : [];
			if (tmpArray.findIndex(x => x === sessionUid) === -1) {
				tmpArray.push(sessionUid);
				localStorage.setItem('SblSiteSessionUID', JSON.stringify(tmpArray));
			}
		}
		return;
	},

	/**
	 * Открытие домашней страницы.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openHomePage(storeValuesBeforeRedirect) {
		let homePageUrl = "/0/Nui/ViewModule.aspx";
		this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
	},

	/**
	 * Открытие страницы заявок.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openApplicationPage(storeValuesBeforeRedirect) {
		let homePageUrl = "/0/Nui/ViewModule.aspx#CardModuleV2/SblApplicationPortalSectionPage";
		this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
	},

	/**
	 * Открытие страницы заявки на рассмотрении.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openUnknownApplicationPage(storeValuesBeforeRedirect) {
		let homePageUrl = "/0/Nui/ViewModule.aspx#CardModuleV2/SblApplicationConsiderationPage";
		this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
	},

	/**
	 * Открытие страницы договора.
	 * @param {string} recordId Id договора.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openContractPage(recordId, storeValuesBeforeRedirect) {
		if (recordId) {
			let homePageUrl = `/0/Nui/ViewModule.aspx#CardModuleV2/SblContractPortalPage/edit/${recordId}`;
			this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
		} else {
			this.openHomePage(storeValuesBeforeRedirect);
		}
	},

	/**
	 * Открытие страницы подписания документов.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openSignDocumentsPage(storeValuesBeforeRedirect) {
		let homePageUrl = "/0/Nui/ViewModule.aspx#CardModuleV2/SblSignDocumentPortalPage";
		this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
	},

	/**
	 * Открытие страницы секции договоров.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openContractSection(storeValuesBeforeRedirect) {
		let homePageUrl = "/0/Nui/ViewModule.aspx#CardModuleV2/SblContractPortalSectionPage";
		this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
	},

	/**
	 * Открытие домашней страницы.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openProfilePage(isEdoEnabled, storeValuesBeforeRedirect) {
		let homePageUrl = "";
		if(isEdoEnabled) {
			homePageUrl = "/0/Nui/ViewModule.aspx#CardModuleV2/SblAccountCustomerEdoPage/";
		} else {
			homePageUrl = "/0/Nui/ViewModule.aspx#SblPortalAccountCardModule/SblAccountCustomerPortalPage/";
		}
		this.setRedirectReference(storeValuesBeforeRedirect, homePageUrl);
	},

	/**
	 * Открытие страницы смены пароля.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров,
	 * необходимых для работы при входе.
	 */
	openChangePasswordPage(storeValuesBeforeRedirect) {
		var passwordChangeUrl = "/0/Nui/ViewModule.aspx?ErrorCode=5";
		this.setRedirectReference(storeValuesBeforeRedirect, passwordChangeUrl);
	},
  
	/**
	 * Установить URL ссылку для переадресации.
	 * @param {Function} storeValuesBeforeRedirect Функция, осуществляющая сохранение параметров, необходимых для работы при входе.
	 * @param {string} url строка переадресации.
	 */
	setRedirectReference(storeValuesBeforeRedirect, url) {
		var redirectUrl = window.location.origin + url;
		if (storeValuesBeforeRedirect) {
			storeValuesBeforeRedirect();
		}
		window.location.href = redirectUrl;
	},

	/**
	 * Получение параметров Return из строки URL.
	 * @returns {String} Параметры, указанные после Return в строке URL.
	 * @private
	 */
	getReturnUrl() {
		var fullUrl = window.location.href;
		var returnUrlCode = "?ReturnUrl=";
		var ipos = fullUrl.indexOf(returnUrlCode);
		if (ipos > 0) {
			return decodeURIComponent(fullUrl.substring(ipos + returnUrlCode.length));
		}
		return ""
	},

	/**
	 * Получение параметра из URL.
	 * @param {String} name Название параметра. 
	 * @param {String} searchStr Строка поиска.
	 */
	getUrlParameter(name, searchStr) {
		if (!searchStr) {
			searchStr = location.search;
		}
		name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
		var regex = new RegExp('[\\?&]' + name + '=([^&#]*)', "i");
		var results = regex.exec(searchStr);
		return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
	},

	
	/**
	 * Открыть окно для выбора контрагентов.
	 * @param {array} accounts Список контрагентов.
	 * @param {function} callback Функция обратного вызова.
	 */
	openAccountSelectLookup: function(accounts, callback, scope) {
		ModalService.show({
			component: LookupModal,
			props: {
				config: {
					caption: "Выберите компанию для осуществления входа в личный кабинет",
					columns: [
						{
							caption: "Компания",
							selector: (item) => item?.Name
						},
						{
							caption: "Руководитель",
							selector: (item) => item?.CeoName
						}
					],
					callback: function(result) {
						callback?.call(scope, result);
					},
					scope: scope
				},
				list: accounts
			},
			settings: {
				height: "auto",
				width: "960px"
			}
		});
	},
};