<template>
<div class="password-recovery sbl-base-container rounded-corners">
	<div class="sbl-card" v-show="!isLoading">
		<!-- Captions -->
		<div class="sbl-items-group">
		<div class="sbl-h2-text self-align-center">Восстановление пароля</div>
		</div>
		<div class="sbl-items-group">
		<div class="sbl-med-text">Введите e-mail, который Вы указали при регистрации, <br />
			и мы отправим Вам пароль на мобильный телефон.</div>
		</div>

		<!-- Input logic -->
		<div class="sbl-items-group">
		<div class="sbl-form-control-container">
			<!-- Caption ошибки должен располагаться выше!!!! -->
			<span class="floating-error" v-if="emailNotValidated" v-text="floatingErrorText"/>

			<input
			required
			autocomplete="off"
			class="sbl-form-control"
			type="text"
			v-model="userLogin"
			@blur="checkRecoverButtonAvailability()"
			@change="checkRecoverButtonAvailability()"
			@keyup="checkRecoverButtonAvailability()"
			/>
			<span class="floating-placeholder">E-mail</span>
		</div>
		</div>

		<!-- Капча -->
			<div id="yandexSmartCaptcha" class="sbl-items-group"></div>

		<!-- Button -->
		<div class="sbl-items-group">
		<RoundedButton
			caption="Получить пароль"
			additionalClasses="sbl-blue-button"
			:disabled="recoveryButtonDisabled"
			@click="tryToRestorePassword()"
		/>
		</div>

		<!-- Back -->
		<div class="sbl-items-group sbl-items-group-last-row">
		<router-link class="sbl-small-text sbl-link auto-width self-align-center" :to="{name: 'SignIn'}">&larr; Назад ко Входу в систему</router-link>
		</div>

	</div>
	<Spinner v-if="isLoading"></Spinner>
</div>
</template>

<script>
import RoundedButton from "../components/RoundedButton";
import ErrorModal from "../components/ErrorModal";
import ConfirmInnModal from "../components/ConfirmInnModal.vue";
import AuthService from "../services/AuthenticationService";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import ModalService from "@/services/ModalService.js";
import { Messages } from "../enums/Messages.js";
import SmsConfirmModalService from "@/services/SmsConfirmModalService.js";
import SmsConfirmationModal from "../components/SmsConfirmationModal";
import	UserBlockedModal from '../components/UserBlockedModal.vue';

export default {
	name: "PasswordRecovery",
	components: {
		RoundedButton,
		ErrorModal,
		SmsConfirmationModal,
		ConfirmInnModal
	},
	data() {
		return {
			/**
			 * Текст ошибки на Input-поле
			 */
			floatingErrorText: "Введите корректный электронный адрес",

			/**
			 * Логин пользователя (является адресом электронной почты), который он вводит самостоятельно.
			 */
			userLogin: "",

			/**
			 * Значение в поле Логин не прошло валидацию.
			 */
			emailNotValidated: false,

			/**
			 * Пароль пользователя.
			 */
			userPassword: "",

			/**
			 * Доступность кнопки восстановления пароля.
			 */
			recoveryButtonDisabled: true,

			/**
			 * Идет ли какая-либо загрузка.
			 */
			isLoading: false,

			/**
			* Идентификатор отрисованной капчи.
			*/
			captchaWidgetId: null,

			/**
			* Флаг мультикабинета.
			*/
			sblPortalPasswordRecoveryForFewlLoginEnable: true,

			/**
			 * Флаг статуса отключения отправки временного пароля.
			*/
			sblPortalTempPasswordDisable: false
		};
	},
	methods: {

		/**
		 * Проверка "Восстановить пароль".
		 */
		checkRecoverButtonAvailability() {
		this.recoveryButtonDisabled =
			!this.validateEmail(this.userLogin);
		},

		/**
		 * Валидация email.
		 * @param {String} Проверяемый email.
		 * @returns Boolean True, если валидация пройдена успешно.
		 * @private
		 */
		validateEmail(email) {
			var re = /^[a-zA-Z0-9]+(?:[\.a-zA-Z0-9!#$%&'*+/=?^_`{|}~-])*(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z-]*[a-zA-Z])?$/;
			var validationResult = re.test(String(email).toLowerCase());
			var isEmpty = String(email).length === 0;

			this.emailNotValidated = !validationResult && !isEmpty;
			return validationResult && !isEmpty;
		},

		/**
		 * Отобразить сообщение об ошибке.
		 * @param {String} errorMessage Текст сообщения.
		 * @param {Boolean} showRegisterButton Отображать ли кнопку "Зарегистрироваться".
		 */
		showErrorModal(errorMessage, showRegisterButton) {
			if (!errorMessage) {
				return;
			}
			showRegisterButton = showRegisterButton || false;

			ModalService.show({
				component: ErrorModal,
				props: {
					message: errorMessage,
					showRegisterButton: showRegisterButton
				},
				settings: {
					name: "ErrorModal",
					height: "auto",
					width: "545px"
				}
			});
		},

		/**
		 * Восстановление пароля.
		 */
		tryToRestorePassword() {
			let scope = this;
			let smartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);	
			
			if (!smartCaptchaResponse) {
				this.showErrorModal(Messages.SblIamNotRobot);
				return false;
			}
			
			var formData = {
				login: this.userLogin,
				captcha: smartCaptchaResponse
			};

			this.recoveryButtonDisabled = true;
			this.isLoading = true;
			SblUserManagementService.recoverPassword(formData)
				.then(function(response) {
					if (response) {
						var data = response && response.data;
						scope.recoveryButtonDisabled = false;
						if (!data.IsSuccess) {
							if (data.ErrorCode) {
								if (data.ErrorCode === "InternalServerError") {
									scope.showErrorModal(data.Error);
									window.smartCaptcha.reset(scope.captchaWidgetId);
								} else {
									window.console.error(data.Error, data);
									scope.showErrorModal(data.Error);
									window.smartCaptcha.reset(scope.captchaWidgetId);
									return;
								}
							} else {
								window.console.error(data.Error, data);

								scope.showErrorModal(
									"В процессе восстановления пароля возникла ошибка. Повторите позднее или обратитесь в Службу поддержки"
								);
								window.smartCaptcha.reset(scope.captchaWidgetId);
								return;
							}
						} else {
							//Success
							if (scope.sblPortalPasswordRecoveryForFewlLoginEnable) {
								//Мультикабинет включен
								if (data.IsInnRequired) {
									ModalService.show({
										component: ConfirmInnModal,
										props: {
											login: scope.userLogin
										}
									}).then((inn) => {
										//Если ИНН корректный
										let data = {
											login: scope.userLogin,
											inn: inn
										};
										sessionStorage.setItem("Inn", inn);
										
										scope.showSmsConfirmModal.call(scope, data);
									})
									.catch((error, response) => {
										console.error(error, response);
										scope.showErrorModal(error);
										window.smartCaptcha.reset(scope.captchaWidgetId);
									});
								} else {
									let data = {
										login: scope.userLogin
									};
									
									scope.showSmsConfirmModal.call(scope, data);
								}
							} else {
								//Мультикабинет выключен (старая логика)
								let data = {
									login: scope.userLogin
								};
								
								scope.showSmsConfirmModal.call(scope, data);
							}
						}
					}
				})
				.catch(function(error) {
					scope.recoveryButtonDisabled = false;
					window.console.log(error);
					scope.showErrorModal(
						"В процессе восстановления пароля возникла ошибка. Повторите позднее или обратитесь в Службу поддержки"
					);
				})
				.finally(() => {
					scope.isLoading = false;
				});
		},

		/**
		 * Показать модальное окно с подтверждением СМС кода.
		 * @param {object} data Информация о пользователе.
		 * @param {String} data.login Логин.
		 * @param {String} data.inn ИНН.
		 */
		showSmsConfirmModal(data) {
			let props = {
				modalData: data,
				confirmButtonFunc: SmsConfirmModalService.confirmRecoveryPasswordSmsCode,
				resendCodeFunc: SmsConfirmModalService.resendLoginSmsCode
			};

			if (data.inn) {
				props.confirmButtonFunc = SmsConfirmModalService.confirmRecoveryPasswordSmsCodeWithInn;
			}

			ModalService.show({
				component: SmsConfirmationModal,
				props: props,
				settings: {
					width:"560px"
				}
			})
			.then(() => {
				// Если все прошло хорошо, закрываем модалку и предлагаем пользователю ввести новый пароль
				this.changeUserPassword(this, this.userLogin);
			})
			.catch(() => {
				// Если все плохо ресетнуть капчу
				window.smartCaptcha.reset(this.captchaWidgetId);
			});
		},

		/**
		 * Метод рендеринга капчи Яндекс.
		 */
		onloadFunction() {
			let cachedSmartCaptchaKey = localStorage.getItem("SblSmartCaptchaSiteKey");

			if (window.smartCaptcha && cachedSmartCaptchaKey) {
				const container = document.getElementById('yandexSmartCaptcha');
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: cachedSmartCaptchaKey,
					hl: 'ru',
				});

				this.captchaWidgetId = widgetId;
				return;
			}

			SblUserManagementService.getSmartCaptchaSiteKey()
			.then( (response) => {
				var smartCaptchaSiteKey = response && response.data;

				if (!smartCaptchaSiteKey) {
					window.console.error(Messages.SblYandexCaptchaNoSiteKey);
					return;
				}
				const container = document.getElementById('yandexSmartCaptcha');
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: smartCaptchaSiteKey,
					hl: 'ru',
				});

				this.captchaWidgetId = widgetId;
				localStorage.setItem("SblSmartCaptchaSiteKey", smartCaptchaSiteKey);

			})
			.catch(function(error) {
				console.log(error);
			});
		},

		/**
		 * Добавить элемент капчи на страницу.
		 */
		setSmartCaptchaElement() {
			let captchaScript = document.createElement("script");
			captchaScript.setAttribute(
				"src",
				"https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction"
			);
			document.head.appendChild(captchaScript);
		},

		/**
		 * Получает с бэка значение системной настройки и устанавливает соответствующий флаг.
		 */
		checkSblPortalPasswordRecoveryForFewlLoginEnable(){
		SblUserManagementService.checkSblPortalPasswordRecoveryForFewlLoginEnable()
			.then(function(response) {
				if (response && response.data) {
					this.sblPortalPasswordRecoveryForFewlLoginEnable = response.data;
				}
			}.bind(this))
		},

		/**
		 * Получает с бэка значение системной настройки необходимости отправки смс.
		 */
		checkSblPortalTempPasswordDisable(){
			SblUserManagementService.checkSblPortalTempPasswordDisable()
			.then(function(response) {
				if (response && response.data) {
					this.sblPortalTempPasswordDisable = response.data;
				}
			}.bind(this))
		},

		/**
		 * Показать модальное окно блокировки пользователя.
		 */
		showUserBlockedModal(blockDuration) {
			ModalService.show({
				component: UserBlockedModal,
				props: {
					blockDuration: blockDuration
				},
				settings: {
					adaptive: true,
					width: 580,
					height: "auto",
					clickToClose: false
				}
			});
		},

		/**
		* Поменять пароль.
		*/
		changeUserPassword(scope, userLogin) {
			// Если сис. настройка включена, не используем временный пароль.
			if (scope.sblPortalTempPasswordDisable) {
				SblUserManagementService.getUserTimeToUnlock(userLogin)
				.then(function(response) {
					let data = response && response.data;
					if (!data) {
						throw "Сервис работы с аккаунтами пользователя пароля вернул пустой ответ на запрос о наличии блокировки пользователя. "
					}
					let timeToUnlock = data.GetUserTimeBeforeUnlockResult;
					if (timeToUnlock > 0) {
						scope.showUserBlockedModal(timeToUnlock);
					} else {
						sessionStorage.setItem("UserName", this.userLogin);
						scope.$router.push({
							name: "ChangePassword"
						})
					}
				}.bind(scope))
				.catch((error, response) => {
					console.error(error, response);
				});
			} else {
				scope.$router.push({
					name: "PasswordRecoveryConfirm",
					params: { login: userLogin }
				});
			}

		}
	},
	mounted() {
		window.onloadFunction = this.onloadFunction;
		this.setSmartCaptchaElement();
		this.checkSblPortalPasswordRecoveryForFewlLoginEnable();
		this.checkSblPortalTempPasswordDisable();
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/elements.scss";

.password-recovery {

	font-family: "SB Sans Display";

	.sbl-med-text {
		font-size: 14px;
		font-weight: 400;
		color: #293C4D;
	}
	.sbl-h2-text {
		font-size: 24px;
		font-weight: 400;
		color: #293C4D;
	}
	.sbl-card {
		padding-top: 15px;
		padding-bottom: 15px;
			border-radius: 20px;
			border: 1px solid #D8E1E8;
			background-color: #FFFFFF;
	}
	.sbl-form-control-container input{
		margin-bottom: 15px;
	}

	&__capcha {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
