import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для реализации авторизации по сертификату.
 */
export class SblDaDataAccountService {

  static get ApiService() { return "SblDaDataAccountService.svc" }

  static get BaseUrl() { return "../0/ServiceModel/" }

  /**
   * Запросить данные по ИНН КПП
   * @param {*} dataToSend
   */
  static queryByInnKpp(dataToSend) {
    return ApiConnection.post(this.ApiService + '/QueryByInnKpp',
      dataToSend,
      {
        baseURL: this.BaseUrl
      }
    );
  }

  /**
   * Запросить данные по контрагентам
   * @param {*} dataToSend 
   */
  static queryByName(dataToSend) {
    return ApiConnection.post(this.ApiService + '/QueryByName',
    dataToSend,
      { baseURL: this.BaseUrl }
    );
  }
}
