<template>
	<div class="sbl-modal rounded-corners">
		<div class="sbl-modal-header">
			<div class="sbl-modal-header-h1">{{title}}</div>
			<button class="modal-close-button" v-if="showCloseButton" @click="$emit('close')" />
		</div>
		<slot name="content"></slot>
	</div>
</template>

<script>
/**
* Базовое модальное окно.
*/
export default {
	props: {
		/**
		* Заголовок модального окна.
		*/
		title: {
			type: String,
			required: true
		},

		/**
		* Показывать кнопку закрытия.
		*/
		showCloseButton: {
			type: Boolean,
			required: false,
			default: true
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

	.sbl-modal-header-h1 {
		margin:auto;
	}

	.modal-close-button {
		align-self: flex-end;
		position: absolute;
		right:2%;
		top:2%;
		background-image: url(~@/assets/images/close-button.png);
		background-repeat: no-repeat;
		background-position: center;
		width: 2em;
		height: 2em;
		border: none;
		background-color: transparent;
		cursor: pointer;
	}

	.sbl-modal {
		padding: 10px;
		border: none;
		background: $popup-background-color;
		font-family: $primary-font;
		height: 100%;

		&-row {
		padding-top: 15px;
	}

	&-content {
		background-color: $popup-background-color;
		width: 580px;
		z-index: 2;
		position: relative;
	}

	&-xs &-content {
		width: 400px;
	}

	&-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-family: $secondary-font;

		&-h1 {
			font-size: 20px;
			font-weight: bold;
			display: flex;
			align-items: center;
		}
	}
}
</style>

