<template>
	<div class="sbl-singning-success sbl-base-container rounded-corners">
	<div class="sbl-card" v-show="!isLoading">
		<!-- Captions -->
		<div class="sbl-items-group">
			<div class="sbl-h2-text self-align-center">Документы уже подписаны</div>
		</div>
		<div class="sbl-items-group">
			<div class="sbl-med-text">Ссылка, по которой вы перешли, уже использована, 
			а документы были успешно подписаны</div>
		</div>

		<!-- Button -->
		<div class="sbl-items-group">
			<RoundedButton
				caption="Закрыть страницу"
				additionalClasses="sbl-green-button"
				@click="onCloseButtonClick()"
			/>
		</div>

	</div>
	<Spinner v-if="isLoading"></Spinner>
	</div>
</template>

<script>

import RoundedButton from "@/components/RoundedButton";
import { CustomLinks } from "../enums/CustomLinks.js";

export default {
	name: "SigningSuccess",
	components: {
		RoundedButton
	},
	data() {
		return {};
	},
	methods: {
		/**
		 * Обработчик нажатия кнопки Закрыть.
		 */
		onCloseButtonClick() {
			window.location.replace(CustomLinks.SberleasingSite);
		}
	},
	mounted() {}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
@import "@/styles/elements.scss";

@media (min-width: 864px) {
	.sbl-base-container {
		margin: auto;
		width: 600px;
		border-radius: $border-rad;
		box-shadow: none;
	}
}

@media (max-width: 864px) {
	.sbl-base-container {
		margin: auto;
		width: 50%;
		min-width: 320px;
		border-radius: $border-rad;
		box-shadow: none;
	}
	.sbl-h2-text {
		font-size: 20px !important;
	}
}

.sbl-singning-success {
	font-family: "SB Sans Display";

	.sbl-med-text {
		font-size: 14px;
		font-weight: 400;
		color: #293C4D;
	}

	.sbl-h2-text {
		font-size: 22px;
		font-weight: 600;
		color: #293C4D;
	}

	.sbl-card {
		padding-top: 20px;
		padding-bottom: 20px;
		border-radius: 20px;
		border: 1px solid #D8E1E8;
		background-color: #FFFFFF;
	}

	.sbl-medium-button {
		width: 200px;
	}  
}
</style>
