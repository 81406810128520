import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для реализации авторизации.
 */
export class AuthService {

  /**
   * Базовый url для запросов.
   */
  static get ApiService() { return "AuthService.svc" }

  /**
   * Ext версия сервиса авторизации.
   */
  static get ApiServiceExt() { return "SblUserManagementServiceExt.svc" }

  static get BaseUrl() { return "../0/ServiceModel/" }

  /**
   * Авторизация.
   * @param {any} param0 Информация о пользователе, под которых идет попытка авторизации.
   * @param {String} param0.userName Имя пользователя. 
   * @param {String} param0.userPassword Пароль пользователя. 
   */
  static login({ UserName, UserPassword, Captcha }) {
    const options = {
      baseURL: this.BaseUrl,
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json",
        "Authorization": "Cookie",
        "ForceUseSession": "true"
      }
    };

    return ApiConnection.post(`${this.ApiServiceExt}/Login`,
      {
        login: UserName,
        password: UserPassword,
        captcha: Captcha
      },
      options);
  }
}