<template>
    <BaseModal title="" @close="$emit('close')">
      <template v-slot:content>
        <div class="personal-data-modal sbl-card">

          <div class="sbl-items-group sbl-h2-text">
            <a class="self-align-center personal-data-modal-header">Согласие на обработку персональных данных</a>
          </div>

          <div class="sbl-items-group">
            <div class="personal-data-modal-message">
              <p style="text-indent:25px;">Я даю своё согласие АО Сбербанк Лизинг (адрес: 143026, Московская обл, г. Одинцово, Рабочий поселок Новоивановское, Можайское шоссе, владение 165, помещение 3.1) (далее – Лизингодатель) на автоматизированную обработку моих персональных данных (ФИО, телефон, электронная почта) с совершением следующих действий: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), использование, блокирование, удаление, уничтожение, передачу (предоставление, доступ) с целью получения информации об истории работы с Лизингодателем и доступа к сервисам Лизингодателя, а также на отправку электронных писем и смс-сообщений о результатах поданной заявки.</p>
              <p style="text-indent:25px;">Согласие может быть отозвано мной в любой момент путём направления в АО «Сбербанк Лизинг» письменного заявления, составленного в простой письменной форме.</p>
              <p style="text-indent:25px;">Согласие признаётся подписанным простой электронной подписью - аналогом собственноручной подписи при нажатии кнопки "Продолжить".</p>
              <p style="text-indent:25px;">Я выражаю согласие на использование моих данных для поддержания связи со мной любым способом, включая телефонные звонки на указанный мобильный телефон, отправку СМС-сообщений на указанный мобильный телефон, отправку электронных писем на указанный электронный адрес с целью информирования о статусах рассмотрения заявки на лизинг, изменениях по текущим договорам, специальных предложениях и других изменениях в личном кабинете.</p>
            </div>
          </div>

          <div class="sbl-items-group">
            <div class="personal-data-modal-check">
              <label>
                <input id="needToAgree" type="checkbox" class="sbl-checkbox" v-model="agreement" />
                <span class="sbl-small-text">Нажимая кнопку "Продолжить", я соглашаюсь на обработку моих персональных данных</span>
              </label>
            </div>

            <div class="personal-data-modal-check personal-data-modal-check-second">
              <label>
                <input
                  id="needToAgreeUserAgreement"
                  type="checkbox"
                  class="sbl-checkbox"
                  v-model="userAgreement"
                />
                <span class="sbl-small-text"> Нажимая кнопку "Продолжить", я подтверждаю, что ознакомлен и принимаю
                  <a
                    href="#"
                    class="sbl-link"
                    @click="getUserAgreement()"
                  >Пользовательское соглашение</a>
                </span>
              </label>
            </div>
          </div>


          <div class="sbl-items-group-multiple sbl-items-group-last-row">
            <RoundedButton id="cancel"
              caption="Отмена"
              additionalClasses="sbl-blue-button"
              @click="onCancelButtonClicked()"
            />
            <RoundedButton id="continue"
              caption="Продолжить"
              additionalClasses="sbl-green-button "
              :disabled="!agreement || ! userAgreement"
              @click="continueButtonClicked()"
            />
          </div>
        </div>
      </template>
    </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";
import ErrorModal from "../components/ErrorModal";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import MessageBox from "@/services/MessageBox";

/**
 * Модальное окно СОПД.
 */
export default {
  props: {
    /**
     * Функция обработки события нажатия на кнопку "Продолжить".
     */
    continueButtonClicked: {
      type: Function,
      required: true
    }
  },
  components: {
    RoundedButton,
    BaseModal,
    ErrorModal
  },
  data() {
    return {
      agreement: false,
      userAgreement: false
    };
  },
  methods: {
    /**
     * Обработчик события нажатия на кнопку "Отмена".
     */
    onCancelButtonClicked() {
      this.$emit("close");
    },

    /**
     * Получение пользовательского соглашения.
     */
    getUserAgreement() {
      var scope = this;
      SblUserManagementService.getUserAgreement()
        .then(function(response) {
          var data = response && response.data;
          if (data) {
            var pdfWindow = window.open("");
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(data) +
                "'></iframe>"
            );
          } else {
            MessageBox.error("Произошла ошибка загрузки файла.");
          }
        })
        .catch(function(error) {
          error += "\n Сервис недоступен";
          MessageBox.error(errorMessage);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.personal-data-modal {
  font-family: $primary-font;
  
  &-header {
    text-align: center;
  }

  #cancel, #continue {
    margin: 0px 10px 0px 10px;
  }

  &-check {
    font-weight: bold;
    cursor: pointer;

    & span {
      margin-left: 10px;
      cursor: pointer;
      margin-top: auto;
      margin-bottom: auto;
    }
    & label {
      display: flex;
      cursor: pointer;
    }
    &-second {
      margin-top: 15px;
    }
  }
}
</style>

