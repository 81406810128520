<template>
	<div class="password-recovery-confirm sbl-base-container rounded-corners">
		<div class="sbl-card">
			<!-- Captions -->
			<div class="sbl-items-group">
			<div class="sbl-h2-text self-align-center">Восстановление пароля</div>
		</div>

		<div class="sbl-items-group">
		<div class="sbl-med-text">На ваш телефон отправлен новый пароль</div>
	</div>

	<!-- Input -->
	<!-- TODO: redisign one symbol=one number -->
	<div class="sbl-items-group">
		<div class="sbl-form-control-container">
			<input
				required
				autocomplete="new-password"
				class="sbl-form-control"
				:type="passwordFieldType"
				v-model="password"
			/>
			<span class="floating-placeholder">Пароль из SMS</span>

			<a
				v-if="password == ''"
				class="password-actions password-actions-not-active"
			/>
			<a
				@click="switchOnPasswordVisibility"
				v-if="passwordFieldType == 'password' && password != ''"
				class="password-actions password-actions-show"
			/>
			<a
				@click="switchOffPasswordVisibility"
				v-if="passwordFieldType == 'text' && password != ''"
				class="password-actions password-actions-hide"
			/>
		</div>
	</div>

	<!-- Капча -->
	<div
		id="yandexSmartCaptcha"
		class="sbl-items-group"
		v-show="isSmartCaptchaVisible"
	></div>

	<!-- Continue -->
	<div class="sbl-items-group">
		<RoundedButton
			caption="Продолжить"
			additionalClasses="sbl-blue-button"
			:disabled="!password"
			@click="onRecoveryButtonClick()"
		/>
	</div>

	<!-- Send one more time -->
	<div class="sbl-items-group sbl-items-group-last-row">
		<div class="sbl-small-text self-align-center">
			<a>Не получили пароль?</a>
			<router-link
				class="sbl-small-text sbl-link sbl-link-spacing"
				:to="{ name: 'PasswordRecovery' }"
				>Отправить пароль еще раз</router-link
			>
		</div>
		</div>
	</div>

	<Spinner v-if="isLoading"></Spinner>
	</div>
</template>

<script>
import RoundedButton from "../components/RoundedButton";
import ErrorModal from "../components/ErrorModal";
import AuthService from "../services/AuthenticationService";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import ModalService from "@/services/ModalService.js";
import UserBlockedModal from "../components/UserBlockedModal.vue";
import MessageBox from "@/services/MessageBox";
import { Messages } from "../enums/Messages.js";
import { AuthenticationErrorCodes } from "../enums/AuthenticationErrorCodes";

export default {
	name: "PasswordRecoveryConfirm",
	components: {
		RoundedButton,
		ErrorModal,
	},
	props: {
		/**
		 * Логин пользователя.
		 */
		login: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isLoading: false,

			/**
			 * Пароль пользователя.
			 */
			password: "",

			passwordFieldType: "password",

			/**
			 * Идентификатор отрисованной капчи.
			 */
			captchaWidgetId: null,

			/**
			 * Отображение капчи на странице.
			 */
			isSmartCaptchaVisible: false,
		};
	},
	methods: {
		switchOnPasswordVisibility() {
			this.passwordFieldType = "text";
		},

		switchOffPasswordVisibility() {
			this.passwordFieldType = "password";
		},

		/**
		 * Обработчик события нажатия на кнопку восстановления пароля/продолжения.
		 */
		onRecoveryButtonClick() {
			if (!this.login) {
				MessageBox.error("Не указан логин пользователя для восстановления пароля");
			}
			if (!this.password) {
				MessageBox.error("Не указан временный пароль пользователя для восстановления пароля");
			}
			let smartCaptchaResponse = "";

			if (this.isSmartCaptchaVisible) {
				smartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);

				if (!smartCaptchaResponse) {
					this.showErrorMessage(Messages.SblIamNotRobot);
					return;
				}
			}

			this.isLoading = true;

			AuthService.tryLogin(
				{
					UserName: this.login,
					UserPassword: this.password,
					Captcha: smartCaptchaResponse,
				},
				this.onError,
				this.saveLoginDataBeforeRedirect
			);
			window.smartCaptcha.reset();
		},

		/**
		 * Handler ошибок.
		 */
		onError(errorMessage, isCapchaNeeded, response) {
			this.isLoading = false;

			if (isCapchaNeeded) {
				this.isSmartCaptchaVisible = true;
			} else if (
				response &&
				response?.Code === AuthenticationErrorCodes.UserBlockedForTime
			) {
				let blockDuration = response?.BlockDuration;
				this.showUserBlockedModal(blockDuration);
			} else {
				MessageBox.error(errorMessage);
			}
		},

		/**
		 * Сохранение параметров перед перенаправлением страницы.
		 */
		saveLoginDataBeforeRedirect() {
			this.isLoading = false;

			sessionStorage.setItem("UserName", this.login);
			sessionStorage.setItem("UserPassword", this.password);
		},

		/**
		 * Метод рендеринга капчи Яндекс.
		 */
		onloadFunction() {
			let cachedSmartCaptchaKey = localStorage.getItem("SblSmartCaptchaSiteKey");

			if (window.smartCaptcha && cachedSmartCaptchaKey) {
				const container = document.getElementById("yandexSmartCaptcha");
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: cachedSmartCaptchaKey,
					hl: "ru",
				});

				this.captchaWidgetId = widgetId;
				return;
			}

			SblUserManagementService.getSmartCaptchaSiteKey()
			.then((response) => {
				var smartCaptchaSiteKey = response && response.data;

				if (!smartCaptchaSiteKey) {
					window.console.error(Messages.SblYandexCaptchaNoSiteKey);
					return;
				}
				const container = document.getElementById("yandexSmartCaptcha");
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: smartCaptchaSiteKey,
					hl: "ru",
				});

				this.captchaWidgetId = widgetId;
				localStorage.setItem("SblSmartCaptchaSiteKey", smartCaptchaSiteKey);
			})
			.catch(function (error) {
				console.log(error);
			});
		},

		/**
		 * Добавить элемент капчи на страницу.
		 */
		setSmartCaptchaElement() {
			let captchaScript = document.createElement("script");
			captchaScript.setAttribute(
				"src",
				"https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction"
			);
			document.head.appendChild(captchaScript);
		},

		/**
		 * Показать модальное окно блокировки пользователя.
		 */
		showUserBlockedModal(blockDuration) {
			ModalService.show({
				component: UserBlockedModal,
				props: {
					blockDuration: blockDuration,
				},
				settings: {
					adaptive: true,
					width: 580,
					height: "auto",
					clickToClose: false,
				}
			});
		}
	},
	mounted() {
		window.onloadFunction = this.onloadFunction;
		this.setSmartCaptchaElement();
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.password-recovery-confirm {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	&__subhead-text {
		margin-top: 20px;
	}

	&__buttons-container {
		display: flex;
		flex-direction: column;
		margin: 20px 0;

		& button,
		& a {
			width: 100% !important;
			margin-top: 20px;
			text-align: center;
		}

		& a {
			color: $unactive-color;
			text-decoration: none;
			font-weight: bold;
			margin-top: 40px;
		}
	}

	&__link {
		text-decoration: underline;
		font-weight: 100;
		color: $primary-color;
	}

	&__password {
		margin-top: 20px;
		text-align: center;
	}

	&__capcha {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
