<template>
	<BaseModal title="" @close="onClose(okClickHandler)">
		<template v-slot:content>
			<div class="info-modal sbl-card">

			<!-- Message -->
			<div class="sbl-items-group info-modal-message">
				<a class="info-modal-text sbl-med-text">{{ message }}</a>
			</div>

			<!-- Buttons -->
			<div class="sbl-items-group self-align-center sbl-items-group-last-row">
				<RoundedButton caption="ОК" @click="onClose(okClickHandler)" additionalClasses="sbl-green-button"/>
			</div>
			</div>
		</template>
	</BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";

/**
 * Модальное окно с сообщением.
 */
export default {
	props: {
		/**
		 * Сообщение.
		 */
		message: {
			type: String,
			default: "Действие успешно завершено"
		},

		/**
		 * Функция обработки события нажатия на кнопку "OK".
		 */
		okClickHandler: {
			type: Function
		}
	},
	components: {
		RoundedButton,
		BaseModal
	},
	methods: {
		/**
		* Закрытие окна с вызовом доп.обработчика
		*/
		onClose: function(customHandler) {
			this.$emit("close");
			if (customHandler != undefined) {
				customHandler();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.info-modal {
	background-color: $popup-background-color;
	color: $popup-font-color;

	.sbl-items-group-last-row{
		gap:10px;
	}
	.sbl-inner-iframe {
		width: 100%;
		border-style: ridge;
		border-radius: 0.5em;
	}

	button {
		max-width: 150px;
	}

	&-message {
		display: flex;
		flex-direction: row;
		align-items: center;
		word-break: break-word;

		&-text {
			white-space: pre-line;
			overflow-y: auto;
			padding-right: 10px;
		}
	}

	&-text {
		text-align: justify;
	}
}
</style>