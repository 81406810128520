/**
 * Сообщения для пользователя.
 */
export const Messages = {
	SblInnShouldContainOnlyDigits: 'В поле должны быть только цифры',
	SblInnIncorrectValue: 'Некорректное значение ИНН',
	SblInnIncorrectLength: 'ИНН должен состоять из 10 или 12 цифр',
	SblPrivateKeyNotFound: 'Приватный ключ сертификата для проверки не найден',
	SblCertificateVerificationFailed: 'При проверке сертификата произошла ошибка. Обратитесь к вашему клиентскому менеджеру или по телефону 8-800-555-55-56',
	SblServiceIsTemporarilyUnavailable: 'Сервис временно недоступен. Обратитесь к вашему клиентскому менеджеру или по телефону 8-800-555-55-56',
	SblCertificateChainCouldNotBeBuilt: 'Не установлены корневые сертификаты. Нужно скачать и установить корневые и промежуточные сертификаты.',
	SblCertificateNotInValidityPeriod: 'Срок действия вашего сертификата электронной подписи истек. Пожалуйста, осуществите ее перевыпуск.',
	SblChangePasswordMessageForSimpleLogin: 'Для входа в систему по логину (электронная почта по умолчанию) и паролю необходимо задать новый постоянный пароль.',
	SblChangePasswordMessageForCertificateLogin: 'Для возможности входа в систему по логину и паролю укажите здесь временный пароль из SMS и придумайте новый. В качестве логина будет использован email, указанный при регистрации',
	SblSberBusinessAuthorizationFailed: 'Ошибка авторизации по SberId',
	SblSberBusinessRegistrationFailed: 'Ошибка получения данных из SberId',
	SblSberIdAuthorizationFailed: 'Проверьте, что вы являетесь клиентом ПАО Сбербанк и правильно ввели свои данные. Повторите операцию через несколько минут или обратитесь в техническую поддержку',
	SblIamNotRobot: 'Вы не заполнили поле "Я не робот".',
	SblEmptyResponseFromAuthService: "Сервис работы с аккаунтами пользователя пароля вернул пустой ответ на запрос смены пароля.",
	SblConfirmationCodeSent: "Вам выслан код подтвеждения.",
	SblProcessingRequest: "Обработка запроса...",
	SblYandexCaptchaNoSiteKey: "Не найден ключ сайта для Yandex Smart Captcha.",
	SblCommonErrorTitle: "Произошла ошибка",
	SblCommonError: "Попробуйте повторить операцию через несколько минут или обратитесь в поддержку",
	SblSberBusinessIdNonClientMessage: "Для использования сервиса вам необходимо иметь счет в СберБанк",
	SblIsRegistrationStartedMessage: "Ваша заявка на регистрацию принята. На указанный Email было выслано письмо для подтверждения и продолжения регистрации",
	SblUserBlockedMessage: "Вход временно заблокирован",
	SblWrongPasswordNumberMessage: "Вы несколько раз подряд неверно ввели пароль.",
	SblWrongPasswordTryAgainMessage: "Попробуйте войти еще раз через ",
	SblIsNeedToSelectDocumentsForSigningMessage: "Для подписания необходимо выбрать хотя бы один документ",
	SblSuccessSigningMessage: "Ваши согласия на обработку персональных данных успешно подписаны",
	SblSuccessCancelSigningMessage: "Отмена подписания согласий прошла успешно. Можете закрыть данную страницу",
	SblSmsModalCommonError: "Произошла ошибка. Обратитесь в службу поддержки",
	SblSmsModalWrongCodeError: "Введен некорректный код, попробуйте еще раз",
	SblSmsModalExpireCodeError: "Срок действия кода истек. Запросите новый код",
	SblSmsModalLimitCodeError: "Вы исчерпали количество попыток проверки кода. Запросите код повторно",
	SblWrongEnteredDataError: "Проверьте корректность введенных данных"
}