<template>
	<div :class="getInputContainerClasses">
	<input 
		v-for='n in count' 
		v-bind:key='n'
		v-model='pincode[n]'
		v-on:keyup.enter='enterPressed'
		@keypress='IsDigit($event,n-1)'
		@keyup='InputUpdated'
		onmousedown="return false"
		:class="getInputClasses"
		maxlength=1
		type="text" />
	</div>
</template>

<script>
import $ from 'jquery'

export default {
	name: "PincodeInput",
	props: {
		/**
		 * Css-классы каждого окна.
		 */
		additionalInputClasses: String,

		/**
		 * Css-классы окна ввода.
		 */
		additionalInputContainerClasses: String,

		/**
		 * Длина кода.
		 */
		length: Number
	},
	data() {
		return {
			defaultInputClasses: "pin-input",
			defaultInputContainerClasses: "pin-input-container",
			count: this.length,
			pincode: []
		};
	},
	computed: {
		getInputClasses() {
			return this.defaultInputClasses + " " + this.additionalInputClasses;
		},
		getInputContainerClasses() {
			return this.defaultInputContainerClasses + " " + this.additionalInputContainerClasses;
		}
	},
	methods: {
		/**
		 * Нажатие на enter
		 */
		enterPressed: function(){
			this.$emit("enter-pressed");
		},

		/**
		 * Получить строчку из массива.
		 */
		getStringByArray(arr){
			if (!arr) {
				return;
			}
			let result = "";
			arr.forEach(function(i) {
				result += i
			});
			return result;
		},

		/**
		 * Оповещение о том, что input обновлен.
		 */
		InputUpdated(){
			this.$emit('input', this.getStringByArray(this.pincode));
		},

		/**
		 * Проверка на ввод цифры.
		 */
		IsDigit: function(evt) {
			evt = (evt) ? evt : window.event;
			let charCode = (evt.which) ? evt.which : evt.keyCode;
			if (charCode < 48 || charCode > 57) {
				evt.preventDefault();
			} else {
				if (evt.target.value) {
					evt.target.value = "";
				}
			}
		}
	},
	mounted() {
		const scope = this;

		// Устанавливаем фокус на первый input.
		if ($(".pin-input").length) {
			$(".pin-input")[0].focus();
		}

		// Переход к следующему input.
		let stepNext = false;

		// Обработка нажатия клавиши.
		$(".pin-input").keydown(function(e) {
			if (e.code == "Backspace") {
				let $prev = $(this).prev('.pin-input');
				if ($prev.length && !this.value) {
					$(this).prev('.pin-input').focus();
				}
			} else {
				if (!$.isNumeric(e.key)) {
					return;
				}

				stepNext = true;
			}
			return;
		});

		$(".pin-input").keyup(function(e) {
			if (scope.getStringByArray(scope.pincode).length === scope.length) {
				scope.enterPressed();
			}
			
			if (stepNext && this.value) {
				$(this).next('.pin-input').focus();
				stepNext= false;
			}
		})

		$(".pin-input").click(function(e) {
			$(this).focus();
			
			return;
		});
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/elements.scss";
.pin-input-container {
	padding:10px;
	display:flex;
	justify-content: space-around;
	width: 100%;

	@media only screen and (max-width: 478px) {
		.pin-input {
			min-width: 35px;
			min-height: 70px;
		}
	}

	@media only screen and (min-width: 478px) {
		.pin-input {
			min-width: 56px;
			min-height: 86px;
		}
	}

	.pin-input {
		flex:0;
		padding:2% 2% 2% 3%;

		padding: 0;
		border-radius: 10px;
		border: 2px solid #D8E1E8;
		background: #FFFFFF;

		color: #212529;
		text-align: center;
		font-family: "SBSansUI-Regular";
		font-size: 32px;
		font-weight: 400;

		&:not(:disabled):hover{
			border: 2px solid #9FA8AD;
		}
	}
	.pin-input:focus {
		border: 2px solid #777777;
	}
}

</style>