<template>
<div class="email-validation-success">
    <div class="sbl-h1">Проверка адреса электронной почты успешно выполнено. Вы можете закрыть данную страницу.</div>
</div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

</style>
