<template>
  <div class="sign-up">
    <div class="sbl-h1">Регистрация</div>
    <div class="sign-up__registration-subtext">
      <router-link class="sbl-link" :to="{name: 'SignIn'}">У меня уже есть учетная запись</router-link>
    </div>

    <div class="sign-up__buttons-container">
      <RoundedButton
        caption="По электронной подписи"
        additionalClasses="sign-up__buttons-container-button"
        @click="registerByCertificate()"
      />
      <div class="sign-up__buttons-container-separator"></div>
      <div class="sbl-form-control-container">
        <div @mouseover="showWarning = true" @mouseleave="showWarning = false">
          <!--<router-link :to="{name: 'SignUpWithPersonalData'}">-->
          <RoundedButton caption="Без электронной подписи"
          additionalClasses="sign-up__buttons-container-button show-warning sbl-gray-button" />
          <!--</router-link>-->
        </div>
        <div class="sign-up__warning-message" v-show="showWarning">Будет доступно в ближайшее время</div>
      </div>
    </div>
  </div>
</template>

<script>
import RoundedButton from "../components/RoundedButton";
import RegisterByCertificateModal from "../components/RegisterByCertificateModal";
import ModalService from "@/services/ModalService.js";

/**
 * Страница регистрации (выбора способа регистрации).
 */
export default {
  name: "SignUp",
  data() {
    return {
      /**
       * Отображение предупреждения.
       */
      showWarning: false
    };
  },
  components: {
    RoundedButton,
    RegisterByCertificateModal
  },
  methods: {
    /**
     * Обработчик события нажатия на кнопку "По электронной подписи".
     */
    registerByCertificate() {
      ModalService.show({
        component: RegisterByCertificateModal,
        props: {},
        settings: {
          name: "RegisterByCertificateModal",
          height: "auto",
          width: "600px"
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.sign-up {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;

  &__registration-subtext {
    margin-top: 10px;
  }

  &__buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 0 20px 0;
    font-size: 16px;

    &-button {
      font-size: 16px !important;
      height: 70px !important;
    }

    &-separator {
      height: 100px;
      width: 1px;
      background-color: $dark-color;
      margin: 0 100px;
    }
  }

  &__warning-message {
    position: absolute;
    left: 10%;
    top: 100%;
    border: 1px solid $primary-color;
    min-height: 35px;
    margin-top: 5px;
    margin-left: 14px;
    width: 150px;
    padding: 10px 15px;
    color: $dark-color;
    font-size: 13px;
    background-color: $light-color;
    box-shadow: 0 0 20px 0 $primary-color;
    line-height: 1.5;
    font-family: $primary-font;
    font-weight: 700;
    z-index: 100;
  }
}
</style>
