<template>
	<BaseModal title="" @close="$emit('close')" :showCloseButton="showCloseButton">
	<template v-slot:content>
		<div class="error-modal sbl-card">

		<!-- Caption -->
		<div class="self-align-center sbl-h2-text">
			<a>{{ title }}</a>
		</div>

		<!-- Error desc -->
		<div class="sbl-items-group error-modal-message">
			<img src="../assets/images/warning.png" alt="Error" />
			<a class="error-modal-text sbl-med-text" v-if="!checkHtmlInMessage()">{{ message }}</a>
			<iframe class="error-modal-text sbl-med-text sbl-inner-iframe" v-else v-bind:srcdoc="message"/>
		</div>

		<!-- Buttons -->
		<div class="sbl-items-group self-align-center sbl-items-group-last-row">
			<RoundedButton
			v-if="showRegisterButton"
			caption="Зарегистрироваться"
			@click="$router.push({ name: 'SignUp' }); onClose()"
			/>
			<RoundedButton :caption="successButtonCaption" @click="onClose(okClickHandler)" additionalClasses="sbl-green-button"/>
			<RoundedButton v-if="showCancelButton" caption="Отмена" additionalClasses="sbl-blue-button" @click="onClose(cancelClickHandler)" />
		</div>
		</div>
	</template>
	</BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";

/**
 * Модальное окно с сообщением об ошибке.
 */
export default {
	props: {
		/**
		 * Заголовок ошибки.
		 */
		title: {
			type: String,
			default: "Ошибка"
		},
		
		/**
		 * Сообщение об ошибке.
		 */
		message: {
			type: String,
			default: "Error occured"
		},

		/**
		 * Отображение кнопки "Регистрация".
		 */
		showRegisterButton: {
			type: Boolean,
			default: false
		},

		/**
		 * Отображение кнопки "Отмена".
		 */
		showCancelButton: {
			type: Boolean,
			default: false
		},

		/**
		 * Отображение кнопки "Закрыть".
		 */
		showCloseButton: {
			type: Boolean,
			default: true
		},
		
		/**
		 * Функция обработки события нажатия на кнопку "Отмена".
		 */
		cancelClickHandler: {
			type: Function
		},

		/**
		 * Функция обработки события нажатия на кнопку "OK".
		 */
		okClickHandler: {
			type: Function
		},

		/**
		 * Заголовок Success кнопки.
		 */
		successButtonCaption: {
			type: String,
			default: "ОК"
		}
	},
	components: {
		RoundedButton,
		BaseModal
	},
	methods: {
		checkHtmlInMessage: function() {
			return this.message.includes('DOCTYPE');
		},

		/**
		* Закрытие окна с вызовом доп.обработчика.
		*/
		onClose: function(customHandler) {
			this.$emit("close");
			if (customHandler != undefined) {
			customHandler();
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";
.error-modal {
	background-color: $popup-background-color;
	color: $popup-font-color;

	.sbl-items-group-last-row{
		gap:10px;
	}
	.sbl-inner-iframe {
		width: 100%;
		border-style: ridge;
		border-radius: 0.5em;
	}

	button {
		max-width: 150px;
	}

	&-message {
		display: flex;
		flex-direction: row;
		align-items: center;
		word-break: break-word;

		img {
			margin-right: 10px;
			width: 40px;
		}

		&-text {
			white-space: pre-line;
			overflow-y: auto;
			padding-right: 10px;
		}
	}

	&-text {
		text-align: justify;
	}
}
</style>

