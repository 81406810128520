/**
 * Тип ОПФ.
 */
export const OpfTypes = {
  ' ': ' ',
  'ЗАО': 'ЗАО',
  'ОАО': 'ОАО',
  'ООО': 'ООО',
  'ПАО': 'ПАО',
  'АО': 'АО',
  'ИП': 'ИП'
}