import ErrorModal from "@/components/ErrorModal.vue";
import ModalService from "@/services/ModalService.js";

export default {
  /**
   * Показать сообщение об ошибке.
   * @param {String} message Сообщение.
   * @param {String} title Заголовок.
   */
  error(message, title) {
    title = title || 'Ошибка';

    // ошибка парсится из типового ответа сервера
    if (message && typeof message === "object") {
      if (message.response) {
        message = message.response;
      }
      if (message.data) {
        message = message.data;
      }
      if (message.message) {
        message = message.message;
      }
      message = message;
    }
    message = message || 'Произошла ошибка.';

    // логируем в консоль и выводим на экран
    console.error(message);
    ModalService.show({
      component: ErrorModal,
      props: {
        title,
        message
      },
      settings: {
        adaptive: true,
        width: 580,
        height: "auto",
        clickToClose: false
      }
    });
  }
}
