import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис настроек СберID.
 */
export class SblSberIdSettingsService {

  static get ApiService() { return "SblSberIdSettingsService.svc" }

  static get BaseUrl() { return "../0/ServiceModel/" }

  /**
   * Получение значения системной настройки 
   * "Включить авторизацию на портале через Сбер ID".
   */
  static getSblEnableAuthorizationOnPortalViaSberID() {
    return ApiConnection.get(this.ApiService + '/getSblEnableAuthorizationOnPortalViaSberID', { baseURL: this.BaseUrl });
  }
}