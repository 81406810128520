import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для взаимодействия с Сбер ID.
 */
export class SblSberIdService {

  static get ApiService() { return "SblSberIdService.svc" }

  static get BaseUrl() { return window.location.protocol + "//" + window.location.host + "/0/ServiceModel/" }

  /**
   * Генерирует URL входа.
   */
  static generateLoginUrl() {
	const hostUrl = window.location.protocol + "//" + window.location.host + "/";
	
    return ApiConnection.post(
		this.ApiService + '/GenerateLoginUrl', {
			requestType: "SblAuthorizationSBID",
			//redirectUri: hostUrl + "/Authorization/sign-in"
			redirectUri: hostUrl + "0/ServiceModel/SblSberIdService.svc/RedirectFromSberId"
		}, {
			baseURL: this.BaseUrl
		}
	);
  }
  
  /**
   * Обрабатывает данные от Сбер ID.
   */
  static processSberIdUserInfo(state, code) {
	const hostUrl = window.location.protocol + "//" + window.location.host + "/";
	
    return ApiConnection.post(
		this.ApiService + '/ProcessSberIdUserInfo', {
			state, code
		}, {
			baseURL: this.BaseUrl
		}
	);
  }
}