<template>
  <div id="app">
    <BasePage>
      <router-view />
    </BasePage>
    <modals-container />
    <CookieNotice />
  </div>
</template>

<script>
import BasePage from "./components/BasePage";
import CookieNotice from "./components/CookieNotice";
import ModalService from "@/services/ModalService.js";

export default {
  name: "App",
  components: {
    BasePage,
    CookieNotice
  },

  beforeMount() {
    // инициализация модалки
    ModalService.initialize(this.$modal);
  }
};
</script>

<style lang="scss">
@import "@/styles/main.scss";

#app {
  display: flex;
}

</style>
