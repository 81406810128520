<template>
  <BaseModal @close="$emit('close')">
    <template v-slot:content>
      <div class="sbl-inn-header">Укажите ИНН компании, в которой вы зарегистрированы</div>
      <div class="confirm-sms" v-if="!isLoading">
        <div class="sbl-inn-control" id="passwordRecoveryConfirmationForm">
          <div class="sbl-form-control-container">
            <input
              autocomplete="off"
              class="sbl-form-control"
              name="login"
              type="hidden"
              v-model="login"
            />
            <input
              required
              autocomplete="off"
              class="sbl-form-control sbl-inn__inn-code"
              name="inn"
              type="text"
              v-mask="'############'"
              v-model="inn"
            />
            <span class="floating-placeholder">ИНН *</span>
          </div>
          <span v-if="errorMessage" class="sbl-form-control-error">{{errorMessage}}</span>
        </div>
        <div class="sbl-inn__buttons-container">
          <RoundedButton
            caption="Подтвердить"
            additionalClasses="sbl-green-button sbl-medium-button"
            :disabled="!inn || !inn.length"
            @click="onConfirmButtonClicked()"
          />
          <RoundedButton
            caption="Отмена"
            additionalClasses="sbl-white-button sbl-medium-button"
            @click="close"
          />
        </div>
      </div>
      <Spinner v-if="isLoading"></Spinner>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import ModalService from "@/services/ModalService.js";
import ErrorModal from "../components/ErrorModal";
import { mask } from "vue-the-mask";

/**
 * Модальное окно ввода кода подтверждения.
 */
export default {
  name: "ConfirmInnModal",
  directives: {
    mask
  },
  props: {
    /**
     * Логин пользователя.
     */
    login: {
      type: String,
      required: true
    },
    /**
     * Ответ от модалки.
     */
    promise: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /**
       * Код подтверждения.
       */
      inn: null,
      /**
       * Сообщение об ошибке.
       */
      errorMessage: null,
      /**
       * Идет ли какая-либо загрузка.
       */
      isLoading: false
    };
  },
  components: {
    RoundedButton,
    BaseModal
  },
  methods: {
    close() {
      this.$emit("close");
    },

    /**
     * Обработчик события нажатия на кнопку "Подтвердить".
     */
    onConfirmButtonClicked() {
      let me = this;

      let data = {
        login: this.login,
        inn: this.inn
      };

      me.isLoading = true;
      SblUserManagementService.recoverPasswordWithInn(data)
        .then(response => {
          console.log(response);
          if (!response || !response.data) {
            throw "Сервис работы с аккаунтами пользователя вернул пустой ответ на запрос подтверждения восстановления пароля.";
          }

          // Если все успешно, закрываем модалку и отдаем ответ наружу
          if (response.data.IsSuccess) {
            me.promise.resolve(me.inn);
            return me.close();
          }

          // Отдаем ответ обратно в главную страницу, а модалку закрываем
          me.promise.reject(response.data.Error, response.data);
          me.close();
        })
        .catch(error => {
          me.promise.reject(error);
          me.close();
        })
        .finally(() => (me.isLoading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/elements.scss";
.sbl-inn-control {
    margin: 0 auto;
    padding-left: 40px;
    padding-right: 40px;
}
.sbl-inn-header {
    font-size: 24px;
    text-align: center;
    padding: 40px 40px 20px 40px;
}
.sbl-inn {
  &__inn-code {
    font-size: 25px !important;
    font-family: Arial regular;
  }

  &__buttons-container {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;

    & > *:not(:first-child) {
      margin-left: 5px;
      margin-right: 100px;
    }
    & > *:first-child {
      margin-left: 100px;
      margin-right: 5px;
    }

  }
}
</style>

