import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для реализации авторизации по токену.
 */
export class SblTokenAuthService {

    static get ApiService() { return "SblTokenAuthService.svc" }
    static get AuthApiService() { return "SblUserManagementServiceExt.svc" }
    static get BaseUrl() { return "../0/ServiceModel/" }

    /**
     * Авторизация по токену.
     * @param {String} token Токен. 
     */
    static loginWithToken(Token) {
        const headers = {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Cookie",
            "ForceUseSession": "true"
        };

        return ApiConnection.post(this.ApiService + '/Login',
            { Token }, { headers });
    }

    /**
     * Получение информации о пользователе.
     * @param {String} authCode код авторизации. 
     */
    static authToUserInfo(authCode)
    {
        return ApiConnection.post(this.AuthApiService + '/AuthToUserInfo',
            { authCode }, { baseURL: this.BaseUrl });
    }
}