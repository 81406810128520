import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис взаимодействия с авторизацией/регистрацией/подписанием.
 */
export class SblUserManagementService {

	static get ApiService() { return "SblUserManagementService.svc" }
	static get ApiServiceExt() { return "SblUserManagementServiceExt.svc" }

	static get BaseUrl() { return "../0/ServiceModel/" }

	/**
	 * Название метода получения случайной последовательности для подписи.
	 */
	static get GetSequenceForCertificateSignMethodName() { return "GetSequenceForCertificateSign" }


	static get GetParseClientCertificateMethodName() { return "ParseClientCertificate" }

	static get GetValidateCertificateOnLoginMethodName() { return "ValidateCertificateOnLogin" }

	/**
	 * Парсинг сертификата на беке .
	 */
	static parseCertificate(certData) {
		return ApiConnection.post(this.ApiServiceExt + '/' + this.GetParseClientCertificateMethodName,
			{ certificateBase64String: certData },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * 
	 * @param {any} request Информация по сертификату.
	 * @param {String} dataToSend.certificateThumbprint "Отпечаток" сертификата. 
	 */
	static validateCertificateOnLogin(request) {
		return ApiConnection.post(this.ApiServiceExt + '/' + this.GetValidateCertificateOnLoginMethodName,
			{ request },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение пользовательского соглашения .
	 */
	static getUserAgreement() {
		return ApiConnection.post(this.ApiService + '/GetUserAgreement',
			{},
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение инструкции пользователя .
	 */
	static getUserManual() {
		return ApiConnection.get(this.ApiService + '/GetUserManual', { baseURL: this.BaseUrl });
	}

	/**
	 * Регистрация по сертификату.
	 * @param {any} dataToSend Информация по сертификату.
	 * @param {any} dataToSend.token Информация о пользователе. 
	 * @param {String} dataToSend.certificateBase64Content Содержимое сертификата в формате Base64.
	 * @param {String} dataToSend.certificateThumbprint "Отпечаток" сертификата. 
	 */
	static registerWithCertificate(dataToSend) {
		return ApiConnection.post(this.ApiServiceExt + '/InitRegistrationByEDM',
			dataToSend,
			{
			baseURL: this.BaseUrl
			}
		);
	}

	/**
	 * Регистрация по токену.
	 * @param {any} token Информация о пользователе. 
	 */
	static registerWithToken(token) {
		return ApiConnection.post(this.ApiService + '/RegisterWithToken',
			token,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Регистрация.
	 * @param {any} token Информация о пользователе. 
	 */
	static register(token) {
		return ApiConnection.post(this.ApiService + '/Register',
			token,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Восстановление пароля.
	 * @param {any} param0 Информация по пользователю.
	 * @param {String} param0.login Имя пользователя.
	 * @param {String} param0.captcha Токет каптчи.
	 */
	static recoverPassword({ login, captcha }) {
		return ApiConnection.post(this.ApiService + '/RecoverPassword',
			{ login, captcha },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Восстановление пароля.
	 * @param {any} param0 Информация по пользователю.
	 * @param {String} param0.login Имя пользователя.
	 * @param {String} param0.inn Инн.
	 */
	static recoverPasswordWithInn({ login, inn }) {
		return ApiConnection.post(this.ApiService + '/RecoverPasswordWithInn',
			{ login, inn },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * 
	 * @param {any} param0 Информация о пользователе.
	 * @param {String} param0.login Имя пользователя.
	 * @param {String} param0.confirmationCode Код подтверждения.
	 */
	static confirmPasswordRecovery({ login, confirmationCode }) {
		return ApiConnection.post(this.ApiService + '/ConfirmPasswordRecovery',
			{ login, confirmationCode },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * 
	 * @param {any} param0 Информация о пользователе.
	 * @param {String} param0.login Имя пользователя.
	 * @param {String} param0.confirmationCode Код подтверждения.
	 * @param {String} param0.inn Инн.
	 */
	static confirmPasswordRecoveryWithInn({ login, confirmationCode, inn }) {
		return ApiConnection.post(this.ApiService + '/ConfirmPasswordRecoveryWithInn',
			{ login, confirmationCode, inn },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * 
	 * @param {any} param0 Информация о пользователе.
	 * @param {String} param0.login Имя пользователя.
	 * @param {String} param0.confirmationCode Код подтверждения.
	 */
	static confirmPasswordChange({ login, confirmationCode, password, inn }) {
		return ApiConnection.post(this.ApiServiceExt + '/ConfirmPasswordChange',
			{ login, confirmationCode, password, inn },
			{ baseURL: this.BaseUrl }
		);
	}


	/**
	 * Получение ключа для рекапчи.
	 */
	static getSmartCaptchaSiteKey() {
		return ApiConnection.post(this.ApiService + '/GetYandexCaptchaSiteKey',
			{},
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение случайной последовательности для подписи сертификата.
	 * @param {String} thumbprint "Отпечаток" сертификата.
	 */
	static getSequenceForCertificateSign(thumbprint) {
		return ApiConnection.post(this.ApiService + '/' + this.GetSequenceForCertificateSignMethodName,
			{ thumbprint },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Запрос валидации сертификата.
	 * @param {String} base64Certificate Содержимое сертификата в base64.
	 */
	static doCertificateValidationRequest(base64Certificate) {
		return ApiConnection.post(this.ApiService + '/DoCertificateValidationRequest',
			base64Certificate,
			{ baseURL: this.BaseUrl }
		);
	}


	/**
	 * Запрос логина сертификата по отпечатку.
	 * @param {String} base64Certificate Содержимое сертификата в base64.
	 */
	static getLoginByThumbprint(thumbprint) {
		return ApiConnection.post(this.ApiServiceExt + '/GetLoginByThumbprint',
			thumbprint,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение дополнительной информации из DaData.
	 * @param {*} param0 Параметры, по которым осуществляется поиск в DaData.
	 * @param {*} param0.inn ИНН.
	 * @param {*} param0.organizationName Название организации.
	 * @param {*} param0.certificateSurName Фамилия.
	 * @param {*} param0.certificateFirstAndMiddleName Имя и отчество.
	 */
	static getAdditionalInfoByDaData({ inn, organizationName, certificateSurName, certificateFirstAndMiddleName }) {
		return ApiConnection.post(this.ApiService + '/GetAdditionalInfoByDaData',
			{ inn, organizationName, certificateSurName, certificateFirstAndMiddleName },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Смена пароля.
	 * @param {*} param0 Информация о пользователе.
	 * @param {*} param0.Login Логин пользователя.
	 * @param {*} param0.Password Текущий пароль.
	 * @param {*} param0.NewPassword Новый пароль.
	 * @param {*} param0.YSmartCaptchaResponse Капча.
	 * @param {*} param0.HasTempPassword Используется временный пароль.
	 */
	static changePassword({ Login, Password, NewPassword, YSmartCaptchaResponse, HasTempPassword}) {
		return ApiConnection.post(this.ApiServiceExt + '/ChangePassword',
			{ Login, Password, NewPassword, YSmartCaptchaResponse, HasTempPassword },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Смена пароля с подтверждением по СМС.
	 * @param {*} param0 Информация о пользователе.
	 * @param {*} param0.Login Логин пользователя.
	 * @param {*} param0.Password Текущий пароль.
	 * @param {*} param0.NewPassword Новый пароль.
	 * @param {*} param0.YSmartCaptchaResponse Капча.
	 */
	static ChangePasswordWithSMS({ Login, Password, NewPassword, YSmartCaptchaResponse, Inn }) {
		return ApiConnection.post(this.ApiServiceExt + '/ChangePasswordWithSMS',
			{ Login, Password, NewPassword, YSmartCaptchaResponse, Inn },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение причины деактивации.
	 * @param {*} param0 Информация о пользователе.
	 * @param {*} param0.Login Логин пользователя.
	 */
	static getDeactivationReason(login) {
		return ApiConnection.post(this.ApiService + '/GetDeactivationReason',
			login,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Создать запрос в JIRA на регистрацию по данным из токена.
	 * @param {any} token Информация о пользователе. 
	 */
	static generateJiraForRegistration(token) {
		return ApiConnection.post(this.ApiService + '/GenerateJiraRequestForRegistration',
			token,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Запрос авторизации.
	 * @param {any} authData Авторизационные данные, необходимые для входа.
	 */
	static loginRequest(authData) {
		return ApiConnection.post(this.ApiServiceExt + '/Login',
			authData,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Запрос авторизации.
	 * @param {any} authData Авторизационные данные, необходимые для входа.
	 */
	static defaultLoginRequest(authData) {
		return ApiConnection.post(this.ApiServiceExt + '/LoginWithOutSms',
			authData,
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение значения фичи SblNewClientRegistrationWithoutSMS.
	 * @param {any} token Информация о пользователе. 
	 */
	static checkIsSmsFeatureEnabled() {
		return ApiConnection.get(this.ApiServiceExt + '/GetSblNewClientRegistrationWithoutSMS',
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение значения фичи SblPortalEdoSettingsIconEnable.
	 * @param {any} token Информация о пользователе. 
	 */
	static checkIsSblPortalEdoSettingsIconEnable() {
		return ApiConnection.get(this.ApiServiceExt + '/GetSblPortalEdoSettingsIconEnable',
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Процесс аутентификации клиента СберБизнеса.
	 * @param {String} authCode код авторизации. 
	 */
	static sberBusinessAuthProcess(authCode)
	{
		return ApiConnection.post(this.ApiServiceExt + '/SberBusinessAuthProcess',
			{ authCode }, { baseURL: this.BaseUrl }
		);
	}

	/**
	 * опытка привязать сертификат к существующему пользователю.
	 * @param {any} request Запрос. 
	 */
	static TryApplyCertToExistingUser(request)
	{
		return ApiConnection.post(this.ApiServiceExt + '/TryApplyCertToExistingUser',
			{ request },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получение значения системной настройки
	 * Включить логику восстановления пароля для мультикабинета.
	 */
	static checkSblPortalPasswordRecoveryForFewlLoginEnable() {
		return ApiConnection.get(this.ApiService + '/GetSblPortalPasswordRecoveryForFewlLoginEnable', { baseURL: this.BaseUrl });
	}

	/**
	 * Получение значения системной настройки
	 * Отключить функцию отправки временного пароля при сбросе пароля.
	 */
	static checkSblPortalTempPasswordDisable() {
		return ApiConnection.get(this.ApiService + '/GetSblPortalTempPasswordDisable', { baseURL: this.BaseUrl });
	}

	/**
	 * Получить время до разблокировки пользователя.
	 * @param {*} userName Имя пользователя. 
	 * @returns 
	 */
	static getUserTimeToUnlock(userName) {
		return ApiConnection.post(this.ApiService + '/GetUserTimeBeforeUnlock', { userName: userName }, { baseURL: this.BaseUrl });
	}

	/**
	 * Получение значения системной настройки
	 * Включить объединенную логику авторизации и регистрации по сертификату на портале.
	 */
	static checkPortalCertificateRegistrationCombineLogicEnable() {
		return ApiConnection.get(this.ApiService + '/SblCheckPortalCertificateRegistrationCombineLogicEnable', { baseURL: this.BaseUrl });
	}

	/**
	 * Проверка токена подписания на его актуальность.
	 * @param {any} tokenId Id токена.
	 */
	static checkIsTokenCorrect(tokenId) {
		return ApiConnection.post(this.ApiServiceExt + '/CheckIsTokenCorrect',
			{ tokenId },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Проверка телефона для подписания.
	 * @param {any} tokenId Id токена.
	 * @param {String} phoneNumber Номер телефона.
	 */
	static validatePhoneNumber(tokenId, phoneNumber) {
		return ApiConnection.post(this.ApiServiceExt + '/ValidatePhoneNumber',
			{ tokenId, phoneNumber },
			{ baseURL: this.BaseUrl }
		);
	}
	
	/**
	 * Получение информации из токена подписания согласий.
	 * @param {any} tokenId Id токена.
	 * @param {String} code СМС код подтверждения.
	 */
	static getSigningTokenInfo(tokenId, code) {
		return ApiConnection.post(this.ApiServiceExt + '/GetTokenInfo',
			{ tokenId, code },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Создать одноразовый пароль подписания.
	 * @param {any} tokenId Id токена.
	 */
	static createPepCode(tokenId) {
		return ApiConnection.post(this.ApiServiceExt + '/CreatePepCode',
			{ tokenId },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Создать одноразовый пароль подписания.
	 * @param {any} codeId Id кода подтвеждения
	 * @param {any} code Код подтверждения.
	 */
	static confirmPepCode(codeId, code) {
		return ApiConnection.post(this.ApiServiceExt + '/ConfirmPepCode',
			{ codeId, code },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получить драфт печатной формы документа на предпросмотр.
	 * @param {any} tokenId Токен.
	 * @param {String} typeCode Код выбранного документа.
	 */
	static getDocumentPrintFormDraft(tokenId, typeCode) {
		return ApiConnection.post(this.ApiServiceExt + '/GetDocumentPrintFormDraft',
			{ tokenId, typeCode },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Запустить ПЭП подписание.
	 * @param {*} param0 Информация о токене.
	 * @param {*} param0.tokenId Токен.
	 * @param {*} param0.isBkiFl Чекбокс "БКИ ФЛ".
	 * @param {*} param0.isBkiUl Чекбокс "БКИ ЮЛ".
	 * @param {*} param0.isConsentEdo Чекбокс "Согласие ЭДО".
	 * @param {*} param0.isConsentToTransferBt Чекбокс "Согласие на передачу БТ".
	 * @param {*} param0.isSopd Чекбокс "СОПД".
	 * @param {*} param0.isPepRules Чекбокс "Правила ПЭП".
	 */
	static startSigning(tokenInfo) {
		return ApiConnection.post(this.ApiServiceExt + '/StartSigning',
			{ tokenInfo },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Отменить подписание.
	 * @param {any} tokenId Id токена.
	 */
	static cancelSigning(tokenId) {
		return ApiConnection.post(this.ApiServiceExt + '/CancelSigning',
			{ tokenId },
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получить значение системной настройки "URL правил использования ПЭП".
	 */
	static getSblUsingPEPRulesURL() {
		return ApiConnection.get(this.ApiServiceExt + '/GetSblUsingPEPRulesURL',
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получить значение системной настройки "URL правил на обработку персональных данных".
	 */
	static getSblPersonalDataRulesURL() {
		return ApiConnection.get(this.ApiServiceExt + '/GetSblPersonalDataRulesURL',
			{ baseURL: this.BaseUrl }
		);
	}

	/**
	 * Получить значение системной настройки "Выключить восстановление пароля из сертификата".
	 */
	static getSblDisablePasswordRecoveryFromCertificate() {
		return ApiConnection.get(this.ApiServiceExt + '/GetSblDisablePasswordRecoveryFromCertificate',
			{ baseURL: this.BaseUrl }
		);
	}
}
