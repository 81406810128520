import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для авторизации в системе по Сбер ID.
 */
export class SblSberIdAuthorizeService {

  static get ApiService() { return "SblSberIdAuthorizeService.svc" }

  static get BaseUrl() { return window.location.protocol + "//" + window.location.host + "/ServiceModel/" }

  /**
   * Авторизует пользователя в системе.
   */
  static authorizeFoundedAccount(state, sysAdminUnitId) {
	const hostUrl = window.location.protocol + "//" + window.location.host + "/";
	
    return ApiConnection.post(
		this.ApiService + '/AuthorizeFoundedAccount', {
			state, sysAdminUnitId
		}, {
			baseURL: this.BaseUrl
		}
	);
  }
}