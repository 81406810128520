<template>
<div class="sign sbl-base-container">
	<div
		v-if="!isPortalCertificateRegistrationCombineLogicEnable"
		class="sign-switch">
		<button
			class="sbl-sign-button sbl-sign-button-login sbl-large-text"
			:disabled="siteLogin"
			@click="siteLogin = true"
		>Вход</button>
		<button	
			class="sbl-sign-button sbl-sign-button-register sbl-large-text"
			:disabled="!siteLogin"
			@click="siteLogin = false"
		>Регистрация</button>
	</div>

	<!-- Форма входа -->
	<div v-if="siteLogin" v-bind:class="getSiteLoginBodyClass()">

	<!-- Вход по СберБизнес ID -->
	<div class="sbl-form-group" v-show="isSberIdAuthEnabled">
		<div @mouseover="showSberIdAuthWarning = true" @mouseleave="showSberIdAuthWarning = false" class="auto-width">
		<RoundedButton
			caption=""
			additionalClasses="sbl-sberBusinnesId-button sbl-med-text sbl-sign-rounded-button"
			@click="onSberBusinessIdEnterButtonClicked"
		>
			<template v-slot:content>
				<div style="display: flex; align-items: center; justify-content: center">
					<img src="../assets/images/sber-id-logo.svg" style="width: 22px; height: 22px; margin-right: 8px">
					<span>Войти по СберБизнес ID</span>
				</div>
			</template>
		</RoundedButton>
		<!--<div class="sign-in__sber-id-warning-message" v-show="showSberIdAuthWarning && !isSberIdAuthEnabled"> Будет доступно в ближайшее время </div>-->
		</div>
	</div>
	
	<!-- Вход по Сбер ID -->
	<div class="sbl-form-group" v-show="isSblAuthorizationOnPortalViaSberIDEnabled">
		<div class="auto-width">
			<RoundedButton
				additionalClasses="sbl-sberId-button sbl-med-text sbl-sign-rounded-button"
				@click="onSberIdButtonClick"
			>	
				<template v-slot:content>
					<div style="display: flex; align-items: center; justify-content: center">
						<img src="../assets/images/sber-id-logo.svg" style="width: 22px; height: 22px; margin-right: 8px">
						<span>Войти по Сбер ID</span>
					</div>
				</template>
			</RoundedButton>
		</div>
	</div>

	<!-- Вход по эл. подписи -->
	<div class="sbl-form-group" v-show="isLoginByCertificateEnabled">
		<div class="auto-width">
		<RoundedButton
			caption="Войти по электронной подписи"
			additionalClasses="sbl-login-by-certificate-button sbl-med-text sbl-sign-rounded-button"
			@click="loginByCertificate"
		/>
		</div>
	</div>
	

	<div class="sbl-form-group" v-show="isLoginByCertificateEnabled">
		<a class="sign-in-or self-align-center sbl-large-text">или</a>
	</div>

	<!-- Логин -->
	<div class="sbl-form-group">
		<div class="sbl-form-control-container">
        <span class="floating-error" v-if="isLoginNotValid" v-text="loginNotValidHelpText"/>
		<input
			class="sbl-form-control"
			type="text"
			v-model="login"
			v-on:keyup.enter="onEnterButtonClicked"
			v-on:keyup="isSignInButtonEnabled"
			required
		/>
		<span class="floating-placeholder sbl-med-text">Электронная почта</span>
		</div>
	</div>

	<!-- Пароль -->
	<div class="sbl-form-group">
		<div class="sbl-form-control-container">
		<input
			class="sbl-form-control sbl-form-control-password"
			:type="passwordFieldType"
			v-model="password"
			v-on:keyup.enter="onEnterButtonClicked"
			v-on:keyup="isSignInButtonEnabled"
			required
		/>
		<span class="floating-placeholder sbl-med-text">Пароль</span>

		<a v-if="password==''" class="password-actions password-actions-not-active"/>
		<a @click="switchOnPasswordVisibility" v-if="passwordFieldType=='password' && password!=''" class="password-actions password-actions-show"/>
		<a @click="switchOffPasswordVisibility" v-if="passwordFieldType=='text' && password!=''" class="password-actions password-actions-hide"/>

		</div>
	</div>


	<!-- Капча -->
	<div id="yandexSmartCaptcha" class = "sbl-form-group" v-show="isSmartCaptchaVisible"></div>

	<!-- Войти -->
	<div class="sbl-form-group">
		<div class="sign-in-enter">
		<RoundedButton
			caption="Войти"
			additionalClasses="sbl-green-button sbl-med-text sbl-sign-rounded-button"
			:disabled="!IsSignInButtonEnabled"
			@click="onEnterButtonClicked"
		/>
		</div>
	</div>

	<!-- Восстановление пароля -->
	<div class="sbl-form-group">
		<router-link
		class="sign-in-remember sbl-link auto-width self-align-center sbl-small-text"
		:to="{name: 'PasswordRecovery'}"
		>Забыли пароль?</router-link>
	</div>

	<!-- Инструкция -->
	<div class="sbl-form-group"
		v-if="isPortalCertificateRegistrationCombineLogicEnable">
		<div class="sign-in-signUp self-align-center sbl-small-text">
			<a @click="goToSignInstruction">
				<router-link 
					to="/" 
					class="sbl-link sbl-link-spacing"
					>Инструкция по регистрации</router-link>
			</a>
		</div>
	</div>

	<!-- Регистрация -->
	<div class="sbl-form-group"	
		v-if="!isPortalCertificateRegistrationCombineLogicEnable">
		<div class="sign-in-signUp self-align-center sbl-small-text">
		<a>Новый пользователь?</a>
		<a @click="goToRegister">
			<router-link to="/" class="sbl-link sbl-link-spacing">Регистрация</router-link>
		</a>
		</div>
	</div>

	<!-- Проверка КриптоПро -->
	<div class="sbl-form-group sbl-items-group-last-row">
		<div class="self-align-center sbl-small-text">
			<a @click="goToCheckCryptoProPlugin">
				<router-link to="/" class="sbl-link sbl-link-spacing">Проверить работу КриптоПро ЭЦП плагина</router-link>
			</a>
		</div>
	</div>

	</div>

	<!-- Форма регистрации -->
	<div v-if="!siteLogin" class="sbl-card sign-up-body">

	<!-- Выберите способ -->
	<div class="sbl-form-group sign-up-choose-caption">
		<a class="sign-up-register-method self-align-center sbl-h2-text">Выберите способ регистрации</a>
	</div>
	
	<!-- Регистрация по СберID -->
	<div class="sbl-form-group" v-show="isSberIdAuthEnabled">
		<div @mouseover="showSberIdAuthWarning = true" @mouseleave="showSberIdAuthWarning = false" class="auto-width">
		<RoundedButton
			caption=""
			additionalClasses="sbl-sberBusinnesId-button sbl-med-text sbl-sign-rounded-button"
			@click="onSberBusinessIdEnterButtonClicked"
		>
			<template v-slot:content>
				<div style="display: flex; align-items: center; justify-content: center">
					<img src="../assets/images/sber-id-logo.svg" style="width: 22px; height: 22px; margin-right: 8px">
					<span>По СберБизнес ID</span>
				</div>
			</template>
		</RoundedButton>
		</div>
	</div>

	<!-- Регистрация по эл. подписи -->
	<div class="sbl-form-group">
		<div class="auto-width">
		<RoundedButton
			caption="Войти по электронной подписи"
			additionalClasses="sbl-login-by-certificate-button sbl-med-text sbl-sign-rounded-button"
			@click="registerByCertificate"
		/>
		</div>
	</div>
	
	<!-- Уже зарегистрированны? -->
	<div class="sbl-form-group">
		<div class="sign-info self-align-center sbl-small-text">
			<a @click="goToSignInstruction">
				<router-link to="/" class="sbl-link sbl-link-spacing">Инструкция по регистрации</router-link>
			</a>
		</div>
		<div class="sign-info self-align-center sbl-small-text">
		<a>Уже зарегистрированы?</a>
		<a @click="goToLogin">
			<router-link to="/" class="sbl-link sbl-link-spacing">Выполните вход</router-link>
		</a>
		</div>
	</div>
	
	<!-- Проверка КриптоПро -->
	<div class="sbl-form-group sbl-items-group-last-row">
		<div class="self-align-center sbl-small-text">
			<a @click="goToCheckCryptoProPlugin">
				<router-link to="/" class="sbl-link sbl-link-spacing">Проверить работу КриптоПро ЭЦП плагина</router-link>
			</a>
		</div>
	</div>
	</div>

	<Spinner v-if="isLoading"></Spinner>

	<div class="sign-in-warning" v-show="isAlertShown">
		<p>{{alertText}}</p>
	</div>
</div>
</template>

<script>
import CertificateModal from "@/components/CertificateModal";
import RoundedButton from "@/components/RoundedButton";
import AuthService from "@/services/AuthenticationService";
import MessageBox from "@/services/MessageBox";
import ModalService from "@/services/ModalService.js";
import { SblSberBusinessIdService } from "../services/api/SblSberBusinessIdService.js";
import { SblSberIdSettingsService } from "../services/api/SblSberIdSettingsService.js";
import { SblSberIdService } from "../services/api/SblSberIdService.js";
import { SblSberIdAuthorizeService } from "../services/api/SblSberIdAuthorizeService.js";
import { Messages } from "../enums/Messages.js";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { EntranceTypes } from "../enums/EntranceTypes.js";
import InvalidUserNameOrPasswordModal from '../components/InvalidUserNameOrPasswordModal.vue';
import  UserBlockedModal from '../components/UserBlockedModal.vue';
import { AuthenticationErrorCodes } from '../enums/AuthenticationErrorCodes';
import AuthenticationService from '../services/AuthenticationService.js';
import SmsConfirmationModal from "../components/SmsConfirmationModal";
import SmsConfirmModalService from "@/services/SmsConfirmModalService.js"
import { DefaultCaptions } from "../enums/DefaultCaptions.js"

export default {
name: "SignIn",
props:{
	IsSignUp: Boolean
},
data: function() {
	return {
	isLoading: false,

	passwordFieldType: "password",

	/**
	 * Переключатель Вход/Регистрация
	 */
	siteLogin: !this.IsSignUp,

	/**
	 * Логин.
	 */
	login: "",

	/**
	 * Поле логин не корректно.
	 */
	isLoginNotValid: false,

	/**
	 * Сообщение при несоблюдении формата логина.
	 */
	loginNotValidHelpText: "Неверный формат электронной почты",

	/**
	 * Пароль.
	 */
	password: "",
	/**
	 * Сообщения об ошибках, отображаемые под паролем.
	 */
	error: "",
	/**
	 * Авторизация по логин и паролю.
	 */
	loginByPassword: true,

	/**
	 * Авторизация по SberId.
	 */
	isSberIdAuthEnabled: false,

	/**
	 * Значение системной настройки "Включить авторизацию на портале через Сбер ID".
	 */
	isSblAuthorizationOnPortalViaSberIDEnabled: false,

	/**
	 * Отображение предупреждения о невозможности входа по SberId.
	 */
	showSberIdAuthWarning: false,

	/**
	 * Доступна ли кнопка "Войти".
	 */
	IsSignInButtonEnabled: false,

	/**
	 * Отображение кнопки входа по УКЭП.
	 */
	isLoginByCertificateEnabled: false,

	/**
	 * Отображение предупреждения внизу страницы.
	 */
	isAlertShown: false,

	/**
	 * Отображение капчи на странице.
	 */
	isSmartCaptchaVisible: false,

	/**
	 * Текст предупреждения внизу страницы.
	 */
	alertText: "",

	/**
	 * URL инструкции по регистрации.
	 */
	signInstructionUrl: 'https://www.sberleasing.ru/upload/e-leasing/lk-sbl-edo.pdf',

	/**
	 * URL проверки работы КриптоПро ЭЦП плагина.
	 */
	cryptoProPluginCheckUrl: 'https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html',
	
	/**
	 * Идентификатор отрисованной капчи.
	 */
	captchaWidgetId: 0,

	/**
	 * Значение системной настройки "Включить объединенную логику авторизации и регистрации по сертификату на портале".
	 */
	isPortalCertificateRegistrationCombineLogicEnable: false,

	};
},
components: {
	RoundedButton,
	SmsConfirmationModal,
	CertificateModal
},
computed: {},
methods: {
	/**
	 * Доступна ли кнопка "Войти".
	 */
	isSignInButtonEnabled() {
		this.IsSignInButtonEnabled = this.validateLogin(this.login) && Boolean(this.password);
		return this.IsSignInButtonEnabled;
	},


    /**
     * Валидация логина.
     * @param {String} login Проверяемый логин.
     * @returns Boolean True, если валидация пройдена успешно.
     * @private
     */
    validateLogin(login) {
		login = login.trim();
		this.login = login;
		var re = /^[a-zA-Z0-9]+(?:[\.a-zA-Z0-9!#$%&'*+/=?^_`{|}~-])*(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z-]*[a-zA-Z])?$/;
		var validationResult = re.test(String(login).toLowerCase());
		this.isLoginNotValid = !validationResult  && Boolean(login);
		return validationResult;
    },

	/**
	 * Мусор из переключателей
	 */
	goToLogin() {
		this.siteLogin = true;
	},

	goToCheckCryptoProPlugin() {
		window.open(this.cryptoProPluginCheckUrl, '_blank').focus();
	},

	goToSignInstruction() {
		window.open(this.signInstructionUrl, '_blank').focus();
	},

	goToRegister() {
		this.siteLogin = false;
	},

	switchOnPasswordVisibility() {
		this.passwordFieldType = "text";
	},
	switchOffPasswordVisibility() {
		this.passwordFieldType = "password";
	},

	/**
	 * Обработчик события нажатия на кнопку "По электронной подписи".
	 */
	registerByCertificate() {
		ModalService.show({
			component: CertificateModal,
			props: {
				authType: EntranceTypes.SIGN_UP,
				cryptoProPluginCheckUrl: this.cryptoProPluginCheckUrl
			},
			settings: {
				height: "auto",
				width: "600px"
			}
		});
	},

	/**
	 * Обработчик клика по кнопке авторизации с помощью ЭПЦ
	 */
	loginByCertificate() {
		ModalService.show({
			component: CertificateModal,
			props: {
				authType: EntranceTypes.SIGN_IN,
				cryptoProPluginCheckUrl: this.cryptoProPluginCheckUrl
			},
			settings: {
				height: "auto",
				width: "600px"
			}
		});
	},

	/**
	 * Обработчик события нажатия на кнопку "Войти".
	 */
	onEnterButtonClicked() {
		this.callBaseAuth();
	},

	/**
	 * Вызвать базовую авторизацию по логину/паролю.
	 * @param {any} accountId Идентификатор контрагента.
	 */
	callBaseAuth(accountId) {
		if (!this.IsSignInButtonEnabled) {
			console.warn(
				"Не заполнен логин/пароль или существуют ошибки входа в систему."
			);
			return;
		}

		let data = {
			login: this.login,
			password: this.password
		};

		if (accountId) {
			data.accountId = accountId;
		}

		if (this.isSmartCaptchaVisible && !accountId) {
			this.smartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);

			if (!this.smartCaptchaResponse) {
				this.showErrorMessage(Messages.SblIamNotRobot);
				return;
			}
			data.captcha = window.smartCaptcha.getResponse(this.captchaWidgetId);
			window.smartCaptcha.reset(this.captchaWidgetId);
		}
		this.isLoading = true;
		
		SblUserManagementService.loginRequest(data)
			.then(response => {
				this.isLoading = false;

				let authData = {
					UserName: data.login,
					UserPassword: data.password
				}

				let loginResult = response.data;

				if (loginResult.NeedCaptcha) {
					this.isSmartCaptchaVisible = true;
				}

				if (loginResult.Success && loginResult.foundedAccounts) {
					this.processFoundedAccountsFromBaseAuth(loginResult.foundedAccounts);
					return;
				}
				if (loginResult.Success && !loginResult.NeedSmsAuth) {
					let responseData = JSON.parse(response.data.ResponseText);
					AuthService.handleAuthResponse(authData, responseData, this.showErrorMessage);
					return;
				}

				if (!loginResult.Success) {
					if (loginResult.ResponseText) {
						let response = JSON.parse(loginResult.ResponseText);

						if (response?.Code === AuthenticationErrorCodes.InvalidCredentials
							|| response?.Code === AuthenticationErrorCodes.SmsAuthenticationInvalidCredentials) {
							this.showAuthorizationErrorModal();
							
							return;
						}
						
						if (response?.Code === AuthenticationErrorCodes.UserBlockedForTime) {
							let blockDuration = response?.BlockDuration;
							this.showUserBlockedModal(blockDuration);
							return;
						}
					}

					this.showErrorMessage(loginResult.ErrorMessage);
					return;
				}
				if (loginResult.NeedSmsAuth) {
					// Если все успешно, открываем модалку подтверждения по смс коду.
					ModalService.show({
						component: SmsConfirmationModal,
						props: {
							modalData: data,
							confirmButtonCaption: DefaultCaptions.EnterButtonCaption,
							confirmButtonFunc: SmsConfirmModalService.confirmLoginSmsCode,
							resendCodeFunc: SmsConfirmModalService.resendLoginSmsCode
						},
						settings: {
							width:"560px"
						}
					})
					.then((responseText) => {
						// Если все прошло хорошо, закрываем модалку и выполняем вход 
						let responseData = JSON.parse(responseText);
						AuthService.handleAuthResponse(authData, responseData, this.showErrorMessage);
					})
					.catch(() => {
						this.showErrorMessage(Messages.SblSmsModalCommonError);
					});
				}
			})
			.catch(error => {
				this.isLoading = false;

				console.log(error);
				this.showErrorMessage(error);
			});
	},

	/**
	 * Обработчик события нажатия на кнопку "Войти по SberBusinessId"
	 */
	onSberBusinessIdEnterButtonClicked() {
		SblSberBusinessIdService.authorize()
			.then((response) => {
				let result = response && response.data?.InitPortalAuthResult;
				if (result && result.RedirectUri) {
					window.location.href = result.RedirectUri;
				} else {
					this.showErrorMessage(Messages.SblSmsModalCommonError);
				}
			})
			.catch((error) => {
				this.showErrorMessage(Messages.SblSmsModalCommonError);
			});
	},
	
	/**
	 * Обработчик нажатия на кнопку "Войти по Сбер ID".
	 */
	onSberIdButtonClick() {
		let scope = this;
	
		SblSberIdService.generateLoginUrl()
			.then(function(response) {
				var result = response?.data?.GenerateLoginUrlResult;
				if (result && !result.ErrorMessage?.Message && result.RedirectUri) {
					window.location.href = result.RedirectUri;
				} else {
					scope.showAuthorizationErrorModal();
				}
			})
			.catch(function(error) {
				scope.showAuthorizationErrorModal();
			});
	},

	/**
	 * Отображение сообщения об ошибке.
	 * @param {String} errorMessage Текст сообщения об ошибке.
	 * @private
	 */
	showErrorMessage(errorMessage) {
		if (!errorMessage) {
			return;
		}
		MessageBox.error(errorMessage);
	},

	/**
	 * Установка значения поля "Авторизация по SberId".
	 */
	setIsSberIdAuthEnabled() {

		SblSberBusinessIdService.getSblIsSberIdAuthEnabled()
			.catch(console.error)
			.then(function(response) {
				if (response && response.data) {
					this.isSberIdAuthEnabled = response.data;
				}
			}
			.bind(this))
	},

	/**
	 * Установка значения атрибута доступности входа по Сбер ID.
	 */
	setIsSblAuthorizationOnPortalViaSberIDEnabled() {
		SblSberIdSettingsService.getSblEnableAuthorizationOnPortalViaSberID()
			.catch(console.error)
			.then(function(response) {
				if (response && response.data) {
					this.isSblAuthorizationOnPortalViaSberIDEnabled = response.data;
				}
			}
			.bind(this))
	},

	/**
	 * Получает с бэка значение системной настройки и устанавливает соответствующий флаг.
	 */
	checkIsSignInAlertShown(){
		SblSberBusinessIdService.checkIsSignInAlertShown()
			.then(function(response) {
				if (response && response.data) {
					this.isAlertShown = response.data;
				}
			}.bind(this))
	},

	/**
	 * Получает с бэка значение системной настройки и устанавливает соответствующее
	 * текстовое значение.
	 */
	getSignInAlertText(){
		SblSberBusinessIdService.getSignInAlertText()
			.then(function(response) {
				if (response && response.data) {
					this.alertText = response.data;
				}
			}.bind(this))
	},

	/**
	 * Получает с бэка значение системной настройки и устанавливает соответствующий флаг.
	 */
	checkIsLoginByCertificateEnabled(){
		SblSberBusinessIdService.getSblIsLoginByCertificateEnabled()
			.then(function(response) {
				if (response && response.data) {
					this.isLoginByCertificateEnabled = response.data;
				}
			}.bind(this))
	},
	
	/**
	 * Проверяет параметры Сбер ID.
	 */
	checkSberIdParams() {
		const query = window.location.href?.split('?');
		query?.shift();
		let params = {};
		
		query.forEach(param => {
			params = {...params, ...Object.fromEntries(new URLSearchParams(param))}
		});

		const state = params?.state;
		const code = params?.code;
		
		if (state && code) {
			this.loginBySberId(state, code);
		}
	},
	
	/**
	 * Производит вход по Сбер ID.
	 */
	loginBySberId(state, code) {
		let scope = this;
	
		SblSberIdService.processSberIdUserInfo(state, code)
			.then(function(response) {
				var result = response?.data?.ProcessSberIdUserInfoResult;
				if (result && !result.ErrorMessage?.Message && result.FoundedAccounts?.length > 0) {
					scope.processFoundedAccounts(state, result.FoundedAccounts);
				} else {

					scope.showAuthorizationErrorModal();
				}
			})
			.catch(function(error) {
				scope.showAuthorizationErrorModal();
			});
	},

	/**
	 * Показать модальное окно ошибки авторизации.
	 */
	showAuthorizationErrorModal() {
		ModalService.show({
			component: InvalidUserNameOrPasswordModal,
			props: {
				signUpCallback: () => {
					this.goToRegister();
				}
			},
			settings: {
				adaptive: true,
				width: 580,
				height: "auto",
				clickToClose: false
			}
		});
	},

	/**
	 * Показать модальное окно блокировки пользователя.
	 */
	showUserBlockedModal(blockDuration) {
		ModalService.show({
			component: UserBlockedModal,
			props: {
				blockDuration: blockDuration
			},
			settings: {
				adaptive: true,
				width: 580,
				height: "auto",
				clickToClose: false
			}
		});
	},

	/**
	 * Обрабатывает найденных контрагентов при базовой авторизации.
	 * @param {any} foundedAccounts Найденные контрагенты.
	 */
	processFoundedAccountsFromBaseAuth: function(foundedAccounts) {
		AuthenticationService.openAccountSelectLookup(foundedAccounts, (account) => {
			sessionStorage.setItem("Inn", account.Inn);
			this.callBaseAuth(account.AccountId);
		}, this);
	},

	/**
	 * Обрабатывает найденных контрагентов.
	 */
	processFoundedAccounts: function(state, foundedAccounts) {
		if (foundedAccounts.length === 1) {
			this.authorizeFoundedAccount(state, foundedAccounts[0].SysAdminUnitId);
			return;
		}
		
		AuthenticationService.openAccountSelectLookup(foundedAccounts, (account) => {
			this.authorizeFoundedAccount(state, account.SysAdminUnitId);
			sessionStorage.setItem("Inn", account.Inn);
		}, this);
	},
	
	/**
	 * Входит под выбранным контрагентом.
	 */
	authorizeFoundedAccount(state, sysAdminUnitId) {
		let scope = this;
	
		SblSberIdAuthorizeService.authorizeFoundedAccount(state, sysAdminUnitId)
			.then(function(response) {
				var result = response?.data?.AuthorizeFoundedAccountResult;
				if (result && !result.ErrorMessage?.Message) {
					scope.redirectToMainPage();
				} else {
					scope.showAuthorizationErrorModal();
				}
			})
			.catch(function(error) {
				scope.showAuthorizationErrorModal();
			});
	},
	
	/**
	 * Перенаправляет на главную страницу портала.
	 */
	redirectToMainPage: function() {
		const hostUrl = window.location.protocol + "//" + window.location.host + "/";
		window.location.href = hostUrl + "0/Nui/ViewModule.aspx#";
	},
	
	/**
	 * Метод рендеринга капчи Яндекс.
	 */
  	onloadFunction() {
		let cachedSmartCaptchaKey = localStorage.getItem("SblSmartCaptchaSiteKey");

		if (window.smartCaptcha && cachedSmartCaptchaKey) {
			const container = document.getElementById('yandexSmartCaptcha');
			const widgetId = window.smartCaptcha.render(container, {
				sitekey: cachedSmartCaptchaKey,
				hl: 'ru',
			});
			
			this.captchaWidgetId = widgetId;
			return;
		}

		SblUserManagementService.getSmartCaptchaSiteKey()
			.then( (response) => {
				 
				let smartCaptchaSiteKey = response && response.data;

				if (!smartCaptchaSiteKey) {
					window.console.error(Messages.SblYandexCaptchaNoSiteKey);
					return;
				}
				const container = document.getElementById('yandexSmartCaptcha');
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: smartCaptchaSiteKey,
					hl: 'ru',
				});
				
				localStorage.setItem("SblSmartCaptchaSiteKey", smartCaptchaSiteKey);

			})
			.catch(function(error) {
				console.log(error);
			});
    },

	/**
	 * Добавить элемент капчи на страницу.
	 */
	setSmartCaptchaElement() {
		let captchaScript = document.createElement("script");
		captchaScript.setAttribute(
			"src",
			"https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction"
		);
		document.head.appendChild(captchaScript);
	},

	/**
	 * Проверить системную настройку "Включить объединенную логику авторизации и регистрации по сертификату на портале".
	 */
	setIsPortalCertificateRegistrationCombineLogicEnable() {
		SblUserManagementService.checkPortalCertificateRegistrationCombineLogicEnable()
			.then( (response) => {
				this.isPortalCertificateRegistrationCombineLogicEnable = response && response.data;
			})
			.catch(function(error) {
				console.log(error);
			});
	},

	/**
	 * Получаем класс для тела формы входа.
	 */
	getSiteLoginBodyClass() {
		let baseClasses = "sbl-card sign-in-body";
		return this.isPortalCertificateRegistrationCombineLogicEnable 
			? baseClasses + " rounded-all"
			: baseClasses;
	},
},

mounted() {
	AuthService.sblSiteSessionSave();
	AuthService.resetCookies();
	this.setIsSberIdAuthEnabled();
	this.setIsSblAuthorizationOnPortalViaSberIDEnabled();
	this.checkIsLoginByCertificateEnabled();
	this.checkIsSignInAlertShown();
	this.getSignInAlertText();
	this.checkSberIdParams();
	window.onloadFunction = this.onloadFunction;
	this.setSmartCaptchaElement();
	this.setIsPortalCertificateRegistrationCombineLogicEnable();
}
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.sbl-login-by-certificate-button {
	background-color: #ffffff;
	color: #212529;
	border: 1px solid #D8E1E8;

	&:not(:disabled):hover {
		background-color: #ffffff;
		background: linear-gradient(73.1deg, #21BA72 10.98%, #0087CD 87.65%);
		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
		border: 1px solid #D8E1E8;
	}

	&:not(:disabled):active {
		background-color: #EEFCFA;
		background: linear-gradient(73.1deg, #21BA72 10.98%, #0087CD 87.65%);
		-webkit-background-clip: text;
  		-webkit-text-fill-color: transparent;
		border: none;
	}

	&:disabled {
		background-color: #D8E1E8;
		color: #293C4D;
		border: none;
	}
}

.sbl-sign-rounded-button {
	height: 48px!important;
	padding: 8px;
	font-size: 14px;
	font-weight: 500;
}

.sign-up-register-method{
	text-align: center;
}

.sign-info {
	margin-top: 65px;
}

.sbl-base-container {
	background: #D8E1E8;
}
.sign {
	height: 450px;
	
	.sbl-sign-button:disabled {
		font-weight: bold;
		background: white;
		color: black;
		font-size: 20px;
	}

	.sbl-sign-button {
		color: $not-active-switch;
		background: none;
		width: 50%;
		height: 4em;
		border: none;
		font-family: "SBSansUI-Regular";
		line-height: 133%;

		&-login {
			border-radius: $border-rad $border-rad 0 0;
		}

		&-register {
			border-radius: $border-rad $border-rad 0 0;
		}
	}

	.sign-up {
		&-signIn {
			margin-top: 150px;
		}

		&-body {
			border-radius: 0 0 $border-rad $border-rad;
		}

		&-choose-caption {
			margin-top: 15%;
			margin-bottom: 5%;
		}
	}

	.sign-in {
		&-body {
			border-radius: 0 0 $border-rad $border-rad;
		}

		&__capcha {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.rounded-all {
		padding-top: 20px;
		border-radius: $border-rad $border-rad $border-rad $border-rad;
	}
}
.sign-in-warning {
	background: white;
	text-align: center;
	border-radius: 16px;
	padding: 10px;
    font-size: 15px;
    margin-top: 10px;
}
</style>
