<template>
  <div class="personal-data sbl-base-container">
      <div class="sbl-card">
        
        <!-- Caption #1 -->
        <div class="sbl-items-group sbl-h2-text">
          <a class="self-align-center">Данные о компании</a>
        </div>

        <!-- Наименование -->
        <div class="sbl-items-group" id="opfFormGoup">
          <div class="sbl-form-control-container" style="width: 100%;">
            <vue-autosuggest
              ref="autocomplete"
              v-model="companyName"
              @selected="onSelected"
              @input="fetchAccountSuggestionsByName"
              :suggestions="suggestions"
              :inputProps="inputProps"
              :getSuggestionValue="getSuggestionValue"
            >
              <template slot="after-input">
                <span 
                  id='floatPlaceholderLabel' 
                  class='floating-placeholder autosuggest-floating-placeholder'
                >
                  Наименование компании
                </span>
              </template>
              <template slot-scope="{suggestion}">
                <div class="sbl-suggestion-item">
                  {{suggestion.item.opf}} {{suggestion.item.name}}
                  <br />
                  <div style="font-size: 85%">
                    ИНН: {{suggestion.item.inn}} КПП: {{suggestion.item.kpp}}
                    <br />
                    {{suggestion.item.desc}}
                  </div>
                </div>
              </template>
            </vue-autosuggest>
          </div>
        </div>

        <!-- ИНН/КПП -->
        <div class="sbl-items-group-multiple" id="innFormGoup">
          <!-- ИНН -->
          <div class="sbl-items-group">
            <div class="sbl-form-control-container">
              <span class="floating-error" v-if="innNotValidated" v-text="innValidationText"/>
              <input
                required
                class="sbl-form-control"
                type="text"
                id="inn"
                v-model="inn"
                :disabled="innDisabled"
                @blur="validateInnField()"
              />
              <span class="floating-placeholder">ИНН *</span>
            </div>
          </div>

          <!-- Spacer -->
          <div class="sbl-items-group sbl-items-group-spacer"/>

          <!-- КПП -->
          <div class="sbl-items-group">
            <div class="sbl-form-control-container">
              <input 
                required
                class="sbl-form-control"
                type="text"
                id="kpp"
                v-model="kpp"
                :disabled="kppDisabled"
              />
              <span class="floating-placeholder">КПП</span>
            </div>
          </div>
        </div>


        <!-- Caption #2 -->
        <div class="sbl-items-group sbl-h2-text">
          <a class="self-align-center">Персональные данные</a>
        </div>
        
        <!-- Перс. данные -->
        <div class="sbl-items-group-multiple" id="personalFormGoup">
          <!-- Фамилия -->
          <div class="sbl-items-group" id="lastNameFormGoup">
            <div class="sbl-form-control-container">
              <input
                required
                class="sbl-form-control"
                id="lastName"
                type="text"
                v-model="surname"
                :disabled="surnameDisabled"
                @blur="checkContinueButtonAvailability()"
                @change="checkContinueButtonAvailability()"
                @keyup="checkContinueButtonAvailability()"
              />
              <span class="floating-placeholder">Фамилия *</span>
            </div>
          </div>

          <!-- Micro Spacer -->
          <div class="sbl-items-group sbl-items-group-micro-spacer"/>

          <!-- Имя -->
          <div class="sbl-items-group" id="firstNameFormGoup">
            <div class="sbl-form-control-container">
              <input
                required
                class="sbl-form-control"
                id="firstName"
                type="text"
                v-model="name"
                :disabled="nameDisabled"
                @blur="checkContinueButtonAvailability()"
                @change="checkContinueButtonAvailability()"
                @keyup="checkContinueButtonAvailability()"
              />
              <span class="floating-placeholder">Имя *</span>
            </div>
          </div>

          <!-- Micro Spacer -->
          <div class="sbl-items-group sbl-items-group-micro-spacer"/>

          <!-- Отчество -->
          <div class="sbl-items-group" id="middleNameFormGoup">
            <div class="sbl-form-control-container">
              <input
                required
                class="sbl-form-control"
                id="middleName"
                type="text"
                v-model="middleName"
                :disabled="middleNameDisabled"
              />
              <span class="floating-placeholder">Отчество *</span>
            </div>
          </div>
        </div>

        <!-- Средства связи -->
        <div class="sbl-items-group-multiple" id="contactsFormGoup">
          <!-- Телефон -->
          <div class="sbl-items-group">
            <div class="sbl-form-control-container">
              <input
                required
                class="sbl-form-control"
                type="text"
                v-model="phone"
                v-mask="'+7(9##)###-##-##'"
                :disabled="phoneDisabled"
                @blur="checkContinueButtonAvailability()"
                @change="checkContinueButtonAvailability()"
                @keyup="checkContinueButtonAvailability()"
              />
              <span class="floating-placeholder">Мобильный телефон *</span>
            </div>
          </div>

          <!-- Spacer -->
          <div class="sbl-items-group sbl-items-group-spacer"/>

          <!-- Email -->
          <div class="sbl-items-group">
            <div class="sbl-form-control-container">
              <span class="floating-error" v-if="emailNotValidated" v-text="emailValidationText"/>
              <input
                required
                class="sbl-form-control"
                type="text"
                v-model="email"
                :disabled="emailDisabled"
                @blur="checkContinueButtonAvailability()"
                @change="checkContinueButtonAvailability()"
                @keyup="checkContinueButtonAvailability()"
              />
              <span class="floating-placeholder">Электронная почта для входа *</span>
            </div>
          </div>
        </div>

        <!-- User agreement -->
        <div class="sbl-items-group personal-data-agreement">
          <div class="personal-data-agreement-check">
            <label>
              <input
                id="needToAgreeUserAgreement"
                type="checkbox"
                class="sbl-checkbox"
                v-model="userAgreement"
                @change="checkContinueButtonAvailability()"
              />
              <span class="sbl-small-text"> Нажимая кнопку «Продолжить» Вы даёте согласие на обработку Ваших персональных данных и подтверждаете, что ознакомлены и согласны c
                <a
                  href="#"
                  class="sbl-link"
                  @click="getUserAgreement()"
                >Пользовательским соглашением</a>
              </span>
            </label>
          </div>
        </div>


        <!-- Buttons -->
        <div class="sbl-items-group-multiple sbl-items-group-signUpButtons">
          <!-- Продолжить -->
          <RoundedButton
            v-show="!isChanged"
            caption="Продолжить"
            additionalClasses="sbl-green-button"
            @click="onContinueButtonClicked()"
            :disabled="continueButtonDisabled"
          />

          <!-- Изменить -->
          <RoundedButton
            v-show="!isChanged && fillFromToken"
            caption="Изменить данные"
            additionalClasses="sbl-blue-button"
            @click="onChangeButtonClicked()"
            :disabled="changeButtonDisabled"
          />

          <!-- Отправить запрос -->
          <RoundedButton
            v-show="isChanged && fillFromToken"
            caption="Отправить запрос"
            additionalClasses="sbl-green-button"
            @click="onSendRequestButtonClicked()"
            :disabled="continueButtonDisabled"
          />
        </div>

        <!-- Back -->
        <div class="sbl-items-group sbl-items-group-last-row">
          <router-link class="sbl-small-text sbl-link auto-width self-align-center" :to="{name: 'SignIn'}">&larr; Назад ко Входу в систему</router-link>
        </div>
      </div>
  </div>
</template>

<script>
import RoundedButton from "../components/RoundedButton";
import PersonalDataModal from "../components/PersonalDataModal";
import ErrorModal from "../components/ErrorModal";
import ConfirmResultModal from "../components/ConfirmRequestResultModal.vue";
import AuthService from "../services/AuthenticationService";
import MessageBox from "@/services/MessageBox";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { Messages } from "../enums/Messages.js";
import { OpfTypes } from "../enums/OpfTypes.js";
import { EntranceTypes } from "../enums/EntranceTypes.js";
import ModalService from "@/services/ModalService.js";
import { SblDaDataAccountService } from "../services/api/SblDaDataAccountService.js";
import { SblSberBusinessIdService } from "../services/api/SblSberBusinessIdService.js";
import { mask } from "vue-the-mask";
import { VueAutosuggest } from "vue-autosuggest";
import InvalidRegistrationModalVue from '../components/InvalidRegistrationModal.vue';

export default {
  name: "LoginWithoutCertificate",
  directives: {
    mask
  },
  components: {
    RoundedButton,
    PersonalDataModal,
    ErrorModal,
    ConfirmResultModal,
    VueAutosuggest
  },
  data() {
    return {
      /**
       * Галка с польз. соглашением
       */
      userAgreement: false,

      jobTitle: "",
      opf: "",
      companyName: "",
      inn: "",
      kpp: "",
      okpo: "",
      ogrn: "",
      surname: "",
      name: "",
      middleName: "",
      phone: "",
      email: "",
      innValidationText: "",
      emailValidationText: "Неверный формат электронной почты",
      sub: "",
      hashOrgId: "",
      opfDisabled: false,
      companyNameDisabled: false,
      innDisabled: false,
      kppDisabled: false,
      surnameDisabled: false,
      nameDisabled: false,
      middleNameDisabled: false,
      phoneDisabled: false,
      emailDisabled: false,
      redirectToMain: false,
      branchType: "",
      fiasCode: "",
      fiasId: "",
      legalAddress: "",

      // Группа свойств для автозаполнения,
      maxSuggestionLength: 10,
      suggestions: [],
      results: [],
      selected: null,
      debouncemilliseconds: 100,
      timeout: null,
      inputProps: {
        id: "autosuggest__input",
        onInputChange: this.fetchAccountSuggestionsByName,
        class: "sbl-form-control",
        disabled: this.companyNameDisabled,
        required: true
      },
      /**
       * Используются данные из сертификата.
       */
      fillFromCertificate: false,
      /**
       * Используются данные из токена.
       */
      fillFromToken: false,
      /**
       * Доступность кнопки продолжения.
       */
      continueButtonDisabled: true,
      /**
       * Доступность кнопки изменения данных.
       */
      changeButtonDisabled: true,
      /**
       * Значение в поле ИНН не прошло валидацию.
       */
      innNotValidated: false,
      /**
       * Значение в поле Email не прошло валидацию.
       */
      emailNotValidated: false,
      /**
       * Наполнение сертификата.
       */
      certificateBase64Content: "",
      /**
       * Отпечаток сертификата.
       */
      certificateThumbprint: "",
      /**
       * Токен, по которому хранятся данные авторизации.
       */
      authToken: "",
      /**
       * Признак того, что данные были изменены.
       */
      isChanged: false,
      /**
       * Пункты выпадающего меню выбора ОПФ.
       */
      opfOptions: []
    };
  },
  methods: {

    /**
     * Получение пользовательского соглашения.
     */
    getUserAgreement() {
      var scope = this;
      SblUserManagementService.getUserAgreement()
        .then(function(response) {
          var data = response && response.data;
          if (data) {
            var pdfWindow = window.open("");
            pdfWindow.document.write(
              "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
                encodeURI(data) +
                "'></iframe>"
            );
          } else {
            MessageBox.error("Произошла ошибка загрузки файла.");
          }
        })
        .catch(function(error) {
          error += "\n Сервис недоступен";
          MessageBox.error(errorMessage);
        });
    },

    fetchAccountSuggestionsByName(accountName) {
      if (this.fillFromCertificate || accountName === "") {
        return;
      }
      var scope = this;
      clearTimeout(this.timeout);
      var dadataQuery = {
        Inn: "",
        Kpp: "",
        Name: accountName
      };
      this.timeout = setTimeout(() => {
        SblDaDataAccountService.queryByName(dadataQuery).then(function(
          response
        ) {
          var data = (response || { data: [] }).data;
          scope.suggestions = [];
          scope.selected = null;
          var array = new Array();
          var currentSuggestionsLength = Math.min(
            scope.maxSuggestionLength,
            data.length
          );
          for (var i = 0; i < currentSuggestionsLength; ++i) {
            var datum = data[i];
            array[array.length] = {
              data: datum,
              value: datum.FullName,
              label: datum.Opf + " " + datum.FullName,
              desc: datum.FullAdress,
              inn: datum.Inn,
              kpp: datum.Kpp,
              opf: datum.Opf,
              name: datum.FullName,
              branchType: datum.BranchType,
              fiasCode: datum.FiasCode,
              fiasId: datum.FiasId,
              legalAddress: datum.FullAdress
            };
          }
          scope.suggestions.push({
            name: "Возможные варианты",
            data: array
          });
        });
      }, this.debounceMilliseconds);
    },

    onSelected(suggestion) {
      console.log(JSON.stringify(suggestion));
      if (!suggestion) {
        return;
      }
      (this.branchType = suggestion.item.branchType),
        (this.fiasId = suggestion.item.fiasId),
        (this.fiasCode = suggestion.item.fiasCode),
        (this.legalAddress = suggestion.item.legalAddress),
        (this.selected = suggestion.item);
      this.opf = suggestion.item.opf;
      this.inn = suggestion.item.inn;
      this.kpp = suggestion.item.kpp;
      this.companyName = suggestion.item.name;
      this.suggestions = [];
    },

    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },

    /**
     * Наполнить список типов ОПФ.
     */
    fillOpfList() {
      Object.keys(OpfTypes).forEach(opfType => {
        this.opfOptions.push(opfType);
      });
    },

    /**
     * Обработчик события нажатия на кнопку "Продолжить".
     */
    onContinueButtonClicked() {
      this.tryToRegistrate();
    },

    /**
     * Установить параметры по данным из сертификата.
     */
    setValuesByCertificateData() {
      if (!this.fillFromCertificate) {
        return;
      }

      var regData = sessionStorage.getItem("registrationData");
      var daDataResult = sessionStorage.getItem("additionalInfoByDaDataResult");
      var registrationData = regData ? JSON.parse(regData) : regData;
      var certificateBase64Content = sessionStorage.getItem(
        "certificateContent"
      );
      var certificateThumbprint = sessionStorage.getItem(
        "certificateThumbprint"
	    );
      if (!certificateThumbprint) {
        this.$router.push({ name: "SignIn" });
            sessionStorage.clear();
        return;
      }

      var certificateSign = sessionStorage.getItem("certificateSign") || {};

      var dadataInfo = daDataResult ? JSON.parse(daDataResult) : daDataResult;

      this.surnameDisabled = this.fillFromCertificate;
      if (registrationData.SN) {
        this.surname = registrationData.SN;
      }

      if (registrationData.T) {
        this.jobTitle = registrationData.T;
      }

      this.nameDisabled = this.fillFromCertificate;
      this.middleNameDisabled = this.fillFromCertificate;
      if (registrationData.G) {
        var combindeName = registrationData.G.split(" ");
        if (combindeName.length > 0) {
          this.name = combindeName[0];
          if (combindeName.length > 1) {
            this.middleName = combindeName[1];
          }
        }
      }

      this.companyNameDisabled = this.fillFromCertificate;
      if (dadataInfo && dadataInfo.OrganizationName) {
        this.companyName = dadataInfo.OrganizationName;
      } else if (registrationData.O) {
        this.companyName = registrationData.O;
      }

      this.innDisabled = this.fillFromCertificate;
      if (registrationData.INN) {
        this.inn = registrationData.INN;
      }

      this.kppDisabled = this.fillFromCertificate;
      if (dadataInfo && dadataInfo.Kpp) {
        this.kpp = dadataInfo.Kpp;
      } else if (registrationData.KPP) {
        this.kpp = this.registrationData.KPP;
      }

      this.opfDisabled = this.fillFromCertificate;
      if (dadataInfo && dadataInfo.Opf) {
        this.opf = dadataInfo.Opf;
      } else if (registrationData.OPF) {
        this.opf = registrationData.OPF;
      }

      this.certificateBase64Content = certificateBase64Content;
      this.certificateThumbprint = certificateThumbprint;
      this.certificateSign = certificateSign;
      sessionStorage.clear();
    },

    /**
     * Проверка доступности кнопки "Продолжить".
     */
    checkContinueButtonAvailability() {
      //var emailValidated = this.validateEmail(this.email);
      this.continueButtonDisabled =
        this.surname.length === 0 ||
        this.name.length === 0 ||
        !this.validateEmail(this.email) ||
        !this.phoneValidator(this.phone) ||
        !this.userAgreement;
    },

    /**
     * Валидация email.
     * @param {String} Проверяемый email.
     * @returns Boolean True, если валидация пройдена успешно.
     * @private
     */
    validateEmail(email) {
      email = email.trim();
      this.email = email;
      var re = /^[a-zA-Z0-9]+(?:[\.a-zA-Z0-9!#$%&'*+/=?^_`{|}~-])*(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z](?:[a-zA-Z-]*[a-zA-Z])?$/;
		  var validationResult = re.test(String(email).toLowerCase());
      this.emailNotValidated = !validationResult && Boolean(email);
      return validationResult;
    },

    /**
     * Валидация поля ИНН.
     */
    validateInnField() {
      var messageResult = this.validateInnValue(this.inn);
      this.innValidationText = messageResult.invalidMessage;
      this.innNotValidated = this.innValidationText === "" ? false : true;
    },

    /**
     * Валидация значения ИНН.
     * @param {String} inn Проверяемое значение ИНН.
     * @returns {Object} result.invalidMessage - Сообщение с текстом ошибки валидации.
     */
    validateInnValue(inn) {
      var invalidMessage = "";
      if (inn) {
        if (!/^\d+$/.test(inn)) {
          // Если ИНН содержит не только цифры
          invalidMessage = Messages.SblInnShouldContainOnlyDigits;
        } else {
          var innArray = inn.split("");
          switch (innArray.length) {
            case 10:
              // Проверить корректность ИНН по контрольной сумме
              var isInnCorrect =
                innArray[9] ==
                ((2 * innArray[0] +
                  4 * innArray[1] +
                  10 * innArray[2] +
                  3 * innArray[3] +
                  5 * innArray[4] +
                  9 * innArray[5] +
                  4 * innArray[6] +
                  6 * innArray[7] +
                  8 * innArray[8]) %
                  11) %
                  10;

              if (!isInnCorrect) {
                invalidMessage = Messages.SblInnIncorrectValue;
              }
              break;
            case 12:
              // Проверить корректность ИНН по контрольной сумме
              var isInn11CharCorrect =
                innArray[10] ==
                ((7 * innArray[0] +
                  2 * innArray[1] +
                  4 * innArray[2] +
                  10 * innArray[3] +
                  3 * innArray[4] +
                  5 * innArray[5] +
                  9 * innArray[6] +
                  4 * innArray[7] +
                  6 * innArray[8] +
                  8 * innArray[9]) %
                  11) %
                  10;
              var isInn12CharCorrect =
                innArray[11] ==
                ((3 * innArray[0] +
                  7 * innArray[1] +
                  2 * innArray[2] +
                  4 * innArray[3] +
                  10 * innArray[4] +
                  3 * innArray[5] +
                  5 * innArray[6] +
                  9 * innArray[7] +
                  4 * innArray[8] +
                  6 * innArray[9] +
                  8 * innArray[10]) %
                  11) %
                  10;
              var isInn12Correct = isInn11CharCorrect && isInn12CharCorrect;
              if (!isInn12Correct) {
                invalidMessage = Messages.SblInnIncorrectValue;
              }
              break;
            default:
              // Если ИНН состоит не из 10/12 цифр
              invalidMessage = Messages.SblInnIncorrectLength;
              break;
          }
        }
      }
      return {
        invalidMessage: invalidMessage
      };
    },

    /**
     * Показать модальное окно ошибки авторизации.
     */
    showRegistrationErrorModal() {
      ModalService.show({
        component: InvalidRegistrationModalVue,
        settings: {
          adaptive: true,
          width: 580,
          height: "auto",
          clickToClose: false
        }
      });
    },

    /**
     * Попытка регистрации пользователя.
     */
    tryToRegistrate() {
      var modalProperties = this.getConfirmResultWindowProperties();
      var clickHandler = () => {
        this.$modal.hide("ConfirmResultModal");
        if (this.redirectToMain) {
          this.$router.push({ name: "SignIn" });
        }
        if (this.authToken) {
          this.$router.push({
            name: "SignInWithToken",
            query: { token: this.authToken }
          });
        }
      };

      ModalService.show({
        component: ConfirmResultModal,
        props: {
          buttonClickHandler: clickHandler,
          properties: modalProperties
        },
        settings: {
          name: "ConfirmResultModal",
          height: "auto"
        }
      });

      var sblUserManagementToken = {
        FirstName: this.name,
        Surname: this.surname,
        AdditionalName: this.middleName,
        Email: this.email,
        MobilePhone: this.phone,
        Inn: this.inn,
        Kpp: this.kpp,
        LegalForm: this.opf,
        CompanyName: this.companyName,
        EnforceCulture: "ru-Ru",
        BranchType: this.branchType,
        FiasCode: this.fiasCode,
        FiasId: this.fiasId,
        LegalAddress: this.legalAddress,
        JobTitle: this.jobTitle
      };
      var registrateFunction = SblUserManagementService.register;
      var dataToSend = sblUserManagementToken;
      if (this.fillFromCertificate) {
        registrateFunction = SblUserManagementService.registerWithCertificate;
        dataToSend = {
          token: sblUserManagementToken,
          certificateThumbprint: this.certificateThumbprint,
          signedCertificateBase64Content: this.certificateSign
        };
      }
      if (this.fillFromToken) {
        var sberBusinessToken = this.getSberBusinessToken();
        registrateFunction = SblUserManagementService.registerWithToken;
        dataToSend = {
          token: sberBusinessToken
        };
      }
      var scope = this;
      var errorText =
        "Во время регистрации произошла ошибка, обратитесь к администратору";
      registrateFunction
        .call(SblUserManagementService, dataToSend)
        .then(function(response) {
          modalProperties.disabledButton = false;
          var messageFromData = response && response.data;
          var resultMessage = "";
          if (scope.fillFromCertificate) {
            messageFromData = messageFromData.InitRegistrationByEDMResult;
          }
          if (scope.fillFromToken) {
            messageFromData = messageFromData.RegisterWithTokenResult;
          }
          if (messageFromData) {
            if (messageFromData.ResultCode == "Success") {
              modalProperties.messageInfo = messageFromData.ResultMessage;
              if (messageFromData.Token) {
                scope.authToken = messageFromData.Token;
              } else {
                scope.redirectToMain = true;
                clickHandler = () => {
                  scope.$router.push({ name: "SignIn" });
                };
              }
            } else if (messageFromData.ResultCode == "UserFriendlyError") {
              if(messageFromData.ResultMessage == "EmailDuplicateError") {

                scope.$modal.hide("ConfirmResultModal");
                scope.showRegistrationErrorModal();
              } else {
                scope.redirectToMain = true;
                modalProperties.messageInfo = messageFromData.ResultMessage;
                clickHandler = () => {
                  scope.$modal.hide("ConfirmResultModal");
                };
              }
            } else if (messageFromData.ResultCode == "Error") {
              scope.redirectToMain = true;
              modalProperties.messageInfo = errorText;
            } else if (messageFromData.ResultCode != "Error") {
              resultMessage = messageFromData.ResultMessage.replace(
                "Авторизация",
                '<a href="/Authorization/index.html">Авторизация</a>'
              );
              scope.redirectToMain = false;
              modalProperties.messageInfo = resultMessage;
            }
          } else {
            messageInfo = errorText;
          }
        })
        .catch(function(error) {
          modalProperties.disabledButton = false;
          modalProperties.messageInfo = errorText;
        });
    },

    /**
     * Получение токена SberBusiness ID.
     * @returns {object} Токен SberBusiness ID с информацией о пользователе.
     */
    getSberBusinessToken: function() {
      var sberBusinessToken = {
        Sub: this.sub,
        Okpo: this.okpo,
        Ogrn: this.ogrn,
        HashOrgId: this.hashOrgId,
        FirstName: this.name,
        Surname: this.surname,
        AdditionalName: this.middleName,
        Email: this.email,
        MobilePhone: this.phone,
        JobTitle: this.jobTitle,
        Inn: this.inn,
        Kpp: this.kpp,
        LegalForm: this.opf,
        CompanyName: this.companyName
      };
      return sberBusinessToken;
    },

    /**
     * Валидатор номера телефона.
     * @param {String} value Проверяемый номер телефона.
     * @returns {Boolean} True, если номер телефона прошел валидацию.
     * @private
     */
    phoneValidator: function(value) {
      var isValid =
        value != "" &&
        new RegExp("\\+[7]\\([9]{1}[0-9]{2}\\)[0-9]{3}\\-[0-9]{2}\\-[0-9]{2}").test(
          value
        );

      return isValid;
    },

    autocompleteAccount() {},

    /**
     * Установка необходимости регистрации по токену.
     * @returns {boolean} True, если регистрация будет происходить по токену.
     */
    setTokenExists: function() {
      this.fillFromToken = sessionStorage.getItem("fillFromToken");
      return this.fillFromToken;
    },

    /**
     * Установка значений на странице по значениям токена.
     */
    setValuesByTokenData: function() {
      if (!this.setTokenExists()) {
        return;
      }
	  var regData = sessionStorage.getItem("registrationTokenData");
	  if (!regData) {
		this.$router.push({ name: "SignIn" });
        sessionStorage.clear();
		return;
	  }

      var registrationData = regData ? JSON.parse(regData) : regData;
      this.setUserInfoByToken(registrationData);
      sessionStorage.clear();
    },

    /**
     * Заполнение информации по пользователю, полученной по токену.
     * @param {object} userInfo Информация о пользователе.
     */
    setUserInfoByToken: function(userInfo) {
      if (!userInfo) {
        return;
      }
      this.changeButtonDisabled = false;

      // Если хотя бы одно поле не заполнено, то делаем доступной кнопку запроса.
      // Игнорируем кпп у ИП
      this.isChanged =
        !userInfo.OrgName ||
        !userInfo.Inn ||
        !(userInfo.Inn.length === 12 ? true : userInfo.OrgKpp) ||
        !userInfo.Surname ||
        !userInfo.FirstName ||
        !userInfo.SecondName;

      if (userInfo.OrgName) {
        this.companyName = userInfo.OrgName;
      }
      if (userInfo.Inn) {
        this.inn = userInfo.Inn;
      }
      if (userInfo.OrgKpp) {
        this.kpp = userInfo.OrgKpp;
      }
      if (userInfo.Surname) {
        this.surname = userInfo.Surname;
      }
      if (userInfo.FirstName) {
        this.name = userInfo.FirstName;
      }
      if (userInfo.SecondName) {
        this.middleName = userInfo.SecondName;
      }
      if (userInfo.PhoneNumber) {
        this.phone = this.getMaskedPhoneValue(userInfo.PhoneNumber);
      }
      if (userInfo.Email) {
        this.email = userInfo.Email;
      }
      if (userInfo.OrgOkpo) {
        this.okpo = userInfo.OrgOkpo;
      }
      if (userInfo.UserPosition) {
        this.jobTitle = userInfo.UserPosition;
      }
      if (userInfo.Sub) {
        this.sub = userInfo.Sub;
      }
      if (userInfo.HashOrgId) {
        this.hashOrgId = userInfo.HashOrgId;
      }
      if (userInfo.OrgOgrn) {
        this.ogrn = userInfo.OrgOgrn;
      }
      this.checkContinueButtonAvailability();
      this.setFieldsDisable(this.isChanged === false);
    },

    /**
     * Получение правильного value для поля phone
     */
    getMaskedPhoneValue: function(v) {
      var i = v.length === 10 ? 0 : 1;
      return "+7("+v[i]+v[i+1]+v[i+2]+")"+v[i+3]+v[i+4]+v[i+5]+"-"+v[i+6]+v[i+7]+"-"+v[i+8]+v[i+9];
    },

    /**
     * Управление доступностью полей.
     * @param {boolean} disable Признак недоступности полей.
     */
    setFieldsDisable: function(disable) {
      this.surnameDisabled = disable;
      this.nameDisabled = disable;
      this.middleNameDisabled = disable;
      this.companyNameDisabled = disable;
      this.innDisabled = disable;
      this.kppDisabled = disable;
      this.opfDisabled = disable;
    },

    /**
     * Обработчик события нажатия на кнопку изменения данных.
     */
    onChangeButtonClicked: function() {
      this.isChanged = true;
    },

    /**
     * Обработчик события нажатия на кнопку "Отправить запрос".
     */
    onSendRequestButtonClicked: function() {
      this.$modal.hide("PersonalDataModal");
      var modalProperties = this.getConfirmResultWindowProperties();
      var clickHandler = () => {
        this.$modal.hide("ConfirmResultModal");
        if (this.redirectToMain) {
          this.$router.push({ name: "SignIn" });
        }
      };

      ModalService.show({
        component: ConfirmResultModal,
        props: {
          buttonClickHandler: clickHandler,
          properties: modalProperties
        },
        settings: {
          name: "ConfirmResultModal",
          height: "auto"
        }
      });

      var sberBusinessToken = this.getSberBusinessToken();
      var dataToSend = sberBusinessToken;

      var scope = this;
      var errorText =
        "Во время создания обращения произошла ошибка, обратитесь к администратору";
      var func = SblUserManagementService.generateJiraForRegistration;
      func
        .call(SblUserManagementService, dataToSend)
        .then(function(response) {
          console.log(response);
          modalProperties.disabledButton = false;
          var messageFromData = response && response.data;
          modalProperties.messageInfo = errorText;
          if (messageFromData) {
            scope.redirectToMain = true;
            modalProperties.messageInfo = messageFromData.ResultMessage;
          }
        })
        .catch(function(error) {
          modalProperties.disabledButton = false;
          modalProperties.messageInfo = errorText;
        });
    },

    /**
     * Получение свойств окна подтверждения результата.
     * @returns {any} Свойства модального окна.
     */
    getConfirmResultWindowProperties: function() {
      return {
        messageInfo: "Обработка запроса...",
        disabledButton: true
      };
    },
  },
  watch: {
    companyNameDisabled: function(val) {
      this.inputProps.disabled = val;
    },
    isChanged: function(val) {
      this.setFieldsDisable(!val);
    }
  },
  mounted() {
    this.fillFromCertificate = sessionStorage.getItem("fillFromCertificate");
    
    if (!sessionStorage.length) {
      this.$router.push({ name: "SignIn" });
      AuthService.resetCookies();
      return;
    }

    this.setValuesByCertificateData();
    this.setValuesByTokenData();
    AuthService.resetCookies();
    this.fillOpfList();
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";



.autosuggest-floating-placeholder{
  top: 20px;
}

.sbl-h2-text, 
.personal-data-agreement {
  padding-top: 15px;
}

.sbl-items-group {
	width: 90%;

	&-multiple {
		width: 90%;
  }

  &-signUpButtons {
    width: 40%;
  }

}

.personal-data {
  top: 15%;
  width: 60%;

  &-agreement {
    font-family: $primary-font;
  
    &-header {
      text-align: center;
    }

    &-check {
      font-weight: bold;
      cursor: pointer;

      & span {
        margin-left: 10px;
        cursor: pointer;
        margin-top: auto;
        margin-bottom: auto;
      }
      & label {
        display: flex;
        cursor: pointer;
      }
      &-second {
        margin-top: 15px;
      }
    }
  }
  #inn {
    padding-right: 15px;
  }

  #kpp {
    padding-left: 15px;
  }

  button {
    margin: 0 10px 0 10px;
  }
}
</style>
