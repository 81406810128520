import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для реализации авторизации по сертификату.
 */
export class SblRegistrationHelperService {

  static get ApiService() { return "SblRegistrationHelperService.svc" }

  /**
   * Очистка кук.
   */
  static resetCookies() {

    return ApiConnection.post(this.ApiService + '/ResetCookies');
  }
}