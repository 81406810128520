import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для взаимодействия с SberBusiness ID.
 */
export class SblSberBusinessIdService {

	static get ApiService() { return "SblSberBusinessIdService.svc" }

	static get BaseUrl() { return "../0/ServiceModel/" }

	/**
	 * Получение значения системной настройки 
	 * "Включить авторизацию / регистрацию по SberBuisnessId".
	 */
	static getSblIsSberIdAuthEnabled() {
		return ApiConnection.get(this.ApiService + '/getSblIsSberIdAuthEnabled', { baseURL: this.BaseUrl });
	}

	/**
	 * Получение значения системной настройки 
	 * "Адрес для редиректа в личный кабинет пользователя СберБизнес".
	 */
	static getSblSberBusinessLinkOnPortal() {
		return ApiConnection.get(this.ApiService + '/getSblSberBusinessLinkOnPortal', { baseURL: this.BaseUrl });
	}

	/**
	 * Получение значения системной настройки 
	 * Авторизация по УКЭП в ЛКК.
	 */
	static getSblIsLoginByCertificateEnabled() {
		return ApiConnection.get(this.ApiService + '/GetSblIsLoginByCertificateEnabled', { baseURL: this.BaseUrl });
	}

	/**
	 * Получение значения системной настройки 
	 * Показывать алерт-блок на портале.
	 */
	static checkIsSignInAlertShown() {
		return ApiConnection.get(this.ApiService + '/GetSblIsAlertBlockOnPortalShown', { baseURL: this.BaseUrl });
	}

	/**
	 * Получение значения системной настройки 
	 * Текст алерт-блока на портале.
	 */
	static getSignInAlertText() {
		return ApiConnection.get(this.ApiService + '/GetSblAlertBlockOnPortalText', { baseURL: this.BaseUrl });
	}

	/**
	 * Авторизация в SberBusiness ID.
	 */
	static authorize() {
		return ApiConnection.post(this.ApiService + '/initPortalAuth', {}, { baseURL: this.BaseUrl });
	}

	/**
	 * Обработка ответа от SberBusiness ID.
	 * @param {string} parameters Параметры запроса.
	 */
	static catchRequest(parameters) {
		const headers = {
			"Accept": "application/json, text/html"
		};
		return ApiConnection.get(this.ApiService + '/catchPortalAuth' + parameters, { baseURL: this.BaseUrl, headers: headers });
	}

	/**
	 * Получение информации о клиенте из SberBusiness ID.
	 * @param {string} token Токен, по которому будет получена информация о клиенте.
	 */
	static getUserInfo(token) {
		const headers = {
			"Accept": "application/json, text/html"
		};
		return ApiConnection.get(this.ApiService + '/getUserInfo/' + token, { baseURL: this.BaseUrl, headers: headers });
	}
}