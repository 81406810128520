<template>
	<div class="change-password">
		<div class="sbl-card">
			<span class="sbl-h1">Установка нового пароля</span>
			<div class="sbl-common-text">{{changePasswordMessage}}</div>
			<div class="sbl-form-group">
				<div v-if="fillFromCertificate">
					<label class="sbl-control-label sbl-control-label-bold">Логин</label>
					<div class="sbl-form-control-container">
						<input
							autocomplete="new-password"
							class="sbl-form-control"
							type="text"
							v-model="login"
							:disabled="loginDisabled"
						/>
					</div>
					<label class="sbl-control-label sbl-control-label-bold">Пароль из SMS</label>
					<div class="sbl-form-control-container">
						<input
							autocomplete="new-password"
							class="sbl-form-control"
							type="password"
							v-model="smsPassword"
						/>
					</div>
				</div>
				<label class="sbl-control-label sbl-control-label-bold">Пароль</label>
				<div class="sbl-form-control-container">
					<input
						class="sbl-form-control"
						:type="passwordFieldType"
						v-model="password"
						@keydown="validatePassword()"
						@change="validatePassword()"
						@keyup="validatePassword()"
						@keypress="validatePassword()"
					/>

					<a v-if="password==''" class="change-password-actions password-actions-not-active"/>
					<a @click="switchOnPasswordVisibility" v-if="passwordFieldType=='password' && password!=''" class="change-password-actions password-actions-show"/>
					<a @click="switchOffPasswordVisibility" v-if="passwordFieldType=='text' && password!=''" class="change-password-actions password-actions-hide"/>

					<div
						class="sbl-field-error-message sbl-field-error-message_xl sbl-arrow-box"
						v-if="showPassHint"
					>
						<div v-if="passwordNotValidated" class="pass-hint-header">Пароль не соответствует требованиям безопасности</div>
						<div v-else class="pass-hint-header">Пароль соответствует требованиям безопасности</div>
						<div class="pass-hint-message">Безопасный пароль должен содержать</div>
						<div class="pass-hint-item">
							<img v-if="isPasswordQuantityNumberValid" src="../assets/images/InfoButtonTrueConditionImage.png" />
							<img v-else src="../assets/images/InfoButtonFalseConditionImage.png" />
							<span>Не менее 8 и не более 16 знаков</span>
						</div>
						<div class="pass-hint-item">
							<img v-if="isPasswordLettersValid" src="../assets/images/InfoButtonTrueConditionImage.png" />
							<img v-else src="../assets/images/InfoButtonFalseConditionImage.png" />
							<span>Прописные и строчные буквы</span>
						</div>
						<div class="pass-hint-item">
							<img v-if="isPasswordSymbolValid" src="../assets/images/InfoButtonTrueConditionImage.png" />
							<img v-else src="../assets/images/InfoButtonFalseConditionImage.png" />
							<span>Хотя бы один специальный символ</span>
						</div>
						<div class="pass-hint-item">
							<img v-if="isPasswordNumbersValid" src="../assets/images/InfoButtonTrueConditionImage.png" />
							<img v-else src="../assets/images/InfoButtonFalseConditionImage.png" />
							<span>Хотя бы одна цифра</span>
						</div>
					</div>
				</div>
				<div class="sbl-form-group" style="width:100%">
					<label class="sbl-control-label sbl-control-label-bold">Подтвердить пароль</label>
					<div class="sbl-form-control-container">
						<input
							class="sbl-form-control"
							:type="confirmPasswordFieldType"
							v-model="confirmedPassword"
							@keydown="comparePasswords()"
							@change="comparePasswords()"
							@keyup="comparePasswords()"
							@keypress="comparePasswords()"
						/>
						<div
							id="falseConfirmPassword"
							class="sbl-field-error-message sbl-arrow-box"
							v-if="passwordsNotEqual"
						>Пароли не совпадают</div>

						<a v-if="confirmedPassword==''" class="change-password-actions password-actions-not-active"/>
						<a @click="switchOnConfirmPasswordVisibility" v-if="confirmPasswordFieldType=='password' && confirmedPassword!=''" class="change-password-actions password-actions-show"/>
						<a @click="switchOffConfirmPasswordVisibility" v-if="confirmPasswordFieldType=='text' && confirmedPassword!=''" class="change-password-actions password-actions-hide"/>
					</div>
				</div>
			</div>

			<!-- Капча -->
			<div id="yandexSmartCaptcha" class="sbl-items-group" v-show="isSmartCaptchaVisible"></div>

			<RoundedButton
				caption="Продолжить"
				additionalClasses="sbl-green-button change-password__continue sbl-items-group-last-row"
				:disabled="passwordNotValidated || passwordsNotEqual || !confirmedPassword"
				@click="onContinueButtonClicked()"
			/>
		</div>
	</div>
</template>

<script>
import ConfirmResultModal from "../components/ConfirmRequestResultModal.vue";
import RoundedButton from "../components/RoundedButton";
import { SblUserManagementService } from "../services/api/SblUserManagementService.js";
import { Messages } from "../enums/Messages.js";
import AuthService from "@/services/AuthenticationService";
import { AuthenticationErrorCodes } from '../enums/AuthenticationErrorCodes';
import MessageBox from "@/services/MessageBox";
import ModalService from "@/services/ModalService.js";
import InvalidUserNameOrPasswordModal from '../components/InvalidUserNameOrPasswordModal.vue';
import SmsConfirmModalService from "@/services/SmsConfirmModalService.js";
import SmsConfirmationModal from "../components/SmsConfirmationModal";

/**
 * Страница изменения пароля.
 */
export default {
	components: {
		ConfirmResultModal,
		RoundedButton,
		SmsConfirmationModal
	},
	data() {
		return {
			/**
			 * Тип поля пароля
			 */
			passwordFieldType: "password",

			/**
			 * Тип поля пароля
			 */
			confirmPasswordFieldType: "password",

			/**
			 * Заполнить из сертификата
			 */
			fillFromCertificate: false,

			/**
			 * Логин
			 */
			login: "",

			/**
			 * Логин не доступен для редактирования
			 */
			loginDisabled: true,

			/**
			 * Пароль из SMS
			 */
			smsPassword: "",

			/**
			 * Пароль.
			 */
			password: "",

			/**
			 * Подтвержденный пароль.
			 */
			confirmedPassword: "",

			/**
			* Использовать временный пароль из Смс.
			*/
			sblPortalTempPasswordDisable: true,

			/**
			 * Пароль не проверен.
			 */
			passwordNotValidated: false,

			/**
			 * Пароли не совпадают.
			 */
			passwordsNotEqual: false,

			/**
			* Значение фичи регистрация без пароля по СМС .
			*/
			isSmsFeatureEnabled: false,

			/**
			* Флаг видимости капчи.
			*/
			isSmartCaptchaVisible: true,

			/**
			 * Идентификатор отрисованной капчи.
			 */
			captchaWidgetId: null,

			/**
			 * Ответ капчи.
			 */
			smartCaptchaResponse: null,

			/**
			 * Валидность пароля по количеству чисел.
			 */
			isPasswordQuantityNumberValid: true,

			/**
			 * Валидность пароля по критерию букв.
			 */
			isPasswordLettersValid: true,

			/**
			 * Валидность пароля по критерию спецсимволов.
			 */
			isPasswordSymbolValid: false,

			/**
			 * Валидность пароля по критерию чисел.
			 */
			isPasswordNumbersValid: false,

			/**
			 * Флаг отображения подсказки пароля.
			 */
			showPassHint: false,

			/**
			 * Флаг отображения подсказки пароля.
			 */
			IsSblPortalEdoSettingsIconEnable: false
		};
	},
	methods: {
		/**
		 * Показать модальное окно ошибки авторизации.
		 */
		showAuthorizationErrorModal() {
			ModalService.show({
				component: InvalidUserNameOrPasswordModal,
				props: {
					signUpCallback: () => {
						this.goToRegister();
					}
				},
				settings: {
					adaptive: true,
					width: 580,
					height: "auto",
					clickToClose: false
				}
			});
		},
		/**
		 * Отображение сообщения об ошибке.
		 * @param {String} errorMessage Текст сообщения об ошибке.
		 * @private
		 */
		showErrorMessage(errorMessage) {
			if (!errorMessage) {
				return;
			}
			MessageBox.error(errorMessage);
		},

		/**
		 * Получает с бэка значение системной настройки и устанавливает соответствующий флаг.
		 */
		checkIsSmsFeatureEnabled(){
			SblUserManagementService.checkIsSmsFeatureEnabled()
			.then(function(response) {
				if (response) {
					this.IsSmsFeatureEnabled = response.data;
				}
			}.bind(this))
		},

		/**
		 * Получает с бэка значение системной настройки и устанавливает соответствующий флаг.
		 */
		checkIsSblPortalEdoSettingsIconEnable(){
			SblUserManagementService.checkIsSblPortalEdoSettingsIconEnable()
			.then(function(response) {
				if (response) {
				this.IsSblPortalEdoSettingsIconEnable = response.data;
				}
			}.bind(this))
		},

		/**
		 * Валидация пароля.
		 */
		validatePassword() {
			let password = this.password;
			let login = this.login;

			const regexCommon = /^(?=.*[-—–._,<>:;'+)=^#`(@|}№{$!%*?&])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,16}$/;

			let isValid = !login.includes(password) && regexCommon.test(password);
			this.validateHints(password);
			this.passwordNotValidated = !(isValid);
			this.showPassHint = true;
			if (isValid) {
				this.comparePasswords();
			}
		},

		/**
		 * Валидация пароля.
		 */
		validateHints(password) {
			if(!password) {
				this.isPasswordQuantityNumberValid = false;
				this.isPasswordLettersValid = false;
				this.isPasswordSymbolValid = false;
				this.isPasswordNumbersValid = false;
			}

			const regexNumberQuantity = /^.{8,16}$/;
			this.isPasswordQuantityNumberValid = regexNumberQuantity.test(password);

			const regexUpperLetters = /[A-Z]/;
			const regexLowerLetters = /[a-z]/;

			this.isPasswordLettersValid = regexUpperLetters.test(password) && regexLowerLetters.test(password);

			const regexSymbol = /[-—–._,<>:;'+)=^#`(@|}№{$!%*?&]/;
			this.isPasswordSymbolValid = regexSymbol.test(password);

			const regexNumber = /\d+/;
			this.isPasswordNumbersValid = regexNumber.test(password);
		},

		/**
		 * Меняет тип поля на текстовое.
		 */
		switchOnPasswordVisibility() {
			this.passwordFieldType = "text";
		},

		/**
		 * Меняет тип поля на пароль.
		 */
		switchOffPasswordVisibility() {
			this.passwordFieldType = "password";
		},

		/**
		 * Меняет тип поля на текстовое.
		 */
		switchOnConfirmPasswordVisibility() {
			this.confirmPasswordFieldType = "text";
		},

		/**
		 * Меняет тип поля на пароль.
		 */
		switchOffConfirmPasswordVisibility() {
			this.confirmPasswordFieldType = "password";
		},

		/**
		 * Сравнение паролей.
		 */
		comparePasswords() {
			this.passwordsNotEqual = this.password !== this.confirmedPassword;
		},

		/**
		 * Авторизация после успешной смены пароля.
		 */
		authorize(){
			let data = {
				login: sessionStorage.getItem("RealLogin"),
				password: this.password
			};
			sessionStorage.clear();
			SblUserManagementService.defaultLoginRequest(data)
				.then(response => {

					let authData = {
						UserName: data.login,
						UserPassword: data.password
					}

					let loginResult = response.data;

					if (loginResult.Success) {
						let responseData = JSON.parse(response.data.ResponseText);
						AuthService.handleAutoAuthResponse(authData, responseData, this.IsSblPortalEdoSettingsIconEnable, this.showErrorMessage);
						return;
					}

					if (!loginResult.Success) {
						if (loginResult.ResponseText) {
							let response = JSON.parse(loginResult.ResponseText);

							if (response?.Code === AuthenticationErrorCodes.InvalidCredentials
								|| response?.Code === AuthenticationErrorCodes.SmsAuthenticationInvalidCredentials) {
								this.showAuthorizationErrorModal();

								return;
							}
						}

						this.showErrorMessage(loginResult.ErrorMessage);
						return;
					}
				})
				.catch(error => {
					console.log(error);
					this.showErrorMessage(error);
				});
		},

		/**
		 * Обработка события нажатия на кнопку "Продолжить".
		 */
		onContinueButtonClicked(){
			if(this.IsSmsFeatureEnabled){
				this.changePasswordWithSMS();
			} else {
				this.changePasswordWithoutSMS();
			}
		},

		/**
		 * Задать новый пароль с подтверждением по смс.
		 */
		changePasswordWithSMS() {
			let userAuthorizationData = {
				NewPassword: this.confirmedPassword,
				Inn: sessionStorage.getItem("Inn"),
				HasTempPassword: !this.sblPortalTempPasswordDisable
			};

			if (this.isSmartCaptchaVisible) {
				let smartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);

				if (!smartCaptchaResponse) {
					this.showErrorMessage(Messages.SblIamNotRobot);
					return;
				}
				userAuthorizationData.YSmartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);
				window.smartCaptcha.reset(this.captchaWidgetId);
			}

			if (this.fillFromCertificate) {
				// При первом логине по сертификату логин/пароль берутся из инпутов
				userAuthorizationData.Login = this.login;
				userAuthorizationData.Password = this.smsPassword;
			} else {
				// При первом логине по паролю данные берутся из sessionStorage
				userAuthorizationData.Login = sessionStorage.getItem("UserName");
				userAuthorizationData.Password = sessionStorage.getItem("UserPassword");
			}

			SblUserManagementService.ChangePasswordWithSMS(userAuthorizationData)
				.then(response => {
					if (!response || !response.data) {
						throw Messages.SblEmptyResponseFromAuthService;
					}

					let result = response.data;

					if (result.success) {
						this.showSmsConfirmModal.call(this, {
							login: sessionStorage.getItem("UserName"),
							password: this.confirmedPassword,
							inn: sessionStorage.getItem("Inn"),
							oldPassword: userAuthorizationData.Password,
							captcha: userAuthorizationData.YSmartCaptchaResponse
						});
					} else {
						this.showErrorMessage(Messages.SblCommonError);
					}
				})
				.catch(error =>{
					window.console.error(error);
					this.showErrorMessage(Messages.SblCommonError);
				});
		},

		/**
		 * Показать модальное окно с подтверждением СМС кода.
		 * @param {object} data Информация о пользователе.
		 * @param {String} data.login Логин.
		 * @param {String} data.password Пароль.
		 * @param {String} data.inn ИНН.
		 */
		showSmsConfirmModal(data) {
			let props = {
				modalData: data,
				confirmButtonFunc: SmsConfirmModalService.confirmChangePasswordSmsCode,
				resendCodeFunc: SmsConfirmModalService.resendPasswordConfirmSmsCode
			};

			ModalService.show({
				component: SmsConfirmationModal,
				props: props,
				settings: {
					width:"560px"
				}
			})
			.then(() => {
				// Если все прошло хорошо, авторизовываем пользователя
				this.authorize();
			})
			.catch(() => {
				// Если все плохо ресетнуть капчу
				window.smartCaptcha.reset();
			});
		},

		/**
		 * Обработка события нажатия на кнопку "Продолжить".
		 */
		changePasswordWithoutSMS() {
			let modalProperties = {
				messageInfo: Messages.SblProcessingRequest,
				disabledButton: true
			};
			let clickHandler = () => {
				this.$modal.hide("ConfirmResultModal");
				this.$router.replace({ name: "SignIn", query: null });
			};
			this.$modal.show(
				ConfirmResultModal,
				{
					buttonClickHandler: clickHandler,
					properties: modalProperties
				},
				{
					name: "ConfirmResultModal",
					height: "auto"
				}
			);

			let userAuthorizationData = {
				NewPassword: this.confirmedPassword,
				HasTempPassword: !this.sblPortalTempPasswordDisable
			};

			if (this.isSmartCaptchaVisible) {
				let smartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);

				if (!smartCaptchaResponse) {
					modalProperties.disabledButton = false;
					modalProperties.messageInfo = Messages.SblIamNotRobot;
					return false;
				}
				userAuthorizationData.YSmartCaptchaResponse = window.smartCaptcha.getResponse(this.captchaWidgetId);
				window.smartCaptcha.reset(this.captchaWidgetId);
			}

			if (this.fillFromCertificate) {
				// При первом логине по сертификату логин/пароль берутся из инпутов
				userAuthorizationData.Login = this.login;
				userAuthorizationData.Password = this.smsPassword;
			} else {
				// При первом логине по паролю данные берутся из sessionStorage
				userAuthorizationData.Login = sessionStorage.getItem("UserName");
				userAuthorizationData.Password = sessionStorage.getItem("UserPassword");
				sessionStorage.clear();
			}

			SblUserManagementService.changePassword(userAuthorizationData)
				.then(response => {
					if (!response || !response.data) {
						throw "Сервис работы с аккаунтами пользователя пароля вернул пустой ответ на запрос смены пароля.";
					}

					var result = response.data;

					modalProperties.disabledButton = false;
					modalProperties.messageInfo = result.success
						? "Пароль успешно изменён, войдите в систему с новым паролем."
						: result.errorMessage;
				})
				.catch(error =>{
					modalProperties.disabledButton = false;
					modalProperties.messageInfo =
						"Сервис временно недоступен. Обратитесь к вашему клиентскому менеджеру или по телефону 8-800-555-55-56";
					window.console.log(error);
				});
		},
		/**
		 * Метод рендеринга капчи Яндекс.
		 */
		onloadFunction() {

			let cachedSmartCaptchaKey = localStorage.getItem("SblSmartCaptchaSiteKey");

			if (window.smartCaptcha && cachedSmartCaptchaKey) {
				const container = document.getElementById('yandexSmartCaptcha');
				const widgetId = window.smartCaptcha.render(container, {
					sitekey: cachedSmartCaptchaKey,
					hl: 'ru',
				});

				this.captchaWidgetId = widgetId;
				return;
			}

			SblUserManagementService.getSmartCaptchaSiteKey()
				.then( (response) => {
					let smartCaptchaSiteKey = response && response.data;

					if (!smartCaptchaSiteKey) {
						window.console.error(Messages.SblYandexCaptchaNoSiteKey);
						return;
					}
					const container = document.getElementById('yandexSmartCaptcha');
					const widgetId = window.smartCaptcha.render(container, {
						sitekey: smartCaptchaSiteKey,
						hl: 'ru',
					});

					this.captchaWidgetId = widgetId;
					localStorage.setItem("SblSmartCaptchaSiteKey", smartCaptchaSiteKey);

				})
				.catch(function(error) {
					console.log(error);
				});
		},

		/**
		 * Добавить элемент капчи на страницу.
		 */
		setSmartCaptchaElement() {
			let captchaScript = document.createElement("script");
			captchaScript.setAttribute(
				"src",
				"https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction"
			);
			document.head.appendChild(captchaScript);
		},

		/**
		* Получает с бэка значение системной настройки необходимости отправки смс.
		*/
		checkSblPortalTempPasswordDisable(){
			SblUserManagementService.checkSblPortalTempPasswordDisable()
			.then(function(response) {
				if (response && response.data) {
					this.sblPortalTempPasswordDisable = response.data;
				}
			}.bind(this))
		}
	},
	computed: {
		changePasswordMessage() {
			return this.fillFromCertificate ? Messages.SblChangePasswordMessageForCertificateLogin :Messages.SblChangePasswordMessageForSimpleLogin;
		}
	},
	mounted() {
		this.checkIsSmsFeatureEnabled();
		this.checkIsSblPortalEdoSettingsIconEnable();

		SblUserManagementService.getSblDisablePasswordRecoveryFromCertificate()
			.then(settingInfo => {
				if (!settingInfo?.data) {
					let fillFromCertificate = sessionStorage.getItem("fillFromCertificate");
					// При первичной регистрации по сертификату
					// из sessionStorage достается отпечаток и по нему получается логин
					if (fillFromCertificate) {
						this.fillFromCertificate = true;
						let thumbprint = sessionStorage.getItem("certificateThumbprint");
						sessionStorage.clear();

						if (!thumbprint) {
							this.loginDisabled = false;
						} else {
							SblUserManagementService.getLoginByThumbprint(JSON.stringify(thumbprint))
								.then(response => {
									if (!response || !response.data) {
										this.loginDisabled = false;
									};

									this.login = response.data;
								});
						}
					}
				}
			});

		window.onloadFunction = this.onloadFunction;
		this.setSmartCaptchaElement();
	}
};

</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.change-password {
	.pass-hint-item {
		padding-bottom: 10px;
		font-family: 'SBSansDisplay-Regular';
	}
	.pass-hint-item span{
		padding-left: 10px;
	}
	.pass-hint-message {
		padding-bottom: 10px;
		font-family: 'SBSansDisplay-Bold';
	}

	.pass-hint-header {
		padding-bottom: 10px;
		font-family: 'SBSansDisplay-Bold';
	}
	img {
		vertical-align: middle;
	}
	.sbl-card{
		display:flex;
		align-items: center;
		.sbl-h1{
			text-align: center;
			margin-top: 10px;
			font-size: 35px;
		}
		.sbl-common-text{
			text-align: center;
			max-width:90%;
		}
		.sbl-form-group{
			display: block;
			.sbl-form-control{
				padding:2px 10px 2px 10px;
			}
		}
	}
	justify-content: center;
	margin:15vh auto;
	align-items: center;
	flex-direction: column;
	max-width: 550px;
	&__head-text {
		margin: 10px 0 30px;
		color: $primary-color;
		font-size: 22px;
		font-weight: bold;
	}

	&__continue {
		margin-top: 30px;
		width: 70%;
	}

	&__capcha {
		 display: flex;
		 align-items: center;
		 justify-content: center;
	}
}
</style>
