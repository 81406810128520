import { ApiConnection } from "@/services/api/ApiConnection";

/**
 * Сервис для реализации авторизации по сертификату.
 */
export class SblCertificateAuthService {

  static get ApiService() { return "SblCertificateAuthService.svc" }

  /**
   * Авторизация по сертификату.
   * @param {any} param0 Информация по сертификату.
   * @param {String} param0.ThumbPrint "Отпечаток" сертификата.
   * @param {String} param0.CertificateSign Подпись сертификата.
   */
  static loginWithCertificate({ ThumbPrint, CertificateSign, AccountId }) {

    const headers = {
      "Accept": "application/json",
      "Content-Type": "application/json",
      "Authorization": "Cookie",
      "ForceUseSession": "true"
    };

    return ApiConnection.post(this.ApiService + '/Login',
      { ThumbPrint, CertificateSign, AccountId }, { headers });
  }
}