<template>
  <BaseModal v-bind:showCloseButton="showCloseButton" title="" @close="$emit('close')">
    <template v-slot:content>
      <div class="sbl-card">
        <div class="sbl-items-group self-align-center sbl-med-text" v-text="properties.messageInfo"/>
        <div class="sbl-items-group sbl-items-group-last-row">
          <RoundedButton
            caption="ОК"
            additionalClasses="sbl-blue-button"
            :disabled="properties.disabledButton"
            @click="buttonClickHandler()"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal";
import RoundedButton from "../components/RoundedButton";

/**
 * Модальное окно для вывода промежуточного результата выполнения процесса.
 */
export default {
  props: {
    /**
     * Функция обработки события нажатия на кнопку.
     */
    buttonClickHandler: {
      type: Function,
      default: () => this.$emit("close")
    },
    properties: {
      type: Object,
      default: function() {
        return {
          messageInfo: "Обработка запроса...",
		  disabledButton: true
        };
      }
	},
	/**
     * Показывать кнопку закрытия.
     */
    showCloseButton: {
      type: Boolean,
	  required: false,
	  default: false
	}
  },
  components: {
    RoundedButton,
    BaseModal
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.sbl-items-group {
  width: 90%;
  align-items: center;
}
button {
  max-width: 200px;
}

</style>

